import React, { Component } from "react";
import { Link } from "react-router-dom";
import AdminHeader from "../Header/AdminHeader";
import AdminFooter from "../Footer/AdminFooter";
import AdminLeftMenu from "../Navbar/AdminLeftMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faEdit,
  faUserLock,
  faSignInAlt,
} from "@fortawesome/free-solid-svg-icons";
import loading from "../Dashboard/assets/img/loading.gif";
import Dialog from "react-dialog";
import { userloginDetails } from "../Utils/AdminAuth";
import DatePicker from "react-datepicker";
import { userId } from "../Utils/userAuth";
import UserDetails from "./UserDetails";
import { HOST_URL } from "../Dashboard/api/Api";
class UserManagement extends Component {
  constructor() {
    const lStorage = userloginDetails();

    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    const isAdmin = lStorage.user.isAdmin;
    const AdminEmail = lStorage.user.username;
    const userPassword = lStorage.user.password;
    super();

    this.state = {
      user_id: userId,
      adminPassword: userPassword,
      AdminEmail: AdminEmail,
      token: userToken,
      userType: isAdmin,
      allUserList: null,
      isactive: "",
      selectedUserId: "",
      serchData: "",
      isDialogOpen: false,
      userCurrentStatus: "",
      successMessage: false,
      errorMessage: "",
      bgColor: "",
      dateFrom: "",
      dateTo: "",
      count: {},
    };
  }

  formatDate() {
    const date = new Date();

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const dateStr = `${year}/${month}/${day}`;
    this.setState({ dateFrom: dateStr });
    this.setState({ dateTo: dateStr });
  }

  componentDidMount() {
    this.onserch();
    this.formatDate();
    this.showData();
  }

  showData = async () => {
    const date = new Date();

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const dateStr = `${year}/${month}/${day}`;

    const frmdt = this.state.dateFrom ? this.state.dateFrom : dateStr;
    const todt = this.state.dateTo ? this.state.dateTo : dateStr;
    let response = await fetch(
      `${HOST_URL}/admin_user_list.php?user_id=${this.state.user_id}&method=admin_signup_count&type=${this.state.userType}&token=${this.state.token}&date_from=${frmdt}&date_to=${todt}`
    );
    let result = await response.json();
    if (result) {
      this.setState({ count: result });
    }
  };

  onserch() {
    fetch(
      `${HOST_URL}/admin_user_list.php?user_id=${this.state.user_id}&method=retrieve&type=${this.state.userType}&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        // console.log('User Iist ',result)
        if (result.success === true) {
          this.setState({ allUserList: result });
          this.setState({ userCurrentStatus: result.data[0].is_active });
        } else {
          this.setState({
            successMessage: true,
            successBg: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      });
    });
  }

  searchUser() {
    if (this.state.serchData.trim() === "") {
      this.onserch();
      this.setState({ successMessage: true });
      this.setState({ errorMessage: "Enter User Name " });
      this.setState({ bgColor: "alert alert-danger alert-dismissible" });
    } else {
      fetch(
        `${HOST_URL}/admin_user_list.php?user_id=${
          this.state.user_id
        }&method=retrieve&type=search&user_name=${this.state.serchData.trim()}&token=${
          this.state.token
        }`
      ).then((response) => {
        response.json().then((result) => {
          // console.log('User Iist ',result)
          if (result.success === true) {
            this.setState({ successMessage: false });
            this.setState({ allUserList: result });
          } else {
            this.setState({ successMessage: true });
            this.setState({ errorMessage: result.message });
            this.setState({ bgColor: "alert alert-danger alert-dismissible" });
          }
        });
      });
    }
  }

  userDisabled() {
    document.querySelector("body").scrollTo(0, 0);
    fetch(
      `${HOST_URL}/admin_user_list.php?user_id=${this.state.user_id}&method=enabledisable&current_status=${this.state.isactive}&ret_user_id=${this.state.selectedUserId}&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        if (result.success === true) {
          this.setState({
            successMessage: true,
            successBg: "alert alert-success alert-dismissible",
            errorMessage: result.message,
          });
          window.location.reload();
        } else {
          this.setState({
            successMessage: true,
            successBg: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      });
    });
  }

  loginToUserAccount(emailaddress, password) {
    var url = `${window.location.protocol}//${window.location.host}`;
    const singleTemplateData = {
      username: emailaddress,
      password: password,
      admintype: this.state.userType,
    };
    fetch(`${HOST_URL}/login.php`, {
      method: "post",
      headers: {
        "content-Type": "application/json",
      },
      body: JSON.stringify(singleTemplateData),
    }).then((result) => {
      result.json().then((result) => {
        if (result.success === true) {
          result["url"] = url;
          if (result.user.userId === 8407) {
            localStorage.setItem("stanmaxuser", JSON.stringify(result));
            window.location = "/stanmax/whatsappconv";
            return;
          } else {
            localStorage.setItem("login", JSON.stringify(result));
            window.location = "/dashboard";
          }
        } else {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      });
    });
  }

  openDialog = (isactive, selectedUserId) =>
    this.setState({
      isDialogOpen: true,
      isactive: isactive,
      selectedUserId: selectedUserId,
    });

  handleClose = () => this.setState({ isDialogOpen: false });

  dateFrom = (date) => {
    let dated =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      ("0" + date.getDate()).slice(-2);
    this.setState({
      dateFrom: dated,
      SelecteddateFrom: date,
    });
  };

  dateTo = (date) => {
    let dated =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      ("0" + date.getDate()).slice(-2);
    this.setState({
      dateTo: dated,
      SelecteddateTo: date,
    });
  };

  render() {
    const oneDayBefore = new Date();
    oneDayBefore.setDate(oneDayBefore.getDate() - 1);

    return (
      <div className="wrapper">
        <AdminLeftMenu />
        <div className="main">
          <AdminHeader />
          <main className="content">
            <div className="container-fluid p-0">
              <div className="row mb-2 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                  <h3>
                    <strong> User </strong> Management{" "}
                  </h3>
                </div>
                <div className="col-auto ml-auto d-flex text-right mt-n1">
                  <DatePicker
                    className="form-control btn-block"
                    dateFormat="yyyy/MM/dd"
                    value={this.state.dateFrom}
                    onChange={this.dateFrom}
                    selected={this.state.SelecteddateFrom}
                    placeholderText={this.state.dateFrom}
                  />

                  <DatePicker
                    className="form-control btn-block"
                    dateFormat="yyyy/MM/dd"
                    value={this.state.dateTo}
                    onChange={this.dateTo}
                    selected={this.state.SelecteddateTo}
                    placeholderText={this.state.dateTo}
                  />

                  <button
                    onClick={() => this.showData()}
                    style={{ borderRadius: 0 + "px" }}
                    className="btn btn-sm btn-primary"
                  >
                    <FontAwesomeIcon icon={faSearch} />{" "}
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-12  mt-3 col-xxl-5 d-flex">
                  <div className="w-100">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="card">
                          <div className="card-body">
                            <h5 className="card-title mb-4">
                              Authkey Signup Count
                            </h5>
                            <h2 className="mt-1 mb-3">
                              {this.state.count.admin_count}
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="card">
                          <div className="card-body">
                            <h5 className="card-title mb-4">
                              Partner Signup Count
                            </h5>
                            <h2 className="mt-1 mb-3">
                              {this.state.count.partner_count}
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                  <div className="card flex-fill">
                    <div className="card-body">
                      <div className="filter-option">
                        <h6 className="card-subtitle text-muted">Filter </h6>
                        <div className="row">
                          <div className="col-md-9">
                            <div className="input-group mb-2 mr-sm-2">
                              <div className="input-group-text">
                                <FontAwesomeIcon icon={faSearch} />
                              </div>
                              <input
                                type="text"
                                onBlur={(event) => {
                                  this.setState({
                                    serchData: event.target.value,
                                  });
                                }}
                                placeholder="Enter user id or name of users"
                                className="form-control"
                              />
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="input-group mb-2 mr-sm-2">
                              <input
                                type="button"
                                onClick={() => this.searchUser()}
                                value="Search User"
                                className="btn mt-1 btn-block btn-primary btn-sm ml-auto "
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="m-auto">
                        {this.state.isDialogOpen && (
                          <Dialog
                            modal={true}
                            isDraggable={true}
                            onClose={this.handleClose}
                            buttons={[
                              {
                                text: "Close",
                                className: "btn-info btn-sm btn mr-2",
                                onClick: () => this.handleClose(),
                              },
                              {
                                text: "Yes",
                                className: "btn btn-sm btn-danger",
                                onClick: () => this.userDisabled(),
                              },
                            ]}
                          >
                            <p>Are You Sure ? </p>
                          </Dialog>
                        )}
                      </div>

                      {this.state.successMessage ? (
                        <div className={this.state.bgColor} role="alert">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>

                          <div className="">{this.state.errorMessage}</div>
                        </div>
                      ) : null}

                      {this.state.allUserList ? (
                        <div
                          style={{ height: 400 + "px" }}
                          className="table-responsive"
                        >
                          <table className="table table-striped table-xs table-sm my-0">
                            <thead>
                              <tr>
                                <th>Action</th>
                                <th> Email ID / Username</th>
                                <th>Parent</th>
                                <th>Credit</th>
                                <th>Source</th>
                                <th>Created Date / Time</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.allUserList.data.map((item, i) => (
                                <tr key={i}>
                                  <td>
                                    <Link
                                      className="mr-2"
                                      to={"/admin/user-details/" + item.id}
                                    >
                                      <FontAwesomeIcon icon={faEdit} />
                                    </Link>
                                    <span
                                      style={{ cursor: "pointer" }}
                                      className="mr-2"
                                      onClick={() =>
                                        this.openDialog(item.is_active, item.id)
                                      }
                                    >
                                      {item.is_active === 1 ? (
                                        <FontAwesomeIcon
                                          color="green"
                                          icon={faUserLock}
                                        />
                                      ) : (
                                        <FontAwesomeIcon
                                          color="red"
                                          icon={faUserLock}
                                        />
                                      )}
                                    </span>
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.loginToUserAccount(
                                          item.email,
                                          item.password,
                                          this.state.AdminEmail,
                                          this.state.adminPassword
                                        )
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faSignInAlt}
                                        color="green"
                                      />
                                    </span>
                                  </td>
                                  <td>
                                    <p className="m-0"> {item.email} </p>
                                    <span
                                      style={{ fontSize: 12 + "px" }}
                                      className="m-0 font-samll text-primary font-weight-bold"
                                    >
                                      User ID : {item.id} ,{" "}
                                    </span>
                                    <span
                                      style={{ fontSize: 12 + "px" }}
                                      className="m-0 text-primary font-weight-bold"
                                    >
                                      Mobile : {item.mobile}{" "}
                                    </span>

                                    <span
                                      style={{ fontSize: 12 + "px" }}
                                      className="m-0 text-danger d-block"
                                    >
                                      country : {item.country}{" "}
                                    </span>
                                  </td>

                                  <td>
                                    {" "}
                                    <strong className="text-capitalize">
                                      {" "}
                                      {item.type} {item.parent_type}{" "}
                                    </strong>{" "}
                                    ({item.parent_name})
                                  </td>
                                  <td>
                                    {item.country_currency}:{" "}
                                    {item.currency.toFixed(2)}
                                  </td>
                                  {/* <td>Default</td> */}
                                  <td>
                                    Source :{" "}
                                    <strong className="text-capitalize">
                                      {" "}
                                      {item.added_by}{" "}
                                    </strong>{" "}
                                    <br />
                                    Plan :{" "}
                                    <strong className="text-capitalize text-primary">
                                      {item.plan_name}
                                    </strong>
                                  </td>
                                  <td>{item.created}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="text-center">
                          <div className="card-body">
                            <img
                              alt="Empty Data"
                              style={{ width: 100 + "px" }}
                              className="project-empty-img"
                              src={loading}
                            />
                            <p>Loading...... </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <AdminFooter />
        </div>
      </div>
    );
  }
}
export default UserManagement;
