import React, { useEffect, useState } from 'react';
import AdminHeader from '../../Header/AdminHeader'
import AdminLeftMenu from '../../Navbar/AdminLeftMenu'
import AdminFooter from '../../Footer/AdminFooter'
import { Link, useParams } from 'react-router-dom';
import { userloginDetails } from '../../Utils/AdminAuth';
import api from "../../../axios/Axios";
import { ADMIN_USERS_LIST } from '../../../constants/ServiceConstants';
import Spining from '../../UI/Spining/Spining';
import Alert from '../../UI/Alert/Alert';
import { HOST_URL } from '../../Dashboard/api/Api';

const UpdateWabaId = () => {
    let { id, brandNumber } = useParams();
    const adminAuthDetails = userloginDetails();

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setisError] = useState(false);
    const [errorType, seterrorType] = useState("");
    const [ErrorResponse, setErrorResponse] = useState(null);
    const [whatsApp,setwhatsApp] = useState([])
    const [userId,setUserId] = useState('')

    const handleChange = (e) =>{
        setwhatsApp({...whatsApp , [e.target.name]: e.target.value})
    }

    useEffect(() => {
        getWhatsAppDetails();
    }, []);


      const updateID = async() =>{
        let data = {
            token: adminAuthDetails.token,
            id: userId,
            user_id: adminAuthDetails.user.userId,
            ret_user_id: id,
            method: "update_waba",
            sending_limit: whatsApp.daily_sending_limit,
            waba_id: whatsApp.waba_id,
            phone_id:whatsApp.phone_id
        }
        let response = await fetch (`${HOST_URL}/admin_user_list.php`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          });
        let result = await response.json();
        if(result){
            setisError(true);
            seterrorType("alert alert-success alert-dismissible");
            setErrorResponse(result.message);

            setTimeout(function () {
              window.location.reload();
            }, 2000);
        }
        else{
            setisError(true);
            seterrorType("alert alert-danger alert-dismissible");
            setErrorResponse(result.message);
        }
      }

      const getWhatsAppDetails = async () => {
        try {
          const { data } = await api.post(ADMIN_USERS_LIST, {
            token: adminAuthDetails.token,
            user_id: adminAuthDetails.user.userId,
            ret_user_id: id,
            brand_number: brandNumber,
            method: "ret_whatsapp_profile",
          });
          if (data.success === true) {
            console.log(data.data)
            setwhatsApp({
              waba_id: data.data[0].waba_id,
              phone_id: data.data[0].phone_number_id,
              daily_sending_limit: data.data[0].daily_sending_limit,
            });
            setUserId(data.data[0].id)
            setIsLoading(false);
          } else {
            setErrorResponse(data.message);
            setIsLoading(false);
          }
        } catch (err) {
          console.log(err);
        }
      };



    return (
        <div>
            <div className="wrapper">
                <AdminLeftMenu />
                <div className="main">
                    <AdminHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                            {isLoading && <Spining />}
                                <div className="col-auto d-none d-sm-block">
                                    <h3><strong className='text-dark'>WhatsApp User List</strong></h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">
                                            <nav className="text-center" aria-label="breadcrumb">
                                                <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
                                                    <Link to={`/admin/whatsapp-account-profile-details/${id}`}><li style={{fontSize:"13px"}} className="breadcrumb-item">Profile Details</li></Link>
                                                    <Link to={`/admin/whatsapp-account-whatsapp-template-list/${id}`}><li style={{fontSize:"13px"}} className="breadcrumb-item">Whatsapp Template List</li></Link>
                                                    <Link to={`/admin/whatsapp-account-update-whatsapp-token/${id}`}><li style={{fontSize:"13px"}} className="breadcrumb-item">Update Whastapp Token</li></Link>
                                                    <Link to={`/admin/whatsapp-account-change-route/${id}`}><li style={{fontSize:"13px"}} className="breadcrumb-item">Change Route</li></Link>
                                                    <Link to={`/admin/update-waba-id/${id}`}><li style={{fontSize:"13px"}} className="breadcrumb-item active">Update Waba Id</li></Link>
                                                </ol>
                                            </nav>
                                        </h5>
                                    </div>
                                    <div className="card-body">
                                    {isError && <Alert type={errorType}> {ErrorResponse} </Alert>}
                                        <div className="form-row ">                                            
                                            <div className="form-group col-md-6">
                                                <label className="col-form-label">Waba Id:</label>
                                                <input
                                                    className="form-control"
                                                    placeholder="Waba Id"
                                                    type="text"
                                                    name="waba_id"
                                                    onChange={handleChange}
                                                    id="flexCheckDefault"
                                                    value={whatsApp.waba_id}
                                                />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="col-form-label">Phone No Id:</label>
                                                <input
                                                    className="form-control"
                                                    placeholder="Phone No Id"
                                                    type="text"
                                                    name="phone_id"
                                                    onChange={handleChange}
                                                    id="flexCheckDefault"
                                                    value={whatsApp.phone_id}
                                                />
                                            </div>
                                        </div>
                                        <div className='form-row'>
                                        <div className="form-group col-md-6">
                                                <label className="col-form-label">
                                                    Daily Sending Limit:
                                                </label>
                                                <input
                                                    className="form-control"
                                                    placeholder="daily sending limit"
                                                    type="text"
                                                    defaultValue={whatsApp.daily_sending_limit}
                                                    name="daily_sending_limit"
                                                    id="flexCheckDefault"
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <button
                                            onClick={updateID}
                                            className="btn btn-sm float-right btn-primary"
                                        >
                                            Update Deatils
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <AdminFooter />
                </div>
            </div>
        </div>
    );
};

export default UpdateWabaId;
