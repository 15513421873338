import React, { useState } from "react";
import Papa from "papaparse";

const UploadCSVFile = (props) => {
  const [csvFile, setcsvFile] = useState(false);
  const [csvData,setCsvData] = useState([])

  // const changeHandler = (event) => {
  //   Papa.parse(event.target.files[0], {
  //     header: true,
  //     skipEmptyLines: true,
  //     complete: function (results) {
  //       const rowsArray = [];
  //       const valuesArray = [];
  //       results.data.map((d) => {
  //         rowsArray.push(Object.keys(d));
  //         valuesArray.push(Object.values(d));
  //       });
  //       let data = {
  //         rowData: results.data,
  //         tableColume: rowsArray[0],
  //       };
  //       setcsvFile(true);
  //       sendData(data);
  //     },
  //   });
  // };

  // const handleFileChange = (event) => {
  //   // setFile(event.target.files[0]);
  //   setcsvFile(true);
  //   sendData(event.target.files[0]);

    
  // };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const text = e.target.result;
        const rows = text.split("\n").map((row) => row.split(","));
        
        // Optional: Process rows to extract headers and values
        const headers = rows[0]; // Assuming the first row is the header
        const data = rows.slice(1).map((row) => {
          const obj = {};
          headers.forEach((header, index) => {
            obj[header] = row[index];
          });
          return obj;
        });
        sendData(file, data);
        setcsvFile(true); // Send data to parent component
      };

      reader.readAsText(file);
    }
  };

  console.log(csvData.length)

  const sendData = (data, countData) => {
    props.sendCsvData(data, countData);
    // props.sendcountData(countData)
  };

  return (
    <>
      <div className="input-group mb-2 mt-4 mr-sm-2">
        <div className="input-group-prepend">
          <div className="input-group-text">AMD CSV File</div>
        </div>
        <input
          type="file"
          name="file"
          accept=".csv"
          onChange={handleFileChange}
          className="form-control"
        />
      </div>

      {csvFile && (
        <div className="alert alert-success">File Upload Successfully</div>
      )}
    </>
  );
};

export default UploadCSVFile;
