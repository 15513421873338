import React, { Component } from "react";

import { Helmet } from "react-helmet";
import loginimg from "../Dashboard/assets/img/login.png";
import { checkUserUrl } from "../Utils/Utils";
import { HOST_URL } from "../Dashboard/api/Api";
import EmptyImg from "../Dashboard/assets/img/signupDone.gif";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import ReactTelInput from "react-telephone-input";
import flags from "../Dashboard/assets/img/flags.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
class ResellerSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
      mobile: "",
      password: "",
      currency: "INR",
      otp: "",
      emailotp: "",
      allCountryList: [],
      method: "send",
      isChecked: true,
      IndiaUser: true,
      countryCode: "",
      loginScreen: true, // true
      otpScreen: false, // false
      isLoading: true,
      successMessage: false,
      signupDone: false,
      errorMessage: "",
      bgColor: "",
      defaultCountry: "in",
      countryCode: "91",
      myParam: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleInputChange = (telephone, selectedCountry) => {
    if (selectedCountry) {
      const { dialCode, iso2 } = selectedCountry;
      this.setState({
        countryCode: dialCode,
        selectedCountryCode: iso2,
      });
    }
  };

  componentDidMount() {
    this.getAllCountryList();

    const params = new URLSearchParams(window.location.search);
    const ref = params.get("ref");
    console.log(ref);
    this.setState({ myParam: ref });

    // var url = document.location.href;
    // var mainURL = "console.authkey.io";
    // var localHost = "localhost:3000";
    // if (url.indexOf(localHost) !== -1 || url.indexOf(mainURL) !== -1) {
    //   console.log("URL test passed");
    // } else {
    //   window.location = "/si";
    // }
  }

  getAllCountryList() {
    fetch(`${HOST_URL}/country_master.php`).then((response) => {
      response.json().then((result) => {
        // console.log(result)
        let allCountryList = [];
        if (result.success === true) {
          for (var i = 0; i < result.data.length; i++) {
            allCountryList.push({
              label: result.data[i].country_name,
              value: result.data[i].country_code,
            });
          }
          this.setState({
            allCountryList: allCountryList,
          });
        } else {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      });
    });
  }

  handleChange = (selectedCountry) => {
    // console.log(selectedCountry)
    this.setState({
      countryCode: selectedCountry.value,
    });
  };

  //   checkBoxhandle = (e) => {
  //     this.setState({
  //       isChecked: e.target.checked,
  //     });
  //   };

  signUpIndia() {
    document.querySelector("body").scrollTo(0, 0);
    if (
      window.location.pathname === "/resellersignup" ||
      window.location.pathname === "/signup/"
    ) {
      this.signup(null);
    } else {
      this.signup(this.props.match.params.source);
    }
  }

  signup(source) {
    // if (this.state.isChecked) {
    const signup = {
      email: this.state.email,
      name: this.state.name,
      password: this.state.password,
      mobile: this.state.mobile,
      country_code: this.state.countryCode,
      currency: this.state.currency,
      method: "send",
      source: source,
      reseller_code: this.state.myParam,
    };
    this.setState({ isLoading: false });
    fetch(`${HOST_URL}/signup_india.php`, {
      method: "post",
      headers: {
        "content-Type": "application/json",
      },
      body: JSON.stringify(signup),
    }).then((result) => {
      result.json().then((resp) => {
        //  console.log(resp);
        if (resp.success === true) {
          this.setState({
            loginScreen: false,
            isLoading: true,
            otpScreen: true,
            successMessage: false,
          });
        } else {
          this.setState({
            successMessage: true,
            isLoading: true,
            bgColor: "alert alert-danger alert-dismissible",
            errorMessage: resp.message,
          });
        }
      });
    });
    // else {
    //   this.setState({
    //     successMessage: true,
    //     isLoading: true,
    //     bgColor: "alert alert-danger alert-dismissible",
    //     errorMessage: "Please accept terms and conditions",
    //   });
    // }
  }

  VerifyMobile() {
    document.querySelector("body").scrollTo(0, 0);
    var url = document.location.href;
    if (
      window.location.pathname === "/signup" ||
      window.location.pathname === "/signup/"
    ) {
      const signup = {
        email: this.state.email,
        name: this.state.name,
        mobile: this.state.mobile,
        password: this.state.password,
        country_code: this.state.countryCode,
        currency: this.state.currency,
        method: "verify",
        // otp: this.state.otp,
        emailotp: this.state.emailotp,
        source: "",
        reseller_code: this.state.myParam,
      };
      this.setState({ isLoading: false });
      fetch(`${HOST_URL}/signup_india.php`, {
        method: "post",
        headers: {
          "content-Type": "application/json",
        },
        body: JSON.stringify(signup),
      }).then((result) => {
        result.json().then((resp) => {
          if (resp.success === true) {
            resp["url"] = url;
            this.setState({
              successMessage: false,
              loginScreen: false,
              otpScreen: false,
              signupDone: true,
              isLoading: true,
            });
            localStorage.setItem("login", JSON.stringify(resp));
            localStorage.setItem("admin", JSON.stringify({ success: false }));
            // setTimeout(function () {
            //     window.location = "/signup-success";
            // });
          } else {
            this.setState({ successMessage: true, isLoading: true });
            this.setState({ bgColor: "alert alert-danger alert-dismissible" });
            this.setState({ errorMessage: resp.message });
          }
        });
      });
    } else {
      var url = document.location.href;
      const signup = {
        email: this.state.email,
        name: this.state.name,
        mobile: this.state.mobile,
        password: this.state.password,
        country_code: this.state.countryCode,
        currency: this.state.currency,
        method: "verify",
        // otp: this.state.otp,
        emailotp: this.state.emailotp,
        source: this.props.match.params.source,
        reseller_code: this.state.myParam,
      };
      this.setState({ isLoading: false });
      fetch(`${HOST_URL}/signup_india.php`, {
        method: "post",
        headers: {
          "content-Type": "application/json",
        },
        body: JSON.stringify(signup),
      }).then((result) => {
        result.json().then((resp) => {
          if (resp.success === true) {
            resp["url"] = url;
            this.setState({
              successMessage: false,
              loginScreen: false,
              otpScreen: false,
              signupDone: true,
              isLoading: true,
            });
            localStorage.setItem("login", JSON.stringify(resp));
            localStorage.setItem("admin", JSON.stringify({ success: false }));
            // setTimeout(function () {
            //     window.location = "/signup-success";
            // });
          } else {
            this.setState({ successMessage: true, isLoading: true });
            this.setState({ bgColor: "alert alert-danger alert-dismissible" });
            this.setState({ errorMessage: resp.message });
          }
        });
      });
    }
  }

  //   responseFacebook = (response) => {
  //     var url = document.location.href;
  //     const loginWithFacebook = {
  //       email: response.email,
  //       name: response.name,
  //       api_response: response,
  //     };
  //     fetch(`${HOST_URL}/facebook_login.php`, {
  //       method: "post",
  //       headers: {
  //         "content-Type": "application/json",
  //       },
  //       body: JSON.stringify(loginWithFacebook),
  //     }).then((result) => {
  //       result.json().then((resp) => {
  //         if (resp.success === true && resp.account === "old") {
  //           this.setState({
  //             show: true,
  //             bgColor: "alert alert-success alert-dismissible",
  //             loginMessage: "success",
  //           });
  //           resp["url"] = url;
  //           localStorage.setItem("login", JSON.stringify(resp));
  //           localStorage.setItem("admin", JSON.stringify({ success: false }));
  //           setTimeout(function () {
  //             window.location = "/dashboard";
  //           });
  //         } else if (resp.success === true && resp.account === "new") {
  //           resp["url"] = url;
  //           this.setState({
  //             show: true,
  //             bgColor: "alert alert-success alert-dismissible",
  //             loginMessage: "success",
  //           });
  //           localStorage.setItem("login", JSON.stringify(resp));
  //           localStorage.setItem("admin", JSON.stringify({ success: false }));
  //           setTimeout(function () {
  //             window.location = "/signup-success";
  //           });
  //         } else {
  //           this.setState({
  //             show: true,
  //             bgColor: "alert alert-danger alert-dismissible",
  //             loginMessage: resp.message,
  //           });
  //         }
  //       });
  //     });
  //   };

  //   componentDidMount() {
  //     this.getAllCountryList();

  //     var url = document.location.href;
  //     var mainURL = "console.authkey.io";
  //     var localHost = "localhost:3000";
  //     if (url.indexOf(localHost) !== -1 || url.indexOf(mainURL) !== -1) {
  //       console.log("URL test passed");
  //     } else {
  //       window.location = "/my-account";
  //     }
  //   }

  //    query = useQuery();
  //    myParam = query.get('myParam');

  render() {
    document.querySelector("body").scrollTo(0, 0);
    const { selectedCountry } = this.state;
    console.log(this.state.myParam);
    return (
      <div>
        <Helmet>
          {checkUserUrl(this.state.otherurl) === true ? (
            <title>
              Login page for SMS Email Voice Using one API - AuthKey.io
              |Powerful API built For Sending OTPs & Trans Traffic
            </title>
          ) : (
            <title>welcome</title>
          )}
          <meta
            name="description"
            content="Send SMS Voice or Email parallelly or use other channels as Fallback | High Volume APIs Built for Sending huge volume with Parallel Sending and Fallback option."
          />
          <style type="text/css">{`
                        .deals-and-offers {
                            display:none !important;
                        }
                        @media only screen and (max-width: 600px) {
                            .mobile-img {
                            display: none;
                            }
                        }
                    `}</style>
        </Helmet>

        <section
          className="loginbg"
          style={{ backgroundImage: `url(${loginimg})` }}
        >
          <div className="container d-flex flex-column">
            <div className="row flex-column-reverse flex-lg-row min-vh-100">
              <div className="col-lg-6 mb-10">
                <div className="login-new-box sm-p-0">
                  {/* {this.state.loginScreen ? ( */}
                  <form className="form-row col-md-6 col-lg-10 pl-md-5 pr-sm-0 mt-4">
                    {/* <FacebookLogin
                            appId="4884679621657801"
                            textButton="Signup With Facebook"
                            autoLoad={false}
                            fields="name,email"
                            callback={this.responseFacebook}
                          />

                          <div className="form-group col-md-12 mt-4">
                            <hr className="mt-0 mb-0" />{" "}
                            <span className="or-center">
                              or use Email address & password
                            </span>
                          </div> */}

                    <div className="form-group col-md-12">
                      {this.state.successMessage ? (
                        <div className={this.state.bgColor} role="alert">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                          <div className="">{this.state.errorMessage}</div>
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-0 col-md-12 text-left login-box">
                      {/* <Link to="/">
                          <img
                            className="img-fluid mb-3"
                            src={logo}
                            style={{ height: "32px" }}
                          />
                        </Link> */}
                      <h3 className="">Create an account</h3>
                    </div>
                    <div className="form-group col-md-6  ">
                      <label className="form-control-label">Name</label>
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={(event) => {
                            this.setState({ name: event.target.value });
                          }}
                          className="form-control logininput"
                          placeholder="Enter Name"
                        />
                      </div>
                    </div>

                    <div className="form-group col-md-6 ">
                      <label className="form-control-label">Email</label>
                      <div className="input-group">
                        <input
                          type="email"
                          onChange={(event) => {
                            this.setState({ email: event.target.value });
                          }}
                          className="form-control logininput"
                          placeholder="Enter Email"
                        />
                      </div>
                    </div>

                    <div className="col-md-12 ">
                      <label className="form-control-label">
                        Select Your Country{" "}
                      </label>

                      <div className="form-row">
                        <div className="input-group col-md-12 mb-1">
                          <Select
                            value={selectedCountry}
                            onChange={this.handleChange}
                            options={this.state.allCountryList}
                            placeholder={"Enter Your Country Name"}
                            closeMenuOnSelect={true}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-md-12">
                      <label className="form-control-label">Mobile</label>
                      <div className="input-group">
                        <div className="input-group-prepend ReactTelFullbox">
                          <ReactTelInput
                            defaultCountry={this.state.defaultCountry}
                            flagsImagePath={flags}
                            onChange={this.handleInputChange}
                          />
                        </div>
                        <input
                          type="text"
                          className="form-control logininput"
                          placeholder="Enter Mobile Number"
                          onChange={(event) => {
                            this.setState({ mobile: event.target.value });
                          }}
                        />
                      </div>
                    </div>

                    <div className="form-group col-md-12 ">
                      <label className="form-control-label">Password</label>
                      <div className="input-group">
                        <input
                          type="password"
                          onChange={(event) => {
                            this.setState({
                              password: event.target.value,
                            });
                          }}
                          className="form-control logininput"
                          placeholder="Enter Strong Password"
                        />
                      </div>
                    </div>
                    {/* <div className="col-md-12">
                        <div className="ml-4">
                          <input
                            type="checkbox"
                            onChange={this.checkBoxhandle}
                            value={this.state.isChecked}
                            className="form-check-input mr-2"
                            id="fallback"
                          />{" "}
                          <small
                            style={{ display: "block", lineHeight: "20px" }}
                            className="mb-2 pl-2"
                          >
                            By submitting this form, you agree to the{" "}
                            <a href="https://authkey.io/term-and-condition">
                              Terms of Use
                            </a>{" "}
                            and have read the{" "}
                            <a href="https://authkey.io/privacy-policy">
                              Privacy Policy
                            </a>
                          </small>
                        </div>
                      </div> */}

                    <div className="mt-4 col-md-12  m-auto">
                      {this.state.isLoading ? (
                        <button
                          type="button"
                          onClick={() => this.signUpIndia()}
                          className="btn w-100 btn-block"
                          style={{ backgroundColor: "#3a3462", color: "white" }}
                        >
                          {" "}
                          Send Otp <FontAwesomeIcon icon={faArrowRight} />{" "}
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-block"
                          style={{ backgroundColor: "#3a3462", color: "white" }}
                        >
                          <span className="spinner-border text-white spinner-border-sm"></span>{" "}
                          Please Wait..
                        </button>
                      )}
                    </div>

                    {/* <div className="form-group text-center col-md-12 ">
                        <div className="mt-2">
                          <p>
                            Already Have Account ?{" "}
                            <Link to="/login">Sign in</Link>{" "}
                          </p>
                        </div>
                      </div> */}

                    {/* <div style={{textAlign:"center", width:"100%"}}>
                        <small style={{fontSize:"11px"}}>
                          © 2020-2024 AuthKey.io Datagen Internet Services Pvt.
                          Ltd. | All rights reserved
                        </small>
                      </div> */}
                  </form>

                  <div
                    className={`modal fade ${
                      this.state.otpScreen ? "show" : ""
                    }`}
                    style={{ display: this.state.otpScreen ? "block" : "none" }}
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="otpModalLabel"
                    aria-hidden={!this.state.otpScreen}
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="otpModalLabel">
                            OTP Verification
                          </h5>
                          <button
                            type="button"
                            className="close"
                            onClick={() => this.setState({ otpScreen: false })}
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <h5 className="alert alert-success">
                            We have sent a 6 digit OTP on your {"Email"}
                          </h5>

                          <form className="otp-verify-form">
                            {/* Mobile OTP input (optional) */}
                            {/* {this.state.countryCode === "91" && (
                                                            <div className="form-group">
                                                                <label htmlFor="mobileOtp" className="form-control-label">Enter Mobile OTP</label>
                                                                <input
                                                                    type="text"
                                                                    id="mobileOtp"
                                                                    onChange={(event) => this.setState({ otp: event.target.value })}
                                                                    className="form-control"
                                                                    placeholder="Enter 6 Digit Mobile OTP"
                                                                />
                                                            </div>
                                                        )} */}

                            {/* Email OTP input */}
                            <div className="form-group">
                              <label
                                htmlFor="emailOtp"
                                className="form-control-label"
                              >
                                Enter Email OTP
                              </label>
                              <input
                                type="text"
                                id="emailOtp"
                                onChange={(event) =>
                                  this.setState({
                                    emailotp: event.target.value,
                                  })
                                }
                                className="form-control"
                                placeholder="Enter 6 Digit Email OTP"
                              />
                            </div>
                          </form>
                        </div>
                        <div className="modal-footer">
                          {this.state.isLoading ? (
                            <button
                              type="button"
                              onClick={() => this.VerifyMobile()}
                              className="btn btn-primary w-100"
                            >
                              Verify Account{" "}
                              <FontAwesomeIcon icon={faArrowRight} />
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-primary w-100"
                              disabled
                            >
                              <span className="spinner-border spinner-border-sm"></span>{" "}
                              Please Wait..
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {this.state.signupDone ? (
                    <div
                      className={`modal fade show`}
                      style={{
                        display: "block",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                      }}
                      tabindex="-1"
                      role="dialog"
                      aria-labelledby="signupModalLabel"
                      aria-hidden="false"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="signupModalLabel">
                              Signup Successful
                            </h5>
                            <button
                              type="button"
                              className="close"
                              onClick={() =>
                                this.setState({ signupDone: false })
                              }
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body text-center">
                            <img
                              alt="Empty Data"
                              style={{ width: "100px" }}
                              src={EmptyImg}
                              className="mb-3"
                            />
                            <p>
                              Your account has been created successfully, <br />
                              Please find your login credentials in your email.
                            </p>
                          </div>
                          <div className="modal-footer">
                            <Link
                              className="btn btn-primary"
                              to="my-account"
                              onClick={() =>
                                this.setState({ signupDone: false })
                              }
                            >
                              Login To Account
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-5  col-lg-5 col-xl-5">
                <div className="mb-3 login-right">
                  <h3>Signup</h3>
                  <p>
                    Signup in to your account to <br /> continue.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default ResellerSignup;
