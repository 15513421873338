import React, { useEffect, useState } from "react";
import ChatLeftMenu from "./ChatLeftMenu";
import DashboardLayout from "../../../UI/Layout/DashboardLayout";
import Axios from "axios";
import { NODE_HOST_URL } from "../../api/Api";
import Select, { components } from "react-select";
import Alert from "../../../UI/Alert/Alert";
import Spining from "../../../UI/Spining/Spining";

const Settings = () => {
  const lStorage = JSON.parse(localStorage.getItem("login"));
  const user_id = lStorage.user.userId;
  const token = lStorage.token;
  const brand_number = localStorage.getItem("wp-cmpy-brand_number");

  const [selectedHs, setSelectedHs] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [agentList, setAgentList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState([]);
  const [tagStatus, setTagStatus] = useState(false);
  const [fallback, setFallback] = useState({ time: "", strategy: "" });
  const [error, setError] = useState({
    successMessage: false,
    bgColor: "alert-danger",
    errorMessage: "",
  });

  useEffect(() => {
    fetchAgent(selectedOption);
  }, [selectedOption]);

  const fetchAgent = async (selectType) => {
    setIsLoading(true);
    const forAgentData = {
      user_id,
      token,
      method: "retrieve",
      agent_type: selectType,
    };
    try {
      const { data } = await Axios.post(
        `${NODE_HOST_URL}/whatsapp_agent`,
        forAgentData
      );

      if (data.success) {
        const newAgentList = data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setAgentList(newAgentList);
      } else {
        setAgentList([]);
      }
    } catch (error) {
      console.error(error.message);
      // setError({
      //   successMessage: true,
      //   bgColor: "alert-danger",
      //   errorMessage: "Failed to fetch agents.",
      // });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const retrieveHuntStrategy = async () => {
      const dataForSetting = {
        user_id,
        token,
        method: "retrieve_hunt_strategy",
        user_type: "admin",
        brand_number,
        channel: "whatsapp",
      };
      try {
        const { data } = await Axios.post(
          `${NODE_HOST_URL}/whatsapp_setting`,
          dataForSetting
        );
        if (data.success) {
          setSelectedHs(data.data.hunt_strategy);
          setSelectedAgent(data.data.teams ? [data.data.teams] : []);
          setFallback({
            time: data.data.fallback_time ? data.data.fallback_time : "",
            strategy: data.data.fallback_strategy
              ? data.data.fallback_strategy
              : "",
          });
          setTagStatus(data.data.tag_status === 1 ? true : false);
        }
      } catch (error) {
        console.log(error.message);
        setError({
          successMessage: true,
          bgColor: "alert-danger",
          errorMessage: "Failed to retrieve hunt strategy.",
        });
      }
    };
    retrieveHuntStrategy();
  }, [user_id, token, brand_number]);

  const handleHuntStrategyChange = (event) => {
    setSelectedHs(event.target.value);
  };

  const handleFallbackTime = (e) => {
    const value = e.target.value;
    setFallback((prevState) => ({ ...prevState, time: value }));
  };

  const handleFallbackStrategy = (e) => {
    const value = e.target.value;
    setFallback((prevState) => ({ ...prevState, strategy: value }));
  };

  const handleAgent = (e) => {
    setSelectedAgent(e);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      cursor: "pointer",
    }),
  };

  const Option = (props) => (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let agentList;

    if (selectedOption === "agent") {
      agentList = selectedAgent.map((agent) => agent.value);
    }

    const dataForSetting = {
      user_id,
      token,
      method: "hunt_strategy",
      user_type: "admin",
      brand_number,
      agent_type: selectedOption,
      channel: "whatsapp",
      hunt_strategy: selectedHs,
      fallback_time: fallback.time===""?0:fallback.time,
      fallback_strategy: fallback.strategy,
      agent_id: selectedOption === "agent" ? agentList : selectedAgent.value,
      tag_status:tagStatus?"1":"0"
    };

    try {
      const { data } = await Axios.post(
        `${NODE_HOST_URL}/whatsapp_setting`,
        dataForSetting
      );
      if (data.success) {
        setError({
          successMessage: true,
          bgColor: "alert-success",
          errorMessage: data.message,
        });
      }
    } catch (error) {
      console.log(error.message);
      setError({
        successMessage: true,
        bgColor: "alert-danger",
        errorMessage: error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const handleTag = (e) => {
    const value = e.target.checked;
    setTagStatus(value ? true : false);
  };

  return (
    <DashboardLayout>
      {isLoading && <Spining />}
      <main className="content">
        {error.successMessage && (
          <Alert type={error.bgColor}>{error.errorMessage}</Alert>
        )}
        <div className="container-fluid p-0">
          <div className="d-flex wFull">
            <div className="ChatLive_card col-md-2">
              <ChatLeftMenu />
            </div>
            <div className="col-md-10">
              <div className="ChatLive_card d-flex flex-column wFull px-3 py-3">
                <div className="TitleBox clearfix">
                  <h3>Settings</h3>
                </div>

                <form onSubmit={handleUpdate}>
                  <div className="row">
                    <div className="d-flex ml-3">
                      <label className="mr-5">Tag Priority</label>
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={tagStatus}
                          onChange={handleTag}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-6">
                      <label>
                        Hunt Strategy
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="mb-2">
                        <select
                          className="form-control"
                          value={selectedHs}
                          onChange={handleHuntStrategyChange}
                          required
                        >
                          <option value="">Select Hunt Strategy</option>
                          <option value="random">Random</option>
                          <option value="sequence">Sequence</option>
                          <option value="parallel">Parallel</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      {agentList.length > 0 && (
                        <>
                          <label>
                            {selectedOption === "agent"
                              ? "Agents"
                              : selectedOption === "team"
                              ? "Select Team"
                              : "Managers"}
                          </label>
                          <div className="mt-1">
                            <Select
                              placeholder={`Select ${selectedOption}`}
                              onChange={handleAgent}
                              options={agentList}
                              isLoading={isLoading}
                              value={selectedAgent}
                              isMulti={selectedOption === "agent"}
                              components={
                                selectedOption === "agent"
                                  ? { Option }
                                  : undefined
                              }
                              styles={customStyles}
                              classNamePrefix="select"
                              hideSelectedOptions={false}
                              required
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {selectedHs === "parallel" && (
                    <div className="row">
                      <div className="col-md-6">
                        <label>
                          Fallback Time
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          className="form-control"
                          required
                          onChange={handleFallbackTime}
                          value={fallback.time}
                        >
                          <option value="">Select time</option>
                          {[1, 2, 3, 4, 5].map((time) => (
                            <option key={time} value={time}>
                              {time} minute{time > 1 ? "s" : ""}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-6">
                        <label>
                          Fallback Strategy
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          className="form-control"
                          required
                          onChange={handleFallbackStrategy}
                          value={fallback.strategy}
                        >
                          <option value="">Select Strategy</option>
                          <option value="random">Random</option>
                          <option value="sequence">Sequence</option>
                        </select>
                      </div>
                    </div>
                  )}
                  <div className="form-row mt-4" style={{ float: "right" }}>
                    <div
                      className="form-group col-md-2"
                      style={{ padding: "0 50px" }}
                    >
                      <button type="submit" className="btn btn-success">
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </DashboardLayout>
  );
};

export default Settings;
