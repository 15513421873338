import React from "react";
import { Link } from "react-router-dom";
import { TbTableShortcut } from "react-icons/tb";
import { LuMousePointer2 } from "react-icons/lu";
import { IoMdSettings } from "react-icons/io";
import { FaUserTag } from "react-icons/fa6";
import { useLocation } from "react-router-dom";

const ChatLeftMenu = () => {
  const urlLocation = useLocation();
  const urlPath = urlLocation.pathname.split('/');

  
  return (
    <>
      <div className="py-3">
        <div className="nav flex-column liveChatList">
          <h3>Chat Settings</h3>
          <ul className="sidebar-nav">
          <li>
              <Link
                to="/dashboard/chat-setting"
                className={urlPath[3] ===undefined  ? "active" : ""}
                
              >
                <span className="iconWH"></span>
                <IoMdSettings />Hunt Strategy
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/chat-setting/tag"
                className={urlPath[3] ==="tag"  ? "active" : ""}
                
              >
                <span className="iconWH"></span>
                <FaUserTag /> Tag
              </Link>
            </li>
            {/* <li>
              <Link
                to="/dashboard/chat-setting/shortcuts"
                className={urlPath[3] ==="shortcuts"  ? "active" : ""}
              >
                <span className="iconWH"></span>
                <TbTableShortcut /> Shortcuts
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/chat-setting/trigger"
                className={urlPath[3] ==="trigger"  ? "active" : ""}
              >
                <span className="iconWH"></span>
                <LuMousePointer2 /> Trigger
              </Link>
            </li> */}
            {/* <li>
              <Link
                to="/dashboard/chat-setting/notifications"
                className={activeLink === "notifications" ? "active" : ""}
                onClick={() => handleLinkClick("notifications")}
              >
                <span className="iconWH"></span>
                <IoNotificationsOutline /> Mail Notifications
              </Link>
            </li> */}
            {/* <li>
              <Link
                to="/dashboard/webhooks"
                className={activeLink === "webhooks" ? "active" : ""}
                onClick={() => handleLinkClick("webhooks")}
              >
                <span className="iconWH"></span>
                <FaLink /> Webhooks
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ChatLeftMenu;
