import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../UI/Layout/DashboardLayout";
import {
  TbMessage2Pin,
  TbMessage2Bolt,
  TbMessage2Plus,
  TbMessage2Check,
  TbMessage2Share,
  TbMessage2X,
} from "react-icons/tb";
import { LiaUserTieSolid } from "react-icons/lia";
import Axios from "axios";
import { NODE_HOST_URL } from "../../api/Api";
import dayjs from "dayjs";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const ChatReport = () => {
  const lStorage = JSON.parse(localStorage.getItem("login"));
  const user_id = lStorage.user.userId;
  const token = lStorage.token;
  const brand_number = localStorage.getItem("wp-cmpy-brand_number");
  const [todayReport, setTodatReport] = useState([]);
  const [agentList, setAgentList] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchReport = async () => {
      try {
        const { data } = await Axios.post(`${NODE_HOST_URL}/whatsapp_report`, {
          user_id,
          token,
          method: "today_report",
          brand_number,
          user_type: "admin",
        });
        if (data.success) {
          setTodatReport(data.data);
        }
      } catch (error) {
        console.error(error.message);
      }
    };

    // const fetchLiveAgents = async () => {
    //   if (!currentUser.parent_id) return;
    //   setLoading((prevState) => ({ ...prevState, activeAgent: true }));

    //   try {
    //     const { data } = await axios.post(`${BASE_URL2}/whatsapp_agent`, {
    //       user_id: currentUser.parent_id,
    //       token: currentUser.parent_token,
    //       method: "live_agents",
    //       user_type: currentUser.user_type,
    //       agent_id: currentUser.user_id,
    //     });
    //     if (data.success) {
    //       setLiveAgentsData(data.data);
    //     }
    //   } catch (error) {
    //     console.error(error.message);
    //     toast.error(error.message);
    //   } finally {
    //     setLoading((prevState) => ({ ...prevState, activeAgent: false }));
    //   }
    // };
    const fetchAgent = async () => {
      const data = {
        user_id,
        method: "retrieve_agent",
        token,
        user_type: "admin",
      };

      try {
        const response = await fetch(`${NODE_HOST_URL}/whatsapp_agent`, {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify(data),
        });
        const result = await response.json();
        if (result.success) {
          const filterAgent = result.data.filter(
            (list) =>
              list.agent_type === "agent" && list.update_first_password === 1
          );
          setAgentList(filterAgent);
        } else {
          console.log("Failed to fetch data:", response.statusText);
        }
      } catch (error) {
        console.error("Error occurred while fetching data:", error);
      }
    };
    fetchReport();
    fetchAgent();
  }, [user_id, token, brand_number]);

  return (
    <div>
      <DashboardLayout>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="row">
              {/* <div className="col-md-5">
                <div className="chatBorderBlock px-4 py-4 MinH">
                  <div className="d-flex">
                    <span className="iconCircluate yellowish mr-2">
                      <PiUserGearDuotone />
                    </span>
                    <h6 className="d-flex">Live Visitors</h6>
                  </div>
                </div>
              </div> */}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <div className="chatBorderBlock px-4 py-4 MinHalf flex-col">
                      <div className="d-flex">
                        <span className="iconCircluate yellowish mr-2">
                          <TbMessage2Pin />
                        </span>
                      </div>
                      <h6 className="d-flex txt-bold">Live Chat</h6>
                      <div className="d-flex">
                        <h4 className="align-self-sm-center">0</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="chatBorderBlock px-4 py-4 MinHalf">
                      <div className="d-flex">
                        <span className="iconCircluate voiletish mr-2">
                          <span class="mdi mdi-chat-processing-outline">
                            <TbMessage2Plus />
                          </span>
                        </span>
                      </div>
                      <h6 className="d-flex">Total Chats</h6>
                      <div className="d-flex">
                        <h4 className="align-self-sm-center">
                          {Array.isArray(todayReport?.total_chat)
                            ? todayReport.total_chat.length
                            : 0}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="chatBorderBlock px-4 py-4 MinHalf">
                      <div className="d-flex">
                        <span className="iconCircluate blueish mr-2">
                          <span class="mdi mdi-chat-processing-outline">
                            <TbMessage2Bolt />
                          </span>
                        </span>
                      </div>
                      <h6 className="d-flex">New Chats</h6>
                      <div className="d-flex">
                        <h4 className="align-self-sm-center">
                          {Array.isArray(todayReport?.new_chat)
                            ? todayReport.new_chat.length
                            : 0}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-4">
                    <div className="chatBorderBlock px-4 py-4 MinHalf">
                      <div className="d-flex">
                        <span className="iconCircluate orangeish mr-2">
                          <TbMessage2Share />
                        </span>
                      </div>
                      <h6 className="d-flex">Repeated Chats</h6>
                      <div className="d-flex">
                        <h4 className="align-self-sm-center">
                          {Array.isArray(todayReport?.total_repeat)
                            ? todayReport.total_repeat.length
                            : 0}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="chatBorderBlock px-4 py-4 MinHalf">
                      <div className="d-flex">
                        <span className="iconCircluate greenish mr-2">
                          <TbMessage2Check />
                        </span>
                      </div>
                      <h6 className="d-flex">Read Chat</h6>
                      <div className="d-flex">
                        <h4 className="align-self-sm-center">
                          {Array.isArray(todayReport?.answer_chat)
                            ? todayReport.answer_chat.length
                            : 0}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="chatBorderBlock px-4 py-4 MinHalf">
                      <div className="d-flex">
                        <span className="iconCircluate orangeish mr-2">
                          <TbMessage2X />
                        </span>
                      </div>
                      <h6 className="d-flex">Unread Chat</h6>
                      <div className="d-flex">
                        <h4 className="align-self-sm-center">
                          {Array.isArray(todayReport?.missed_chat)
                            ? todayReport.missed_chat.length
                            : 0}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <div className="chatBorderBlock px-4 py-4 MinHx">
                  <div className="d-flex">
                    <span className="iconCircluate greenish mr-2">
                      <LiaUserTieSolid />
                    </span>
                    <h6 className="flex-grow-1">Agent Chat History </h6>
                    {/* <a href="/dashboard/user-management" className="text-sm">
                      View All
                    </a> */}
                  </div>
                  <div className="tableAutoScroll">
                  <table className="table table-striped table-bordered mt-3">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {agentList.map((agent) => (
                        <tr key={agent.id}>
                          <td>{agent.id}</td>
                          <td>{agent.name}</td>
                          <td>{agent.email}</td>
                          <td>{agent.mobile}</td>
                          <td>
                            <span>
                              {agent.online === 1 ? (
                                <>
                                  <div
                                    style={{
                                      display: "inline-block",
                                      width: "10px",
                                      height: "10px",
                                      borderRadius: "50%",
                                      backgroundColor: "green",
                                    }}
                                  />
                                  <span style={{ marginLeft: "8px" }}>
                                    Online
                                  </span>
                                </>
                              ) : agent.last_seen_datetime === null ? (
                                "NA"
                              ) : (
                                dayjs(agent.last_seen_datetime).format(
                                  "DD/MM/YYYY h:mm A"
                                )
                              )}
                            </span>
                          </td>
                          <td>
                            <Link
                              to={`/dashboard/chat-report/agent-report/${agent.id}?name=${agent.name}`}
                            >
                              View More
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </DashboardLayout>
    </div>
  );
};

export default ChatReport;
