import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AdminHeader from '../Header/AdminHeader';
import AdminFooter from '../Footer/AdminFooter';
import AdminLeftMenu from '../Navbar/AdminLeftMenu';
import { userloginDetails } from '../Utils/AdminAuth';
import Error from '../Dashboard/Error';
import { HOST_URL } from '../Dashboard/api/Api';
class ChangeCurrency extends Component {

    constructor() {

        const lStorage = userloginDetails();
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;
        const isAdmin = lStorage.user.isAdmin;

        super();

        this.state = {
            user_id: userId,
            token: userToken,
            userType: isAdmin,
            successMessage: false,
            ChangeCurrencyAlert: false,
            deleteWord: '',
            seletedUserId: '',
            errorMessage: '',
            bgColor: "alert alert-success alert-dismissible",
        }
    }

    componentDidMount() {
        this.getUserDetails();
        this.setState({ seletedUserId: this.props.match.params.id });
    }

    ChangeCurrency() {

        document.querySelector('body').scrollTo(0, 0);

        if (this.state.deleteWord === 'delete') {
            fetch(`${HOST_URL}/convert_currency_user.php?user_id=${this.state.user_id}&method=changecurrency&ret_user_id=${this.props.match.params.id}&token=${this.state.token}`).then((response) => {
                response.json().then((result) => {
                    console.log(result);
                    if (result.success === true) {
                        this.setState({
                            successMessage: true,
                            bgColor: "alert alert-success alert-dismissible",
                            errorMessage: result.message
                        })

                        this.props.history.push('/admin/change-currency/' + this.props.match.params.id);

                    } else {
                        this.setState({
                            successMessage: true,
                            bgColor: "alert alert-danger alert-dismissible",
                            errorMessage: result.message
                        })
                    }
                })
            })

        } else {

            this.setState({
                successMessage: true,
                bgColor: "alert alert-danger alert-dismissible",
                errorMessage: 'Please type  Delete word to change the currency'
            })

        }

    }

    getUserDetails() {
        this.setState({ seletedUserId: this.props.match.params.id });
        fetch(`${HOST_URL}/admin_user_list.php?user_id=${this.state.user_id}&method=retrieve&type=userdetails&ret_user_id=${this.props.match.params.id}&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                // console.log('user',result);
                if (result.success === true) {
                    this.setState({
                        email: result.data[0].email,
                    });
                } else {
                    this.setState({
                        successMessage: true,
                        successBg: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })
    }

    alertCurrency() {
        this.setState({
            ChangeCurrencyAlert: true
        })
    }



    render() {
        return (
            <>
            {this.state.userType==="reseller"?<Error/>:
            <div className="wrapper">
                <AdminLeftMenu />
                <div className="main">
                    <AdminHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3>User Details <strong className='text-info'> {this.state.email} </strong>  </h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="card">


                                    <div className="card-header">
                                        <h5 className="card-title mb-0">
                                            <div className="col-auto ml-auto text-right mt-n1">
                                                <nav className="text-center" aria-label="breadcrumb">
                                                    <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">

                                                        <Link to={'/admin/user-details/' + this.state.seletedUserId}><li className="breadcrumb-item">User Details </li> </Link>


                                                        <Link to={'/admin/user-transaction-details/' + this.state.seletedUserId}>
                                                            <li className="breadcrumb-item"> Transaction Details </li></Link>

                                                        <Link to={'/admin/user-price-details/' + this.state.seletedUserId}>
                                                            <li className="breadcrumb-item"> Price Details </li></Link>

                                                        <Link to={'/admin/user-add-price/' + this.state.seletedUserId}>  <li className="breadcrumb-item "> Add Credit </li></Link>

                                                        <Link to={'/admin/user-entity-pass/' + this.state.seletedUserId}>  <li className="breadcrumb-item"> Entity By Pass   </li></Link>

                                                        <Link to={'/admin/change-currency/' + this.state.seletedUserId}>  <li className="breadcrumb-item active"> Change Currency  </li></Link>
                                                        <Link
                                                                    to={
                                                                        "/admin/bulk-sms-route/" +
                                                                        this.state.seletedUserId
                                                                    }
                                                                >
                                                                    {" "}
                                                                    <li className="breadcrumb-item ">
                                                                        {" "}
                                                                        Bulk Sms Route Setting{" "}
                                                                    </li>
                                                                </Link>
                                                                <Link to={`/admin/agent-setting/${this.state.seletedUserId}`}><li className="breadcrumb-item">Agent Setting</li></Link>
                                                    </ol>
                                                </nav>
                                            </div>
                                        </h5>
                                    </div>

                                    <div className="card-body">


                                        {
                                            this.state.successMessage ?
                                                <div className={this.state.bgColor} role="alert">
                                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                        <span aria-hidden="true">×</span>
                                                    </button>
                                                    <div className="">
                                                        {this.state.errorMessage}
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }


                                        <div className="filter-option">

                                            <div className="row">

                                                {this.state.ChangeCurrencyAlert ?
                                                    <>
                                                        <div className="col-md-12 mb-3 text-left">
                                                            <p>Please Type delete word Into text Box </p>
                                                            <div className="input-group mb-2 mr-sm-2">

                                                                <input onChange={(event) => this.setState({ deleteWord: event.target.value })} type="text" placeholder="delete" className="form-control" />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4 text-center">
                                                            <div className="input-group mb-2 mr-sm-2">
                                                                <input type="button" onClick={() => this.ChangeCurrency()} value="Confirm to change INR ot EUR" className="btn btn-block btn-danger" />
                                                            </div>
                                                        </div>
                                                    </>

                                                    :

                                                    <div className="col-md-4 text-center">
                                                        <div className="input-group mb-2 mr-sm-2">
                                                            <input type="button" onClick={() => this.alertCurrency()} value="Change Currency INR to EUR" className="btn btn-block btn-danger" />
                                                        </div>
                                                    </div>

                                                }



                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <AdminFooter />
                </div>
            </div>
    }</>);
    }
}

export default ChangeCurrency;