import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../UI/Layout/DashboardLayout";
import Spining from "../../../UI/Spining/Spining";
import Alert from "../../../UI/Alert/Alert";
import ChatLeftMenu from "./ChatLeftMenu";
import Axios from "axios";
import { NODE_HOST_URL } from "../../api/Api";
const Tag = () => {
  const lStorage = JSON.parse(localStorage.getItem("login"));
  const user_id = lStorage.user.userId;
  const token = lStorage.token;
  const [isLoading, setIsLoading] = useState(false);
  const[tag, setTag] = useState("");
  const [tagValid, setTagValid] = useState(true);
  const [error, setError] = useState({
    successMessage: false,
    bgColor: "alert-danger",
    errorMessage: "",
  });

  const [chips, setChips] = useState([]);
useEffect(() => {
  retrieveTag();
}, []);

  const handleDelete = async(chipToDelete) => {
    const newTagArr= chips.filter((chip) => chip !== chipToDelete);
    setChips(newTagArr);
    try {
    
      const dataforAddTag={
        user_id,
        token,
        method:"add_tag",
        tag:newTagArr
      }
      
      const {data}= await Axios.post(`${NODE_HOST_URL}/whatsapp_setting`,dataforAddTag);
      if(data.success===true){
        setChips(newTagArr);
        setError({
          successMessage: true,
          bgColor: "alert-success",
          errorMessage: "Delete successfully",
        })
      }else{
        setError({
          successMessage: true,
          bgColor: "alert-danger",
          errorMessage: data.message,
        })
      }
    } catch (error) {
      console.log(error);
      setError({
        successMessage: true,
        bgColor: "alert-danger",
        errorMessage: error.message,
      })
    }
  };
  const handleTag = (e)=>{
    const value= e.target.value;
    if (value.startsWith('@')) {
      setTag(value);
      setTagValid(true);
    } else {
      setTagValid(false);
      setTag(value);
    }
    
  }
  const retrieveTag = async()=>{
    try {
      const {data} = await Axios.get(`${NODE_HOST_URL}/whatsapp_setting?user_id=${user_id}&token=${token}&method=retrieve_tag`);
      if(data.success===true){
        setChips(data.data);
      }else{
        setChips([]);
      }
    } catch (error) {
      console.log(error);
      
    }
  }

  const handleAdd =async()=>{
    if(!tag||tag===""){
      setError({
        successMessage: true,
        bgColor: "alert-danger",
        errorMessage: "Please enter tag",
      })
      return
    }
    try {
      setIsLoading(true)
      const newTagArr=[...chips,tag];
      
      const dataforAddTag={
        user_id,
        token,
        method:"add_tag",
        tag:newTagArr
      }
      
      const {data}= await Axios.post(`${NODE_HOST_URL}/whatsapp_setting`,dataforAddTag);
      if(data.success===true){
        setChips(newTagArr);
        setError({
          successMessage: true,
          bgColor: "alert-success",
          errorMessage: data.message,
        })
      }else{
        setError({
          successMessage: true,
          bgColor: "alert-danger",
          errorMessage: data.message,
        })
      }
    } catch (error) {
      console.log(error);
      setError({
        successMessage: true,
        bgColor: "alert-danger",
        errorMessage: error.message,
      })
    }
    setIsLoading(false)
  }
  return (
    <>
      <DashboardLayout>
        {isLoading && <Spining />}
        <main className="content">
          {error.successMessage && (
            <Alert type={error.bgColor}>{error.errorMessage}</Alert>
          )}
          <div className="container-fluid p-0">
            <div className="d-flex wFull">
              <div className="ChatLive_card col-md-2">
                <ChatLeftMenu />
              </div>
              <div className="col-md-10">
                <div className="ChatLive_card d-flex flex-column wFull px-3 py-3">
                  <div className="TitleBox clearfix">
                    <h3>Tag Setting</h3>
                  </div>
                  <div className="mx-4">
                    
                    <div className="row">
                      <label className="mt-1">Tags</label>
                      <div className="tag-chips-container">
                        {chips.map((chip, index) => (
                          <div className="tag-chip" key={index}>
                            <span>{chip}</span>
                            <button
                              className="tag-chip-close-btn"
                              onClick={() => handleDelete(chip)}
                            >
                              &times;
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <label>Add Tag</label>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          value={tag}
                          placeholder="@tag"
                          onChange={handleTag}
                        />
                        {!tagValid&&<small className="text-danger">Enter tag start with @</small>}
                      </div>
                      <div className="col-md-6">
                      <button className="btn btn-success" onClick={handleAdd}>Add</button>
                     
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </DashboardLayout>
    </>
  );
};

export default Tag;
