import React, { Component, useRef } from "react";
import { Link } from "react-router-dom";
import DashboardHeader from "../../Header/DashboardHeader";
import DashboardFooter from "../../Footer/DashboardFooter";
import DashboardLeftMenu from "../../Dashboard/DashboardLeftMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faEye,
  faArrowDown,
  faArrowUp,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import EmptyImg from "../../Dashboard/assets/img/create-project.png";
import "react-datepicker/dist/react-datepicker.css";
import { HOST_URL, HOST_URL_DB2 } from "../api/Api";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Spining from "../../UI/Spining/Spining";
import { IoLogoWhatsapp } from "react-icons/io";
import { BiBorderRadius } from "react-icons/bi";
import { FaSms } from "react-icons/fa";
import { WHATSAPP_TEMPLATE_LIST } from "../../../constants/ServiceConstants";
import WhatsAppCampaignListPreview from "../whatsapp/components/WhatsappCampaignListPreview";


class CampaignList extends Component {
  constructor(props) {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;


    super();
    this.state = {
      campaignList: [],
      user_id: userId,
      token: userToken,
      channel: "whatsapp",
      successShow: false,
      successMessage: "",
      bgColor: "",
      startDate: new Date(),
      endDate: new Date(),
      maxDate: null,
      loading: false,
      loadingBtn: false,
      totalData: 0,
      totalCost: 0,
      totalDelivered: 0,
      totalFailed: 0,
      totalRead: 0,
      totalSent: 0,
      totalDND: 0,
      totalOptOut: 0,
      openTemplatePopup: false,
      templateDetails: [],
      route: ""
    };
    this.channelHandler = this.channelHandler.bind(this);
  }

  componentDidMount() {
    this.getCampaignList("whatsapp");
    this.getRoute();
  }

  getRoute = async () => {
    try {
      let response = await fetch(`${HOST_URL}/get_user_route.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`)
      let result = await response.json()
      if (result.success) {
        this.setState({ route: result.data })
      }
    } catch (error) {

    }

  }

  currentDate(currentDate) {
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  addDays(date) {
    const days = 30;
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + days);
    return newDate;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.startDate !== this.state.startDate) {
      let maxDatee = this.addDays(this.state.startDate)
      this.setState({
        maxDate: maxDatee
      })
    }
  }
  getCampaignList(channel) {
    this.setState({ loading: true });
    this.setState({ loadingBtn: true });
    let sDate = this.currentDate(this.state.startDate);
    let eDate = this.currentDate(this.state.endDate);
    fetch(channel === "sms" ? `${HOST_URL_DB2}/bulk_campaign_sms.php?user_id=${this.state.user_id}&method=retrieve_new&token=${this.state.token}&channel=${channel}&from_date=${sDate}&to_date=${eDate}` :
      `${HOST_URL}/bulk_campaign_sms.php?user_id=${this.state.user_id}&method=retrieve_new&token=${this.state.token}&channel=${channel}&from_date=${sDate}&to_date=${eDate}`
    ).then((response) => {
      response.json().then((result) => {
        console.log(result)
        if (result.success === true) {
          let totalCost = 0;
          let totaldelivered = 0;
          let totalsent = 0;
          let totalread = 0;
          let totalfailed = 0;
          let contactCount = 0;
          let data = result?.data?.map((item) => {
            let intValue = parseFloat(item.campaign_cost);
            totalCost += intValue;
            totaldelivered += item.delivered;
            totalsent += item.sent;
            totalread += item.read;
            totalfailed += item.failed;
            contactCount += item.contact_count;
            return { ...item, channel: channel, user_token: this.state.token };
          });
          // console.log(data)
          this.setState({
            campaignList: data,
            totalData: contactCount,
            totalCost: totalCost,
            totalDelivered: totaldelivered,
            totalFailed: totalfailed,
            totalRead: totalread,
            totalSent: totalsent,
          });
          this.setState({ loadingBtn: false });
          this.CampaignData(data)
        } else {
          this.setState({
            campaignList: null,
          });
          this.setState({ loadingBtn: false });
        }
        if (result.success === false) {
          this.setState({
            campaignList: null,
          });
          this.setState({ loadingBtn: false });
        }
      });
    });
    this.setState({ loading: false });
  }

  deletecampaignList(id) {
    document.querySelector("body").scrollTo(0, 0);
    fetch(
      `${HOST_URL}/email_campaign.php?user_id=${this.state.user_id}&id=${id}&method=delete&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        //console.log(result.success);
        if (result.success === true) {
          this.setState({ successShow: true });
          this.setState({ errorMessage: result.message });
          this.setState({ bgColor: "alert alert-success alert-dismissible" });
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        } else {
          this.setState({ successMessage: true });
          this.setState({ errorMessage: result.message });
          this.setState({ bgColor: "alert alert-danger alert-dismissible" });
        }
      });
    });
  }

  channelHandler(e) {
    // console.log(e.target.value);
    this.setState({
      channel: e.target.value,
    });
    this.getCampaignList(e.target.value);
  }

  startDateHandler = (date) => {
    this.setState({
      startDate: date,
      // endDate: date,
    });
  };
  endDateHandler = (date) => {
    this.setState({
      endDate: date,
    });
  };

  CampaignData = (data) => {
    let totalOptOut = 0;
    let totalDND = 0;
    let totalCost = 0;


    data.forEach((x) => {
      // console.log(x.template_id.substr(-4, 4));

      totalOptOut += x.optout || 0;
      totalDND += x.dnd || 0;
      totalCost += parseFloat(x.campaign_cost) || 0;
    });

    totalCost = totalCost.toFixed(2);

    this.setState({
      totalCost: totalCost,
      totalDND: totalDND,
      totalOptOut: totalOptOut
    })
  }

  TemplateListDetails = async (templateid) => {
    // Open the popup when starting to fetch template details
    this.setState({ openTemplatePopup: true });

    const data = {
      token: this.state.token,
      user_id: this.state.user_id,
      id: templateid,
      method: "retrieveid",
    };

    try {
      let response = await fetch(`${HOST_URL}/whatsapp_template.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      if (result.success) {
        // Successfully fetched template details
        this.setState({
          templateDetails: result.data, // Update the template details state
          // openTemplatePopup: true, // Ensure the popup is still open
        });
      } else {
        // Handle failure case (optional: you could reset or handle the error)
        this.setState({
          templateDetails: null, // Optional: reset template details on failure
          // openTemplatePopup: false, // Close the popup if fetching failed
        });
      }
    } catch (error) {
      // Handle any network or unexpected errors
      console.log(error);
      this.setState({
        templateDetails: null, // Optional: reset template details on error
        // openTemplatePopup: false, // Close the popup in case of an error
      });
    }
  }



  render() {
    return (
      <div className="wrapper">
        <DashboardLeftMenu />
        <div className="main">
          <DashboardHeader />
          {this.state.loading && <Spining />}
          <main className="content">
            <div className="container-fluid p-0">
              <div className="row mb-2 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                  <h3>
                    <strong>Campaign </strong> List{" "}
                  </h3>
                </div>
                <div className="col-auto ml-auto text-right mt-n1">
                  <Link
                    className="btn btn-primary btn-sm"
                    to="/dashboard/campaign-start"
                  >
                    {" "}
                    <FontAwesomeIcon icon={faPlus} color="" /> Create New
                    Campaign{" "}
                  </Link>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-12 col-xxl-5 d-flex">
                  <div className="w-100">
                    <div className="row">
                      <div className="col-sm-2">
                        <div className="card">
                          <div className="card-body p-2">
                            <h5 className="card-title mb-0">Total Data</h5>
                            <h6 className="mt-0 mb-0">
                              {this?.state?.campaignList ? this.state.totalData : 0}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div className="card">
                          <div className="card-body p-2">
                            <h5 className="card-title mb-0">Total Delivered</h5>
                            <h6 className="mt-0 mb-0">
                              {this?.state?.campaignList ? this.state.totalDelivered : 0}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div className="card">
                          <div className="card-body p-2">
                            <h5 className="card-title mb-0">Total Failed</h5>
                            <h6 className="mt-0 mb-0">
                              {this?.state?.campaignList ? this.state.totalFailed : 0}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div className="card">
                          <div className="card-body p-2">
                            <h5 className="card-title mb-0">{this.state.channel === "sms" ? "Total Opt Out" : "Total Read"}</h5>
                            <h6 className="mt-0 mb-0">
                              {this.state.channel === "sms" ? this.state.totalOptOut : this.state.totalRead}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div className="card">
                          <div className="card-body p-2">
                            <h5 className="card-title mb-0">{this.state.channel === "sms" ? "Total DND" : "Total Sent"}</h5>
                            <h6 className="mt-0 mb-0">
                              {this.state.channel === "sms" ? this.state.totalDND : this.state.totalSent}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div className="card">
                          <div className="card-body p-2 ">
                            <h5 className="card-title mb-0">Total Cost</h5>
                            <h6 className="mt-0 mb-0">
                              {this?.state?.campaignList ? parseFloat(this.state.totalCost).toFixed(2) : 0}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {this.state.successShow ? (
                <div className={this.state.bgColor} role="alert">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <div className="">{this.state.errorMessage}</div>
                </div>
              ) : null}

              <div className="row justify-content-center">
                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                  <div className="card flex-fill ">
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <h3 className="mt-2 ml-3">Campaign Report</h3>
                      <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                        <label className="mr-2" style={{ whiteSpace: 'nowrap' }}>Select channel</label>
                        {/* <div className="input-group drop-down-icons mt-2"> */}
                          <select onChange={this.channelHandler} className="form-control mt-2 ">
                            <option value="whatsapp">Whatsapp</option>
                            <option value="sms">SMS</option>
                          </select>
                        {/* </div> */}
                      </div>
                    </div>


                    {/* <div className="card-header d-flex">
                      <div className="">
                        <h6 className="card-subtitle text-muted mt-2">
                          Select campaign Type{" "}
                        </h6>
                      </div>

                      <div style={{ width: "150px", marginLeft: "2px" }}>
                        <div className="input-group  drop-down-icons">
                          <select
                            onChange={this.channelHandler}
                            className="form-control"
                          >
                            <option value="whatsapp">Whatsapp</option>
                            <option value="sms">SMS</option>
                          </select>
                        </div>
                      </div>
                      <div className="d-flex ml-2">
                        <h5 className="card-subtitle text-muted mt-1 ml-2 mr-2">
                          From:
                        </h5>
                        <ReactDatePicker
                          dateFormat="dd/MM/yyyy"
                          selected={this.state.startDate}
                          className="form-control"
                          onChange={(date) => this.startDateHandler(date)}
                          selectsStart
                          startDate={this.state.startDate}
                        />

                        <h5 className="card-subtitle text-muted mt-1 mx-2">
                          To:
                        </h5>
                        <ReactDatePicker
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                          selected={this.state.endDate}
                          onChange={(date) => this.endDateHandler(date)}
                          selectsEnd
                          startDate={this.state.startDate}
                          minDate={this.state.startDate}
                          maxDate={this.state.maxDate}
                        />
                        <div className=" ml-2 ">
                          {this.state.loadingBtn === true ? (
                            <button
                              className="btn btn-success p-2"
                              type="button"
                              disabled
                            >
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="sr-only">Loading...</span>
                            </button>
                          ) : (
                            <button
                              className="btn btn-success p-2 "
                              onClick={() =>
                                this.getCampaignList(this.state.channel)
                              }
                            >
                              <FontAwesomeIcon
                                className="fa-lg"
                                icon={faSearch}
                              />
                            </button>
                          )}
                        </div>
                      </div>
                    </div> */}

                    <div className="card-header">
                      <div className="row">
                        <div className="col-5">
                          <label>From:</label>
                          <ReactDatePicker
                            dateFormat="dd/MM/yyyy"
                            selected={this.state.startDate}
                            className="form-control ml-2"
                            onChange={(date) => this.startDateHandler(date)}
                            selectsStart
                            startDate={this.state.startDate}
                          />
                        </div>
                        <div className="col-5">
                          <label>To:</label>
                          <ReactDatePicker
                            dateFormat="dd/MM/yyyy"
                            className="form-control ml-2"
                            selected={this.state.endDate}
                            onChange={(date) => this.endDateHandler(date)}
                            selectsEnd
                            startDate={this.state.startDate}
                            minDate={this.state.startDate}
                            maxDate={this.state.maxDate}
                          />
                        </div>
                        <div className="col-2">
                          {/* <label></label><br/> */}
                          {this.state.loadingBtn ? (
                            <button className="btn btn-success p-2 ml-2" type="button" disabled>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="sr-only">Loading...</span>
                            </button>
                          ) : (
                            <button
                              className="btn btn-success p-2 ml-2"
                              onClick={() => this.getCampaignList(this.state.channel)}
                            >
                              <FontAwesomeIcon className="fa-lg" icon={faSearch} />
                            </button>
                          )}

                        </div>
                      </div>
                      {/* <div className="d-flex flex-column">
                        <div className="d-flex align-items-center mb-2">
                          <label className="mr-2 mb-0">From:</label>
                          <label className="mr-2 mb-0">To:</label>
                        </div>
                        <div className="d-flex align-items-center">
                          <ReactDatePicker
                            dateFormat="dd/MM/yyyy"
                            selected={this.state.startDate}
                            className="form-control mr-2"
                            onChange={(date) => this.startDateHandler(date)}
                            selectsStart
                            startDate={this.state.startDate}
                          />
                          <ReactDatePicker
                            dateFormat="dd/MM/yyyy"
                            className="form-control mr-2"
                            selected={this.state.endDate}
                            onChange={(date) => this.endDateHandler(date)}
                            selectsEnd
                            startDate={this.state.startDate}
                            minDate={this.state.startDate}
                            maxDate={this.state.maxDate}
                          />
                          <div>
                            {this.state.loadingBtn ? (
                              <button className="btn btn-success p-2" type="button" disabled>
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="sr-only">Loading...</span>
                              </button>
                            ) : (
                              <button
                                className="btn btn-success p-2"
                                onClick={() => this.getCampaignList(this.state.channel)}
                              >
                                <FontAwesomeIcon className="fa-lg" icon={faSearch} />
                              </button>
                            )}
                          </div>
                        </div>
                      </div> */}
                    </div>



                    {this.state.loading === true ? (
                      <Spining />
                    ) : (
                      <div className="col-md-12">
                        {this.state.campaignList < 1 && (
                          <div className="card d-flex justify-content-center align-items-center ">
                            <h6>No Record Found</h6>
                          </div>
                        )}
                        {this.state.campaignList?.map((item, i) => (
                          <div className="card" key={i}>
                            <div className="card-body">
                              <div className="d-flex  justify-content-between">
                                <p>
                                  {" "}
                                  {this.state.channel === "whatsapp" ?
                                    <IoLogoWhatsapp className="text-success mr-2" style={{ fontSize: '2em' }} /> :
                                    <FaSms className="text-primary mr-2" style={{ fontSize: '2em' }} />}
                                  Campaign Name:{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {item.campaign_name}{" "}
                                  </span>{" "}
                                </p>
                                <p>
                                  {this.state.channel === "sms" ? (
                                    <>
                                      {
                                        item.status === 2 ? (
                                          <span className="badge bg-warning">
                                            Schedule
                                          </span>
                                        ) : item.status === 4 ? (
                                          <span className="badge bg-danger">
                                            Schedule Deleted
                                          </span>
                                        ) : null
                                      }
                                    </>
                                  ) : (
                                    <>
                                      {item.status === 1 ? (
                                        <span className="badge bg-info">
                                          Submitted
                                        </span>
                                      ) : item.status === 2 ? (
                                        <span className="badge bg-info">
                                          Schedule
                                        </span>
                                      ) : item.status === 3 ? (
                                        <span className="badge bg-success">
                                          Processing
                                        </span>
                                      ) : item.status === 5 ? (
                                        <span className="badge bg-warning">
                                          Schedule
                                        </span>
                                      ) : (
                                        <span className="badge bg-primary ">
                                          Completed
                                        </span>
                                      )}
                                    </>
                                  )}

                                </p>
                                <p>Campaign Date: {item.camp_start_datetime}</p>
                              </div>
                              <hr className="m-0 mb-1"></hr>
                              <div className="d-flex summary-data text-center">
                                <div className="mb-1">
                                  <span className="text-muted"> total </span>{" "}
                                  <br />
                                  <span className="textvalue">
                                    {" "}
                                    {/* <FontAwesomeIcon
                                    icon={faArrowUp}
                                    color="green"
                                    size="1x"
                                  />{" "} */}
                                    {item.contact_count}{" "}
                                  </span>{" "}
                                </div>

                                <div className="mb-1">
                                  <span className="text-muted">
                                    {" "}
                                    Delivered{" "}
                                  </span>{" "}
                                  <br />
                                  <span className="textvalue">
                                    {" "}
                                    {/* <FontAwesomeIcon
                                    icon={faArrowUp}
                                    color="green"
                                    size="1x"
                                  />{" "} */}
                                    {item.delivered}{" "}
                                  </span>{" "}
                                </div>

                                <div className="mb-1">
                                  <span className="text-muted"> Failed </span>
                                  <br />{" "}
                                  <span className="textvalue">
                                    {" "}
                                    {/* <FontAwesomeIcon
                                    icon={faArrowDown}
                                    color="red"
                                    size="1x"
                                  />{" "} */}
                                    {item.failed}{" "}
                                  </span>{" "}
                                </div>

                                {this.state.channel === "sms" ?
                                  (
                                    <>
                                      <div className="mb-1">
                                        <span className="text-muted"> Opt Out </span>
                                        <br />{" "}
                                        <span className="textvalue">
                                          {" "}
                                          {/* <FontAwesomeIcon
                                 icon={faArrowDown}
                                 color="red"
                                 size="1x"
                               />{" "} */}
                                          {item.optout}{" "}
                                        </span>{" "}
                                      </div>

                                      <div className="mb-1">
                                        <span className="text-muted"> DND </span>
                                        <br />{" "}
                                        <span className="textvalue">
                                          {" "}
                                          {/* <FontAwesomeIcon
                                    icon={faArrowDown}
                                    color="red"
                                    size="1x"
                                  />{" "} */}
                                          {item.dnd}{" "}
                                        </span>{" "}
                                      </div>

                                    </>
                                  ) : (
                                    <>
                                      <div className="mb-1">
                                        <span className="text-muted"> Sent </span>{" "}
                                        <br />
                                        <span className="textvalue">
                                          {" "}
                                          <FontAwesomeIcon
                                            icon={faArrowUp}
                                            color="green"
                                            size="1x"
                                          />{" "}
                                          {item.sent}{" "}
                                        </span>{" "}
                                      </div>

                                      <div className="mb-1">
                                        <span className="text-muted"> Read </span>
                                        <br />{" "}
                                        <span className="textvalue">
                                          {" "}
                                          <FontAwesomeIcon
                                            icon={faArrowUp}
                                            color="green"
                                            size="1x"
                                          />{" "}
                                          {item.read}{" "}
                                        </span>{" "}
                                      </div>
                                    </>
                                  )}

                                {this.state.route === 36 &&
                                  <div className="mb-1">
                                    <span className="text-muted"> CTA </span>
                                    <br />{" "}
                                    <Link
                                      to={`/dashboard/campaign/cta-report/${item.id}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {item.cta_tracking === 0 ? <span className="textvalue">{item.cta_tracking}</span> :
                                        <span className=" text-success">
                                          <FontAwesomeIcon icon={faEye} />
                                        </span>}
                                    </Link>
                                  </div>}

                                <div className="mb-1">
                                  <span className="text-muted"> Report </span>
                                  <br />{" "}
                                  <Link
                                    to={`/dashboard/campaign/detailed-report/${item.channel}/${item.id}/${item.campaign_name}/${item.contact_count}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <span className=" text-success">
                                      <FontAwesomeIcon icon={faEye} />
                                    </span>
                                  </Link>
                                </div>
                                <div className="mb-1">
                                  <span className="text-muted"> Download </span>
                                  <br />{" "}
                                  <a
                                    href={this.state.channel === "sms" ? `${HOST_URL_DB2}/bulk_campaign_sms.php?user_id=${item.user_id}&method=download&token=${item.user_token}&channel=${item.channel}&campaign_id=${item.id}&archive_status=${item.archive_status}&camp_start_datetime=${item.camp_start_datetime}` : `${HOST_URL}/bulk_campaign.php?user_id=${item.user_id}&method=download&campaign_id=${item.id}&channel=${item.channel}&token=${item.user_token}`}
                                    className="text-success"
                                  >
                                    <FontAwesomeIcon icon={faDownload} />{" "}
                                  </a>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between mt-1">
                                <div className="d-flex">
                                  <small className="m-0, p-0">
                                    Campaign ID: {item.id}/
                                  </small>
                                  {/* {this.state.channel === "sms" ? <small>Template ID:  xxxxxxxx{item?.template_id?.substr(-4,4)}/</small> :
                                  <small>Template ID: {item?.template_id}/</small>} */}
                                  {this.state.channel === "sms" ? <small>Template ID: {item?.template_id}/</small> :
                                    <small
                                      style={{
                                        cursor: 'pointer',
                                        backgroundColor: this.state.openTemplatePopup ? '#f0f0f0' : 'transparent',
                                      }}
                                      onMouseEnter={() => this.TemplateListDetails(item?.template_id)}
                                      onMouseLeave={() => this.setState({ openTemplatePopup: false })}
                                    // onClick={() => this.TemplateListDetails(item?.template_id)}
                                    >
                                      Template ID: {item?.template_id}
                                    </small>}
                                  {this.state.channel === "sms" ? <small>Sender ID: {item.sender_id}</small> : null}
                                </div>
                                <small>
                                  Campaign Cost:{" "}
                                  {parseFloat(item.campaign_cost).toFixed(2)}
                                </small>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    <WhatsAppCampaignListPreview templateDetails={this.state.templateDetails} openTemplatePopup={this.state.openTemplatePopup} />

                    {/* <AdvanceDataTable
                      tableName={""}
                      tableCell={WHATSAPP_CAMPAIGN_LIST_CONST}
                      tableData={this.state.campaignList}
                      isLoading={this.state.loading}
                      isError={this.state.ErrorResponse}
                    /> */}

                    {/* {this.state.campaignList ? (
                      <table className="table table-hover text-left  project  my-0">
                        <thead>
                          <tr>
                            <th>Campaign ID</th>
                            <th>Campaign Name</th>
                            {this.state.channel === "whatsapp" && (
                              <th> Template ID </th>
                            )}
                            <th>Total Campaign Data</th>
                            <th>Campaign Start On</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.filterCampaignList.map((itam, i) => (
                            <tr key={i}>
                              <td>{itam.id}</td>
                              <td>{itam.campaign_name}</td>
                              {this.state.channel === "whatsapp" && (
                                <td>{itam.template_id}</td>
                              )}

                              <td>
                                <span className="badge bg-primary">
                                  {itam.contact_count}
                                </span>
                              </td>
                              <td> {itam.camp_start_datetime} </td>
                              <td>
                                {" "}
                                {itam.status === 1 ? (
                                  <span className="badge bg-info">
                                    Submitted
                                  </span>
                                ) : itam.status === 2 ? (
                                  <span className="badge bg-info">
                                    Schedule
                                  </span>
                                ) : itam.status === 3 ? (
                                  <span className="badge bg-success">
                                    Processing
                                  </span>
                                ) : itam.status === 5 ? (
                                  <span className="badge bg-warning">
                                    Schedule{" "}
                                  </span>
                                ) : (
                                  <span className="badge bg-primary ">
                                    Completed
                                  </span>
                                )}{" "}
                              </td>
                              <td>
                                {itam.status === 5 ? null : (
                                  <a
                                    href={`/dashboard/campaign/summary-report/${
                                      this.state.channel
                                    }/${itam.id}/${encodeURIComponent(
                                      itam.campaign_name
                                    )}`}
                                  >
                                    <span className="badge bg-success">
                                      {" "}
                                      <FontAwesomeIcon
                                        icon={faEye}
                                      /> Report{" "}
                                    </span>
                                  </a>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <div className="text-center pb-5">
                        <div className="card-body">
                          <img
                            alt="Empty Data"
                            className="project-empty-img"
                            src={EmptyImg}
                          />
                          <p>No Campaign Yet</p>
                          <Link
                            className="btn  btn-success btn-sm"
                            to="/dashboard/campaign-start"
                          >
                            Create New Campaign
                          </Link>
                        </div>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </main>

          <DashboardFooter />
        </div>
      </div>
    );
  }
}

export default CampaignList;
