import React, { useState } from "react";
import DashboardLayout from "../../../UI/Layout/DashboardLayout";
import ChatLeftMenu from "./ChatLeftMenu";
import { AiOutlineUser, AiTwotoneDelete } from "react-icons/ai";
import { FiArrowLeft } from "react-icons/fi";

const Notifications = () => {
  const [selectedBox, setSelectedBox] = useState(null);
  const handleSelect = (boxName) => {
    setSelectedBox(boxName);
  };
  const closeBox = () => {
    setSelectedBox(null);
  };

  return (
    <>
      <DashboardLayout>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="d-flex wFull">
              <div className="ChatLive_card col-md-2">
                <ChatLeftMenu />
              </div>
              <div className="col-md-10">
                <div className="ChatLive_card d-flex flex-column wFull px-3 py-3">
                  <div className="TitleBox">
                    <h5>Mail Notification</h5>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="px-4 py-3 borderBox alert-secondary mb-2">
                        <label>Send all Chat Transcripts to</label>
                        <div class="input-group drop-down-icons mb-2">
                          <select class="form-control">
                            <option>Nobody</option>
                            <option>Department selected in prechat form</option>
                          </select>
                        </div>
                        <label>
                          And up to 5 other Agents or email addresses
                        </label>
                        <div class="input-group mb-2">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Trigger Name"
                          />
                        </div>
                      </div>

                      <div className="px-4 py-3 borderBox alert-secondary mt-4 mb-2">
                        <label>Send New Ticket notifications to</label>
                        <div class="input-group drop-down-icons mb-2">
                          <select class="form-control">
                            <option>All</option>
                            <option>Nobody</option>
                            <option>Only Admins</option>
                          </select>
                        </div>
                        <label>And up to 5 other Agents</label>
                        <div class="input-group mb-2">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Trigger Name"
                          />
                        </div>
                      </div>

                      <div className="px-4 py-3 borderBox alert-secondary mt-4 mb-2">
                        <label>Send Offline Form notifications to</label>
                        <div class="input-group drop-down-icons mb-2">
                          <select class="form-control">
                            <option>All</option>
                            <option>Department selected in prechat form</option>
                          </select>
                        </div>
                        <label>
                          And up to 5 other Agents or email addresses
                        </label>
                        <div class="input-group mb-2">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Trigger Name"
                          />
                        </div>
                      </div>

                      <div className="px-4 py-3 borderBox alert-secondary mt-4 mb-2">
                        <label>Send Missed Chat notifications to</label>
                        <div class="input-group drop-down-icons mb-2">
                          <select class="form-control">
                            <option>All</option>
                            <option>Department selected in prechat form</option>
                          </select>
                        </div>
                        <label>
                          And up to 5 other Agents or email addresses
                        </label>
                        <div class="input-group mb-2">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Trigger Name"
                          />
                        </div>
                      </div>

                      <div className="col-md-12 mb-4">
                        <button className="btn btn-success btn-sm">Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </DashboardLayout>
    </>
  );
};

export default Notifications;
