import React, { useState } from "react";
import DashboardLayout from "../../../UI/Layout/DashboardLayout";
import ChatLeftMenu from "./ChatLeftMenu";
import { AiOutlineUser, AiTwotoneDelete } from "react-icons/ai";
import { FiArrowLeft } from "react-icons/fi";

const ShortCut = () => {
  const [selectedBox, setSelectedBox] = useState(null);
  const handleSelect = (boxName) => {
    setSelectedBox(boxName);
  };
  const closeBox = () => {
    setSelectedBox(null);
  };

  return (
    <>
      <DashboardLayout>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="d-flex wFull">
              <div className="ChatLive_card col-md-2">
                <ChatLeftMenu />
              </div>
              <div className="col-md-10">
                <div className="ChatLive_card d-flex flex-column wFull px-3 py-3">
                  <div className="TitleBox">
                    <h3>
                      <a href="/dashboard/chat-setting">
                        <FiArrowLeft /> Back
                      </a>
                    </h3>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="col-md-12 mb-2">
                        <label>Shortcut</label>
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text">/</span>
                          </div>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Enter Shortcut"
                          />
                        </div>
                      </div>

                      <div className="col-md-12 mb-2">
                        <label>Message</label>
                        <div class="input-group mb-3">
                          <textarea
                            type="text"
                            class="form-control"
                            placeholder="Enter Message"
                          />
                        </div>
                      </div>
                      {selectedBox === "ticket" && (
                        <div class="col-md-12">
                          <div
                            class="alert alert-secondary borderBox alert-dismissible fade show"
                            role="alert"
                          >
                            Ticket Form
                            <button
                              type="button"
                              class="close"
                              data-dismiss="alert"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                        </div>
                      )}

                      {selectedBox === "lead" && (
                        <div class="col-md-12">
                          <div
                            class="alert alert-secondary borderBox alert-dismissible fade show"
                            role="alert"
                          >
                            Lead Capture Form
                            <button
                              type="button"
                              class="close"
                              data-dismiss="alert"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                        </div>
                      )}

                      {selectedBox === "message" && (
                        <div class="col-md-12 mb-3">
                          <div class="borderBox px-4 py-2">
                            <label className="text-sm">Suggested Message</label>
                            <button
                              type="button"
                              class="close float-right"
                              data-dismiss="alert"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>

                            <div class="input-group mb-3">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Enter Message"
                              />
                              <div class="input-group-append">
                                <span class="input-group-text">
                                  <AiTwotoneDelete size={20} />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {selectedBox === "file" && (
                        <div class="col-md-12 mb-3">
                          <div class="borderBox px-4 py-2">
                            <label className="text-sm">File</label>
                            <button
                              type="button"
                              class="close float-right"
                              data-dismiss="alert"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="input-group mb-3">
                              <input
                                type="file"
                                class="form-control"
                                placeholder="Enter Message"
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="col-md-12 mb-4 form-group">
                        <div class="dropdown">
                          <button
                            class="btn btn-secondary btn-sm dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Add Element
                          </button>
                          <div
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <a
                              class="dropdown-item"
                              onClick={() => handleSelect("ticket")}
                            >
                              Ticket Form
                            </a>
                            <a
                              class="dropdown-item"
                              onClick={() => handleSelect("lead")}
                            >
                              Lead Capture Form
                            </a>
                            <a
                              class="dropdown-item"
                              onClick={() => handleSelect("message")}
                            >
                              Suggested Messages
                            </a>
                            <a
                              class="dropdown-item"
                              onClick={() => handleSelect("file")}
                            >
                              File
                            </a>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12 mb-4 form-group">
                        <label>Action</label>

                        {selectedBox == "transferChat" && (
                          <div
                            class="alert alert-success alert-dismissible fade show"
                            role="alert"
                          >
                            Transfer Chat to &nbsp;
                            <span className="badge badge-success">A</span>{" "}
                            &nbsp; all agent{" "}
                            <button
                              type="button"
                              class="close"
                              data-dismiss="alert"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                        )}
                        {selectedBox == "changeTag" && (
                          <div
                            class="alert alert-success alert-dismissible fade show"
                            role="alert"
                          >
                            <label class="wFull">Add Tag</label>
                            <div class="input-group">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Search or Create"
                              />
                            </div>
                            <button
                              type="button"
                              class="close"
                              data-dismiss="alert"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                        )}
                        <div className="w-full">
                          <div class="dropdown">
                            <button
                              class="btn btn-secondary btn-sm dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              + Action
                            </button>
                            <div
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a
                                class="dropdown-item"
                                onClick={() => handleSelect("transferChat")}
                              >
                                Transfer Chat
                              </a>
                              <a
                                class="dropdown-item"
                                onClick={() => handleSelect("changeTag")}
                              >
                                Change Tags
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12 mb-4">
                        <label>Access</label>
                        <div className="w-full">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              value="option1"
                            />
                            <label class="form-check-label" for="inlineRadio1">
                              Public
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio2"
                              value="option2"
                            />
                            <label class="form-check-label" for="inlineRadio2">
                              Personal
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-4">
                        <button
                          type="button"
                          class="btn btn-sm btn-outline-dark"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          class="btn btn-outline-danger btn-sm"
                        >
                          Delete
                        </button>
                        <button className="btn btn-success btn-sm">Save</button>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="ChatPreviewFrame wFull d-flex align-items-start flex-column">
                        <div className="d-flex wFull alert-dark px-3 py-3">
                          <span className="iconCircluate bg-white mr-2">
                            <AiOutlineUser />
                          </span>
                          <h6 className="d-flex mb-0">Widget Previews</h6>
                        </div>
                        <div class="chat-window d-flex flex-column">
                          <div class="chat-bubble sender">
                            Sorry to keep you waiting, unfortunately all of our
                            agents are currently busy or away, please leave a
                            message and we will get back to you as soon as
                            possible. What’s the best email to reach you on?
                          </div>
                          {/* <div class="chat-bubble receiver">
                            I have a question about Bootstrap.
                          </div> */}
                        </div>

                        <div className="d-flex px-3 py-2 justify-content-center borderBox wFull text-center">
                          This is the Preview
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </DashboardLayout>
    </>
  );
};

export default ShortCut;
