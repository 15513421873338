import React, { useEffect, useState } from 'react';
import AdminHeader from '../../Header/AdminHeader'
import AdminLeftMenu from '../../Navbar/AdminLeftMenu'
import AdminFooter from '../../Footer/AdminFooter'
import { Link, useParams } from 'react-router-dom';
import { userloginDetails } from '../../Utils/AdminAuth';
import api from "../../../axios/Axios";
import { ADMIN_USERS_LIST } from '../../../constants/ServiceConstants';
import Spining from '../../UI/Spining/Spining';
import Alert from '../../UI/Alert/Alert';

const ChangeRouteSetting = () => {
    let { id, brandNumber } = useParams();
    const adminAuthDetails = userloginDetails();

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setisError] = useState(false);
    const [errorType, seterrorType] = useState("");
    const [ErrorResponse, setErrorResponse] = useState(null);
    const [changeRouteOptions, setChangeRouteOptions] = useState([]);
    const [route, setRoute] = useState();



    useEffect(() => {
        // getWhatsAppDetails();
      getRouteDetails();
      getRouteOptions();
    }, []);

    // const getWhatsAppDetails = async () => {
    //     try {
    //         const { data } = await api.post(ADMIN_USERS_LIST, {
    //             token: adminAuthDetails.token,
    //             user_id: adminAuthDetails.user.userId,
    //             ret_user_id: id,
    //             brand_number: brandNumber,
    //             method: "ret_whatsapp_profile",
    //         });
    //         if (data.success === true) {
    //             setwhatsApp({
    //                 address: data.data[0].address,
    //                 brand_number: data.data[0].brand_number,
    //                 category: data.data[0].category,
    //                 comp_name: data.data[0].comp_name,
    //                 description: data.data[0].description,
    //                 email: data.data[0].email,
    //                 image_url: data.data[0].image_url,
    //                 number_not_active: data.data[0].number_not_active,
    //                 status: data.data[0].status,
    //                 app_id: data.data[0].app_id,
    //                 waba_id: data.data[0].waba_id,
    //                 phone_id: data.data[0].phone_number_id,
    //                 daily_sending_limit: data.data[0].daily_sending_limit,
    //             });
    //             setIsLoading(false);
    //         } else {
    //             setErrorResponse(data.message);
    //             setIsLoading(false);
    //         }
    //     } catch (err) {
    //         console.log(err);
    //     }
    // };

  
    const getRouteDetails = async () => {
        try {
          const { data } = await api.post(ADMIN_USERS_LIST, {
            token: adminAuthDetails.token,
            user_id: adminAuthDetails.user.userId,
            ret_user_id: id,
            method: "ret_whatsapp_route",
          });
          // console.log('ret_whatsapp_route', data)
          if (data.success === true) {
            setRoute(data.data);
            setIsLoading(false);
          } else {
            setErrorResponse(data.message);
            setIsLoading(false);
          }
        } catch (err) {
          console.log(err);
        }
      };


    const getRouteOptions = async () => {
        setIsLoading(true);
        try {
          const { data } = await api.post(ADMIN_USERS_LIST, {
            token: adminAuthDetails.token,
            user_id: adminAuthDetails.user.userId,
            ret_user_id: id,
            brand_number: brandNumber,
            method: "ret_whatsapp_route_master",
          });
    
          if (data.success === true) {
            setIsLoading(false);
    
            setChangeRouteOptions(data.data);
          } else {
            setChangeRouteOptions([]);
            setIsLoading(false);
          }
        } catch (err) {
          console.log(err);
        }
      };

    const UpdateRoute = async () => {
        try {
          const { data } = await api.post(ADMIN_USERS_LIST, {
            token: adminAuthDetails.token,
            user_id: adminAuthDetails.user.userId,
            ret_user_id: id,
            route_id: route,
            method: "update_whatsapp_route",
          });
    
          if (data.success === true) {
            setisError(true);
            setErrorResponse(data.message);
            seterrorType("alert-success");
            setTimeout(function () {
              window.location.reload();
            }, 2000);
          } else {
            setErrorResponse(data.message);
            setisError(true);
            seterrorType("alert-danger");
            setIsLoading(false);
          }
        } catch (err) {
          console.log(err);
        }
      };

    return (
        <div>
            <div className="wrapper">
                <AdminLeftMenu />
                <div className="main">
                    <AdminHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                            {isLoading && <Spining />}
                                <div className="col-auto d-none d-sm-block">
                                    <h3><strong className='text-dark'>WhatsApp User List</strong></h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">
                                            <nav className="text-center" aria-label="breadcrumb">
                                                <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
                                                    <Link to={`/admin/whatsapp-account-profile-details/${id}`}><li style={{fontSize:"13px"}} className="breadcrumb-item">Profile Details</li></Link>
                                                    <Link to={`/admin/whatsapp-account-whatsapp-template-list/${id}`}><li style={{fontSize:"13px"}} className="breadcrumb-item">Whatsapp Template List</li></Link>
                                                    <Link to={`/admin/whatsapp-account-update-whatsapp-token/${id}`}><li style={{fontSize:"13px"}} className="breadcrumb-item">Update Whastapp Token</li></Link>
                                                    <Link to={`/admin/whatsapp-account-change-route/${id}`}><li style={{fontSize:"13px"}} className="breadcrumb-item active">Change Route</li></Link>
                                                    <Link to={`/admin/update-waba-id/${id}`}><li style={{fontSize:"13px"}} className="breadcrumb-item">Update Waba Id</li></Link>
                                                </ol>
                                            </nav>
                                        </h5>
                                    </div>
                                    <div className="card-body">
                                    {isError && <Alert type={errorType}> {ErrorResponse} </Alert>}
                                        <div className="form-row ">
                                            <div className="form-group col-md-12">
                                                <label className="col-form-label">Route Name:</label>
                                                <select
                                                    value={route?.[0]?.routeid || route}
                                                    onChange={(e) => setRoute(e.target.value)}
                                                    className="form-control"
                                                >
                                                    <option value="">Select Route </option>

                                                    {changeRouteOptions?.length
                                                        ? changeRouteOptions.map((options) => (
                                                            <option value={options.id} key={options.id}>
                                                                {options.route_name}
                                                            </option>
                                                        ))
                                                        : null}
                                                </select>
                                            </div>
                                        </div>
                                        <button
                                            onClick={UpdateRoute}
                                            className="btn btn-sm float-right btn-primary"
                                        >
                                            Update Route
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <AdminFooter />
                </div>
            </div>
        </div>
    );
};

export default ChangeRouteSetting;
