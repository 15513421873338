import React, { useEffect, useState } from 'react';
import AdminHeader from '../../Header/AdminHeader'
import AdminLeftMenu from '../../Navbar/AdminLeftMenu'
import AdminFooter from '../../Footer/AdminFooter'
import { Link, useParams } from 'react-router-dom';
import { userloginDetails } from '../../Utils/AdminAuth';
import api from "../../../axios/Axios";
import { ADMIN_USERS_LIST } from '../../../constants/ServiceConstants';
import { WHATSAPP_TEMPLATE_LIST_ADMIN } from '../../UI/Table/ColumnConst';
import AdvanceDataTable from '../../UI/Table/AdvanceDataTable';
import Spining from '../../UI/Spining/Spining';

const WhatsappTemplateSettingList = () => {
    let { id } = useParams();
    const adminAuthDetails = userloginDetails();

    const [isLoading, setIsLoading] = useState(true);
    const [whatsTemplateList, setwhatsTemplateList] = useState([]);
    const [ErrorResponse, setErrorResponse] = useState(null);

    useEffect(() => {
        getTemplateList();
    }, []);

    const getTemplateList = async () => {
        try {
          const { data } = await api.post(ADMIN_USERS_LIST, {
            token: adminAuthDetails.token,
            user_id: adminAuthDetails.user.userId,
            ret_user_id: id,
            method: "whatsapp_template",
          });
          //console.log(data)
          if (data.success === true) {
            setwhatsTemplateList(data.data);
            setIsLoading(false);
          } else {
            setErrorResponse(data.message);
            setIsLoading(false);
          }
        } catch (err) {
          console.log(err);
        }
      };

    return (
        <div>
            <div className="wrapper">
                <AdminLeftMenu />
                <div className="main">
                    <AdminHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                            {isLoading && <Spining />}
                                <div className="col-auto d-none d-sm-block">
                                    <h3><strong className='text-dark'>WhatsApp User List</strong></h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">
                                            <nav className="text-center" aria-label="breadcrumb">
                                                <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
                                                    <Link to={`/admin/whatsapp-account-profile-details/${id}`}><li style={{fontSize:"13px"}} className="breadcrumb-item">Profile Details</li></Link>
                                                    <Link to={`/admin/whatsapp-account-whatsapp-template-list/${id}`}><li style={{fontSize:"13px"}} className="breadcrumb-item active">Whatsapp Template List</li></Link>
                                                    <Link to={`/admin/whatsapp-account-update-whatsapp-token/${id}`}><li style={{fontSize:"13px"}} className="breadcrumb-item">Update Whastapp Token</li></Link>
                                                    <Link to={`/admin/whatsapp-account-change-route/${id}`}><li style={{fontSize:"13px"}} className="breadcrumb-item">Change Route</li></Link>
                                                    <Link to={`/admin/update-waba-id/${id}`}><li style={{fontSize:"13px"}} className="breadcrumb-item">Update Waba Id</li></Link>
                                                </ol>
                                            </nav>
                                        </h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-row ">
                                            <div className="form-group col-md-12">
                                                <AdvanceDataTable
                                                    tableName={``}
                                                    tableCell={WHATSAPP_TEMPLATE_LIST_ADMIN}
                                                    tableData={whatsTemplateList}
                                                    isLoading={isLoading}
                                                    isError={ErrorResponse}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <AdminFooter />
                </div>
            </div>
        </div>
    );
};

export default WhatsappTemplateSettingList;
