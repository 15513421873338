import React, { useEffect, useState } from "react";
import Select, { default as ReactSelect, components } from "react-select";
// import makeAnimated from "react-select/animated";
import axios from "axios";
import { HOST_URL, WhstappAccountList } from "../../api/Api";

function NewButton(props) {
  const lStorage = JSON.parse(localStorage.getItem("login"));
  const userId = lStorage.user.userId;
  const userToken = lStorage.token;
  let paramid = props.paramid;
  // console.log(props)
  // const animatedComponents = makeAnimated();
  const [defaultButtnData, setdefaultButtnData] = useState();
  const [callToAction, setCallToAction] = useState(false);
  const [specialUser, setSpecialUser] = useState("");
  const [urlAction, setUrlAction] = useState(false);
  const [urlAction2, setUrlAction2] = useState(false);
  const [quickReply, setQuickReply] = useState(false);
  const [buttonType, setButtonType] = useState({
    callToAction: false,
    quickReply: false,
  });
  const [isChecked, setisChecked] = useState(false);
  const [quickRepCount, setQuickRepCount] = useState(1);
  const [selectedOption, setSelectedOption] = useState([""]);
  const [selectedButtonOption, setSelectedButtonOption] = useState([]);
  const [selectedQuickReplyOption, setSelectedQuickReplyOption] = useState([""])
  const [button1, setButton1] = useState(false)
  const [button2, setButton2] = useState(false)
  const [button3, setButton3] = useState(false)
  const [isButtonChecked, setIsButtonChecked] = useState(false);
  const [mainUrl, setMainurl] = useState('')
  const [whatsappNumber, setWhatsappNumber] = useState([])
  // const [url, setUrl] = useState('');

  const handleChange = (event) => {
    const checked = event.target.checked;
    setIsButtonChecked(checked);

    if (checked) {
      if(urlAction?.urlType === "Dynamic"){
        setUrlAction((prevState) => ({
          ...prevState,
          url: `https://0kb.in/${whatsappNumber}/`
        }))
      }
      else if (urlAction2?.urlType === "Dynamic"){
        setUrlAction2((prevState) => ({
          ...prevState,
          url: `https://0kb.in/${whatsappNumber}/`
        }))
      }
     
    } else {
      if(urlAction?.urlType === "Dynamic"){
        setUrlAction((prev) => ({
          ...prev,
          url: "",  
        }));
      }
      else if(urlAction2?.urlType === "Dynamic"){
        setUrlAction2((prev) => ({
          ...prev,
          url: "",  
        }));
      }
    }
  };


  useEffect(() => {
    if (paramid !== undefined) {
      setisChecked(true);
      setdefaultButtnData(props.defaultButtnData);
      updateData(props.defaultButtnData)
      updateInnerData(props.defaultButtnData)
      if (props.defaultButtnData.length > 0) {
        // setButtonType("call to action");
        props.defaultButtnData.map((item) => {
          let key = Object.keys(item);
          if (key.includes("urltext")) {
            setUrlAction(item);
          }
          if (key.includes("urltext2")) {
            setUrlAction2(item);
          }
          if (key.includes("phone")) {
            setCallToAction(item);
          }
          if (key.includes("button1")) {
            setButton1(item)
          }
          if (key.includes("button2")) {
            setButton2(item)
          }
          if (key.includes("button3")) {
            setButton3(item)
          }
        });

        // if (props.defaultButtnData[0].actionType === "quickReply") {
        //   setButtonType("quick reply");

        //   const convertObjectToArray = (obj) => {
        //     return Object.keys(obj).map(key => ({ [key]: obj[key] }));
        //   };

        //   const transformedArray = convertObjectToArray(props.defaultButtnData[0]);
        //   const removeActionType = (arr) => {
        //     return arr.filter(item => !Object.keys(item).includes("actionType"));
        //   };

        //   const filteredArray = removeActionType(transformedArray);
        //   setQuickReply(props.defaultButtnData[0]);
        //   setQuickRepCount(filteredArray.length);
        // }
      }
    }
  }, [props.defaultButtnData, paramid]);

  useEffect(() => {
    props.defaultButtnData.forEach((item) => {
      const keyys = Object.keys(item);
      if (keyys.includes("phone")) {
        const newObj = { value: "callphone", label: "Call Phone number" };
        setSelectedOption((prevDefaultopt) => [...prevDefaultopt, newObj]);
      }
      if (keyys.includes("urltext")) {
        const newObj = { value: "visitwebsite1", label: "Visit website 1" };
        setSelectedOption((prevDefaultopt) => [...prevDefaultopt, newObj]);
      }
      if (keyys.includes("urltext2")) {
        const newObj = { value: "visitwebsite2", label: "Visit website 2" };
        setSelectedOption((prevDefaultopt) => [...prevDefaultopt, newObj]);
      }
      if (keyys.includes("button1")) {
        const newObj = { value: "button1", label: "Button 1" };
        setSelectedQuickReplyOption((prevDefaultopt) => [...prevDefaultopt, newObj]);
      }
      if (keyys.includes("button2")) {
        const newObj = { value: "button2", label: "Button 2" };
        setSelectedQuickReplyOption((prevDefaultopt) => [...prevDefaultopt, newObj]);
      }
      if (keyys.includes("button3")) {
        const newObj = { value: "button3", label: "Button 3" };
        setSelectedQuickReplyOption((prevDefaultopt) => [...prevDefaultopt, newObj]);
      }
    });
  }, [props.defaultButtnData]);

  useEffect(()=>{
    setIsButtonChecked(props.checkedButton);
    setMainurl(props.mainurl)
  },[props.checkedButton , props.mainurl])

  useEffect(() => {
    axios
      .get(
        `${HOST_URL}/get_user_route.php?user_id=${userId}&method=retrieve&token=${userToken}`
      )
      .then((res) => {
        if (res.data.success === true) {
          setSpecialUser(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  });

  const buttonHandler = (selectedButtonOptions) => {
    const selectedValues = selectedButtonOptions.map(option => option.value);
    setButtonType({
      callToAction: selectedValues.includes("call to action"),
      quickReply: selectedValues.includes("quick reply"),
    });
    props.sendButtonData(false);
    props.sendURLButton(false);
    setCallToAction(false);
    setUrlAction(false);
    setUrlAction2(false);
    setQuickReply(false);
    setButtonValue();
  };

  useEffect(() => {
    getWhatsaAppNumberList();
  }, [])

  const getWhatsaAppNumberList = async () => {
    WhstappAccountList().then((res) => {
      if (res.data.success === true) {
        // console.log(res.data.data)
        setWhatsappNumber(res.data.data[0].brand_number)
      } else {
        console.log("data not fetch")
      }
    });
  };



  const buttonHnadlar = (event) => {
    event.target.checked ? setisChecked(true) : setisChecked(false);
  };

  const setQuickButtonValue = () => {
    const buttonData = {
      button1,
      button2,
      button3,
      isButtonChecked,
      mainUrl
    };


    // Call the function passed from the parent
    props.sendQuickReplyButtonData(buttonData);
  };

  useEffect(() => {
    setQuickButtonValue();
  }, [button1, button2, button3, mainUrl, isButtonChecked]);

  const setButtonValue = () => {
    props.sendButtonData([callToAction]);
    props.sendURLButton([urlAction]);
    props.sendURLButton2([urlAction2]);
    props.sendQuickButton([quickReply]);
  };

  useEffect(() => {
    setButtonValue();
  }, [callToAction, urlAction, urlAction2, quickReply]);

  const changeHandler = (e) => {
    setCallToAction({
      ...callToAction,
      [e.target.name]: e.target.value,
      actionType: "calltoaction",
    });
  };

  const urlHandler = (e) => {

    setUrlAction((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
      actionType: "calltoaction",
    }));

    if(e?.traget?.value === 'Dynamic'){
      if(e.target.value === "Static"){
        setIsButtonChecked(false);
        setUrlAction((prev) => ({
          ...prev,
          url : ""
        }));
      }
    }
  };

  const urlHandler2 = (e) => {
    setUrlAction2((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
      actionType: "calltoaction",
    }));

    if(e?.traget?.value === 'Dynamic'){
      if(e.target.value === "Static"){
        setIsButtonChecked(false);
        setUrlAction2((prev) => ({
          ...prev,
          url : ""
        }));
      }
    }   
  };

  const onChangeButton1 = (e) => {
    setButton1({ ...button1, [e.target.name]: e.target.value, actionType: "quickReply" });
  }

  const onChangeButton2 = (e) => {
    setButton2({ ...button2, [e.target.name]: e.target.value, actionType: "quickReply" });
  }

  const onChangeButton3 = (e) => {
    setButton3({ ...button3, [e.target.name]: e.target.value, actionType: "quickReply" });
  }

  const addQuickRepInput = (e) => {
    if (quickRepCount < 3) {
      setQuickRepCount(quickRepCount + 1);
    }
  };

  const handleSelect = (selectedValue) => {
    setSelectedOption(selectedValue);
    if (selectedValue.length === 0) {
      setCallToAction(false);
      setUrlAction(false);
      setUrlAction2(false);
      return;
    }

    let stringArray = JSON.stringify(selectedValue);

    let phoneTextIndex = stringArray.indexOf("callphone");
    let urlTextIndex = stringArray.indexOf("visitwebsite1");
    let urlTextIndex2 = stringArray.indexOf("visitwebsite2");

    if (phoneTextIndex === -1) {
      setCallToAction(false);
    }

    if (urlTextIndex === -1) {
      setUrlAction(false);
    }
    if (urlTextIndex2 === -1) {
      setUrlAction2(false);
    }
  };

  const handleQuickReplySelect = (selectedValue) => {
    setSelectedQuickReplyOption(selectedValue);
    // if (selectedValue.length === 0) {
    //   setCallToAction(false);
    //   setUrlAction(false);
    //   setUrlAction2(false);
    //   return;
  }

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      cursor: "pointer",
    }),
  };

  const buttonSelectHandler = (selectedButtonOptions) => {
    setSelectedButtonOption(selectedButtonOptions || []);
  };

  const options = [
    { value: "call to action", label: "Call To Action" },
    { value: "quick reply", label: "Quick Reply" },
  ];

  const quickReplyOption = [
    { value: "button1", label: "Button 1" },
    { value: "button2", label: "Button 2" },
    { value: "button3", label: "Button 3" },
  ]

  // useEffect(()=>{
  //   updateData();
  // },[])

  const updateData = (data) => {
    if (paramid) {
      if (data.length > 0) {
        data.forEach((item) => {
          let keys = Object.keys(item);

          let updatedButtonType = {};

          if (keys.includes("text") || keys.includes("urltext") || keys.includes("urltext2")) {
            buttonType.callToAction = true;
          }
          if (keys.includes("button1") || keys.includes("button2") || keys.includes("button3")) {
            buttonType.quickReply = true;
          }

          // setButtonType((prevButtonType) => ({
          //   ...prevButtonType,
          //   ...updatedButtonType,
          // }));
        });
      }
    }
  };


  const updateInnerData = (data) => {
    if (paramid !== undefined) {
      setisChecked(true);
      // setdefaultButtnData(props.defaultButtnData);

      // Initialize state with the default button data based on the `paramid`
      if (props.defaultButtnData.length > 0) {
        const buttonData = props.defaultButtnData;
        buttonData.map((x) => {
          setButton1(x.button1);
          setButton2(x.button2);
          setButton3(x.button3);
          setCallToAction(x.phone);
          setUrlAction(x.urltext);
          setUrlAction2(x.urltext2);
         
          // if(buttonType.callToAction === true){
          //   setButtonType({
          //     callToAction: selectedValues.includes("call to action"),
          //     // quickReply: selectedValues.includes("quick reply"),
          //   });
          // }

          // if(buttonType.quickReply=== true){
          //   setButtonType({
          //     // callToAction: selectedValues.includes("call to action"),
          //     quickReply: selectedValues.includes("quick reply"),
          //   });
          // }
        })
        // if (buttonData) {

        //   // Set other states as needed...
        // }
      }
    }
  }
  
  return (
    <>
      <div className="whatsapp-options">
        <label>
          <h4>
            Button<span className="text-muted">(optional)</span>
          </h4>
          <p>
            Create buttons that let customers respond to your message or take
            action.
          </p>
        </label>
        <label className="switch">
          <input
            type="checkbox"
            defaultChecked={isChecked === true ? "checked" : null}
            onChange={buttonHnadlar}
          />
          <span className="slider round"></span>
        </label>
      </div>
      {isChecked && (
        <>
          <div className="input-group drop-down-icons">
            <Select
              options={options}
              closeMenuOnSelect={false}
              // value={paramid ? buttonType}
              hideSelectedOptions={false}
              isMulti
              components={{ Option }}
              styles={buttonType}
              classNamePrefix="select"
              placeholder="Select button type"
              onChange={buttonHandler}
            />
          </div>

          {buttonType.callToAction ? (
            <div className="call-action-area">
              <Select
                options={
                  specialUser === 26
                    ? [
                      { value: "callphone", label: "Call Phone number" },
                      { value: "visitwebsite1", label: "Visit website 1" },
                    ]
                    : [
                      { value: "callphone", label: "Call Phone number" },
                      { value: "visitwebsite1", label: "Visit website 1" },
                      { value: "visitwebsite2", label: "Visit website 2" },
                    ]
                }
                closeMenuOnSelect={false}
                value={selectedOption}
                hideSelectedOptions={false}
                // components={animatedComponents}
                isMulti
                components={{
                  Option,
                }}
                styles={customStyles}
                classNamePrefix="select"
                placeholder="Select type of action"
                onChange={handleSelect}
              />
              {selectedOption.map((option) => {
                if (option.value === "callphone") {
                  return (
                    <>
                      <div className="choose-options">
                        <span className="">
                          <span className="p-2"> Call phone number </span>
                        </span>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <input
                            type="text"
                            className="form-control"
                            id="inputFirstName"
                            name="text"
                            value={callToAction.text}
                            onChange={changeHandler}
                            placeholder="Button Text"
                            maxLength={20}
                          />
                        </div>
                        <div className="col-md-4">
                          <input
                            type="text"
                            className="form-control"
                            name="countryCode"
                            value={callToAction.countryCode}
                            id="inputFirstName"
                            onChange={changeHandler}
                            placeholder="country code"
                          />
                        </div>
                        <div className="col-md-4">
                          <input
                            type="text"
                            className="form-control"
                            name="phone"
                            value={callToAction.phone}
                            id="inputFirstName"
                            autoComplete="off"
                            onChange={changeHandler}
                            placeholder="Mobile Number"
                            maxLength={15}
                          />
                        </div>
                      </div>
                    </>
                  );
                }
              })}

              {selectedOption?.map((option) => {
                if (option?.value === "visitwebsite1") {
                  return (
                    <>
                      <div className="choose-options">
                        <span className="">
                          <span className="p-2"> Visit website 1 </span>
                        </span>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <input
                            type="text"
                            className="form-control"
                            name="urltext"
                            value={urlAction.urltext}
                            onChange={urlHandler}
                            id="inputFirstName"
                            placeholder="Button Text"
                            maxLength={20}
                          />
                        </div>
                        <div className="col-md-4 drop-down-icons">
                          <select
                            value={urlAction?.urlType}
                            onChange={urlHandler}
                            name="urlType"
                            className="form-control"
                          >
                            <option>URL Type</option>
                            <option>Static</option>
                            {urlAction2?.urlType === "Dynamic" ? null : <option>Dynamic</option>}
                          </select>
                        </div>
                        <div className="col-md-4">
                          <input
                            type="text"
                            onChange={urlHandler}
                            name="url"
                            value={isButtonChecked && urlAction?.urlType === "Dynamic" ? `https://0kb.in/${whatsappNumber}/` : urlAction.url}
                            disabled={isButtonChecked && urlAction?.urlType === "Dynamic" ? true : false}
                            className="form-control"
                            id="inputFirstName"
                            placeholder="www.example.com"
                          />
                        </div>
                      </div>
                      {urlAction?.urlType === "Dynamic" && <div className="input-group mt-1 mr-sm-1">
                        <div className="input-group-text">{`{{1}}`}</div>
                        <input
                          type="text"
                          value={urlHandler2.sampleurl}
                          name="sampleurl"
                          required
                          onChange={urlHandler}
                          placeholder={`Sample Data of {{1}}`}
                          className="form-control"
                        />
                      </div>}
                    </>
                  );
                }

                if (option?.value === "visitwebsite2") {
                  return (
                    <>
                      <div className="choose-options">
                        <span className="">
                          <span className="p-2"> Visit website 2 </span>
                        </span>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <input
                            type="text"
                            className="form-control"
                            name="urltext2"
                            value={urlAction2.urltext2}
                            onChange={urlHandler2}
                            id="inputFirstName"
                            placeholder="Button Text"
                            maxLength={20}
                          />
                        </div>
                        <div className="col-md-4 drop-down-icons">
                          <select
                            value={urlAction2?.urlType}
                            onChange={urlHandler2}
                            name="urlType"
                            className="form-control"
                          >
                            <option>URL Type</option>
                            <option>Static</option>
                            {urlAction?.urlType === "Dynamic" ? null : <option>Dynamic</option>}
                          </select>
                        </div>
                        <div className="col-md-4">
                          <input
                            type="text"
                            onChange={urlHandler2}
                            name="url"
                            value={isButtonChecked && urlAction2?.urlType === "Dynamic" ? `https://0kb.in/${whatsappNumber}/` : urlAction2.url}
                            disabled={isButtonChecked && urlAction2?.urlType === "Dynamic" ? true : false}
                            className="form-control"
                            id="inputFirstName"
                            placeholder= "www.example.com"
                          />
                        </div>
                      </div>
                      {urlAction2?.urlType === "Dynamic" && <div className="input-group mt-1 mr-sm-1">
                        <div className="input-group-text">{`{{1}}`}</div>
                        <input
                          type="text"
                          name="sampleurl"
                          value={urlHandler2.sampleurl}
                          required
                          onChange={urlHandler2}
                          placeholder={`Sample Data of {{1}}`}
                          className="form-control"
                        />
                      </div>}
                    </>
                  );
                }
              })}
            </div>
          ) : null}

          {urlAction2?.urlType === "Dynamic" || urlAction?.urlType === "Dynamic" ? (
            <div className="row mt-2">
              <div className="col-4">
                <div className="input-group ml-4 mt-1 mr-sm-1">
                  <input
                    type="checkbox"
                    checked={isButtonChecked}
                    onChange={handleChange}
                    className="form-check-input"
                  />
                  <label className="form-check-label ml-2">Cta Tracking</label>
                </div>
              </div>

              {isButtonChecked &&
                <div className="col-8">
                  <input
                    type="text"
                    name="enter_main_url"
                    value={mainUrl}
                    required
                    onChange={(e) => setMainurl(e.target.value)}
                    placeholder='Enter Main Url'
                    className="form-control"
                  />
                </div>
              }

            </div>) : null}
{/* 
            <input
        type="text"
        value={url}
        onChange={(e) => setUrl(e.target.value)} // Allow user to edit URL if needed
        placeholder="Enter URL"
        className="form-control"
      /> */}



          {buttonType.quickReply && (
            <div>
              <div className="call-action-area">
                {/* {Array.from({ length: quickRepCount }, (_, index) => (
                  <>
                    <div
                      className="input-group mb-1 "
                      id={`button${index + 1}`}
                      key={index}
                    >
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">
                          {" "}
                          Button {index + 1}
                        </span>
                      </div>
                      <input
                        type="text"
                        onChange={quickLinkhandler}
                        name={`button${index + 1}`}
                        value={quickReply[`button${index + 1}`]}
                        maxLength={20}
                        className="form-control"
                        placeholder="Username"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                  </>
                ))}
                <button
                  className="btn btn-sm btn-primary mb-2 mt-4 float-right"
                  onClick={addQuickRepInput}
                >
                  Add Button
                </button> */}
                <Select
                  options={quickReplyOption}
                  closeMenuOnSelect={false}
                  value={selectedQuickReplyOption}
                  hideSelectedOptions={false}
                  isMulti
                  components={{ Option }}
                  styles={buttonType}
                  classNamePrefix="select"
                  placeholder="Select button"
                  onChange={handleQuickReplySelect}
                />
                {selectedQuickReplyOption.map((option) => {
                  if (option.value === 'button1') {
                    return (
                      <div
                        className="input-group mb-1 "
                      // id={`button${index + 1}`}
                      // key={index}
                      >
                        <input
                          type="text"
                          onChange={onChangeButton1}
                          name='button1'
                          value={button1.button1}
                          maxLength={20}
                          className="form-control"
                          placeholder="button 1"
                          aria-label="button 1"
                          aria-describedby="basic-addon1"
                        />

                      </div>
                    )
                  }
                  if (option.value === 'button2') {
                    return (
                      <div
                        className="input-group mb-1 "
                      // id={`button${index + 1}`}
                      // key={index}
                      >
                        <input
                          type="text"
                          onChange={onChangeButton2}
                          name='button2'
                          value={button2.button2}
                          maxLength={20}
                          className="form-control"
                          placeholder="button 2"
                          aria-label="button 2"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                    )
                  }
                  if (option.value === 'button3') {
                    return (
                      <div
                        className="input-group mb-1 "
                      // id={`button${index + 1}`}
                      // key={index}
                      >
                        <input
                          type="text"
                          onChange={onChangeButton3}
                          name='button3'
                          value={button3.button3}
                          maxLength={20}
                          className="form-control"
                          placeholder="button 3"
                          aria-label="button 3"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                    )
                  }
                })}
              </div>
            </div>
          )}
          {/* <div className="text-right mt-2">
            <button className="btn btn-sm btn-success" onClick={setButtonValue}>Save</button>
          </div> */}
        </>
      )}
    </>
  );
}

export default NewButton;
