import React, { useState } from "react";
import DashboardLayout from "../../../UI/Layout/DashboardLayout";
import { FaUserCircle } from "react-icons/fa";

const ShortCut = () => {
  const [selectedBox, setSelectedBox] = useState(null);
  const handleSelect = (boxName) => {
    setSelectedBox(boxName);
  };
  const closeBox = () => {
    setSelectedBox(null);
  };

  return (
    <>
      <DashboardLayout>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="d-flex wFull">
              <div className="ChatUser_card col-md-3">
                <div className="row">
                  <div className="ChatUserLeftScoll">
                    <ul className="ChatUserList">
                      <li>
                        <div className="d-flex flex-column">
                          <div className="d-flex ChatUserGroup">
                            <div className="ChatUserName flex-grow-1 justify-content-start text-md font-weight-bold">
                              Ashsish Sharma
                            </div>
                            <div className="ChatUserDate justify-content-end text-xs">
                              11/9/2024
                            </div>
                          </div>
                          <div className="ChatUserMsg">
                            <p className="text-sm">
                              asfdjsal dfkshkfhsa ldlsfhlsf...
                            </p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex flex-column">
                          <div className="d-flex ChatUserGroup">
                            <div className="ChatUserName flex-grow-1 justify-content-start text-md font-weight-bold">
                              Ashsish Sharma
                            </div>
                            <div className="ChatUserDate justify-content-end text-xs">
                              11/9/2024
                            </div>
                          </div>
                          <div className="ChatUserMsg">
                            <p className="text-sm">
                              asfdjsal dfkshkfhsa ldlsfhlsf...
                            </p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex flex-column">
                          <div className="d-flex ChatUserGroup">
                            <div className="ChatUserName flex-grow-1 justify-content-start text-md font-weight-bold">
                              Ashsish Sharma
                            </div>
                            <div className="ChatUserDate justify-content-end text-xs">
                              11/9/2024
                            </div>
                          </div>
                          <div className="ChatUserMsg">
                            <p className="text-sm">
                              asfdjsal dfkshkfhsa ldlsfhlsf...
                            </p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex flex-column">
                          <div className="d-flex ChatUserGroup">
                            <div className="ChatUserName flex-grow-1 justify-content-start text-md font-weight-bold">
                              Ashsish Sharma
                            </div>
                            <div className="ChatUserDate justify-content-end text-xs">
                              11/9/2024
                            </div>
                          </div>
                          <div className="ChatUserMsg">
                            <p className="text-sm">
                              asfdjsal dfkshkfhsa ldlsfhlsf...
                            </p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex flex-column">
                          <div className="d-flex ChatUserGroup">
                            <div className="ChatUserName flex-grow-1 justify-content-start text-md font-weight-bold">
                              Ashsish Sharma
                            </div>
                            <div className="ChatUserDate justify-content-end text-xs">
                              11/9/2024
                            </div>
                          </div>
                          <div className="ChatUserMsg">
                            <p className="text-sm">
                              asfdjsal dfkshkfhsa ldlsfhlsf...
                            </p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex flex-column">
                          <div className="d-flex ChatUserGroup">
                            <div className="ChatUserName flex-grow-1 justify-content-start text-md font-weight-bold">
                              Ashsish Sharma
                            </div>
                            <div className="ChatUserDate justify-content-end text-xs">
                              11/9/2024
                            </div>
                          </div>
                          <div className="ChatUserMsg">
                            <p className="text-sm">
                              asfdjsal dfkshkfhsa ldlsfhlsf...
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-9 ChatLive_card">
                <div className="userchatoverlay"></div>
                <div className="d-flex flex-column wFull">
                  <div className="chatLiveHeader px-3 py-2">
                    <h3>
                      {" "}
                      <FaUserCircle size={26} /> Ashish Sharma
                    </h3>
                  </div>
                  <div className="row">
                    <div className="col-md-6"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </DashboardLayout>
    </>
  );
};

export default ShortCut;
