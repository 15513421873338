import React from "react";
import { checkUserUrl } from "../Utils/Utils";
import { url } from "../Utils/userAuth";
const WhatsAppApiComponent = () => {
  // console.log(url)
  return (
    <div className="api-docs">
      <p className=" mt-2 mb-0">
        Sending WhatsApp using {url} APIs is a breeze and lightning fast. Simply
        pass the message body, mobile number & {url} in the API request to send
        WhatsApp Message instantly.
      </p>
      <h3 className="mt-4">WhatsApp Templates: </h3>
      <p className=" mt-2 mb-0">
        You can also{" "}
        <a href="/dashboard/whatsapp-template-list">create templates</a> in the{" "}
        {url} portal and use the <strong>template ID (wid) </strong>in the API
        request. Templates are necessary in case of dynamic content present in
        the message. For example, in order to send an OTP message, you can
        create a template as below:
      </p>
      <p>
        {" "}
        Dear <strong> {`{#name#}`} </strong>, your otp is{" "}
        <strong> {`{#otp#}`} </strong> {url} automatically adds the dynamic
        values of the Template as API Parameters which can be used to change the
        content delivered to each recipient.
      </p>
      <p className=" mt-2 mb-0">
        Example usage for above template, assuming{" "}
        <strong> whatsaApp Template id (wid) </strong> generated as 101 is as
        follows.
      </p>
      <code>
        {`${
          checkUserUrl(url)
            ? "https://api.authkey.io/request"
            : `${url}/restapi/request.php`
        }`}
        ?authkey=<span className="paivariable">{"XXXXXX"}</span>&mobile=
        <span className="paivariable">RecepientMobile</span>&country_code=
        <span className="paivariable">CountryCode</span>&wid=
        <span className="paivariable">101</span>&name=
        <span className="paivariable">Twinkle</span>&otp=
        <span className="paivariable">1234</span>
      </code>
      <p>
        {" "}
        Here, <strong> 'name' </strong> and <strong>'otp'</strong> parameters
        are auto-added to the API and their values shall be replaced in the
        template body {`{...}`}
      </p>
      <h3 className="mt-5">Authkey Balance: </h3>
      <p>
        To fetch your authkey balance just pass your{" "}
        <strong> {`{#authkey#}`} </strong> as parameter, You can find{" "}
        <strong> {`{#authkey#}`} </strong> into your My Acount {`>`} Profile.
      </p>
      <code>
        {`${
          checkUserUrl(url)
            ? " https://console.authkey.io/restapi/getbalance.php?authkey="
            : `${url}/restapi/getbalance.php?authkey=`
        }`}
        <span className="paivariable">authkey</span>
      </code>
      <p>
        <b>GET API To Send Media Document/Image/Video Template with variable</b>
      </p>
      <h3 className="mt-5">API using GET Request [Multimedia]:</h3>
      <code>
        {`${
          checkUserUrl(url)
            ? " https://console.authkey.io/restapi/getbalance.php?authkey="
            : `${url}/restapi/getbalance.php?authkey=`
        }`}
        <span className="paivariable">XXXXXX</span>&mobile=
        <span className="paivariable">XXXXXXXXXX</span>&country_code=
        <span className="paivariable">91</span>&wid=
        <span className="paivariable">templateid</span>&template_type=
        <span className="paivariable">media</span>&headerFileName=
        <span className="paivariable">BillDetails</span>&HeaderData=
        <span className="paivariable">https://abc.pdf</span>
      </code>
      <h3 className="mt-5">API using POST Request:</h3>
      <code>
      {`${
          checkUserUrl(url)
            ? " https://console.authkey.io/restapi/requestjson.php"
            : `${url}/restapi/requestjson.php`
        }`}
        
        </code>
      <h4 className="my-4 text-muted">
        <b>Headers:</b>
      </h4>
      <div class="mt-2 mb-0 ml-5">
        <p>
          <b>Authorization:</b> Basic{" "}
          <pre class="preinline">&lt;Your Authkey&gt;</pre>
        </p>
      </div>
      <div class="mt-0 mb-0 ml-5">
        <p>
          <b>Content-Type:</b> application/json
        </p>
      </div>
      <pre>
        <code>
          {`
          {
              "country_code": "XX",
              "mobile": "XXXXXXXXXX",
              "wid": "xxxx",
              "type": "text",
              "bodyValues": {"var1":"xxxx","var2":"xxxx"},
              "headerValues": {
                "headerFileName": "xyz",
                "headerData":  "https://abc.pdf"
              }
            }
          `}
        </code>
      </pre>
      <h4 className="my-4 text-muted">
        <b>Parameters:</b>
      </h4>
      <p>
        <pre class="preinline">&lt;Your authkey&gt;</pre> = Your authkey portal
        authkey
      </p>
      <p>country_code = xx </p>
      <p>mobile = Mobile Number</p>
      <p>wid = WhatsApp Template ID</p>
      <p>type = text/media</p>
      <p>bodyValues = pass the variables as "var1":"value"</p>
      <p>headerFileName = Header</p>
      <p>Name headerData = file full url</p>
    </div>
  );
};

export default WhatsAppApiComponent;
