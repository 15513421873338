import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AdminHeader from '../Header/AdminHeader';
import AdminFooter from '../Footer/AdminFooter';
import AdminLeftMenu from '../Navbar/AdminLeftMenu';
import EmptyImg from "../Dashboard/assets/img/create-project.png";
import { userloginDetails } from '../Utils/AdminAuth';
import { HOST_URL } from '../Dashboard/api/Api';

class UserTransationDetails extends Component {

    constructor() {

        const lStorage = userloginDetails();
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;
        const empType = lStorage.user.isAdmin;


        super();

        this.state = {
            user_id: userId,
            token: userToken,
            emp_type:empType,
            successMessage: false,
            selectUserTransactionDetails: '',
            seletedUserId: '',
            errorMessage: '',
            bgColor: "alert alert-success alert-dismissible",
        }
    }

    componentDidMount() {
        this.getUserDetails();
        this.setState({ seletedUserId: this.props.match.params.id });
        fetch(`${HOST_URL}/admin_user_list.php?user_id=${this.state.user_id}&method=retrieve&type=transaction&ret_user_id=${this.props.match.params.id}&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                // console.log('user',result);
                if (result.success === true) {
                    this.setState({ selectUserTransactionDetails: result.data });
                } else {
                    this.setState({
                        successMessage: true,
                        successBg: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })
    }

    getUserDetails() {
        this.setState({ seletedUserId: this.props.match.params.id });
        fetch(`${HOST_URL}/admin_user_list.php?user_id=${this.state.user_id}&method=retrieve&type=userdetails&ret_user_id=${this.props.match.params.id}&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                // console.log('user',result);
                if (result.success === true) {
                    this.setState({
                        email: result.data[0].email,
                    });
                } else {
                    this.setState({
                        successMessage: true,
                        successBg: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })
    }

    render() {
        // console.log(this.state);
        return (
            <div className="wrapper">
                <AdminLeftMenu />
                <div className="main">
                    <AdminHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3>User Details <strong className='text-info'> {this.state.email} </strong>  </h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="card">

                                    <div className="card-header">
                                        <h5 className="card-title mb-0">
                                            <div className="col-auto ml-auto text-right mt-n1">
                                                <nav className="text-center" aria-label="breadcrumb">
                                                    <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
                                                        <Link to={'/admin/user-details/' + this.state.seletedUserId}><li className="breadcrumb-item">User Details </li> </Link>
                                                        <Link to={'/admin/user-transaction-details/' + this.state.seletedUserId}>  <li className="breadcrumb-item active"> Transaction Details </li></Link>

                                                        <Link to={'/admin/user-price-details/' + this.state.seletedUserId}>
                                                            <li className="breadcrumb-item"> Price Details </li></Link>
                                                       {this.state.emp_type==="reseller"?null:<>
                                                        <Link to={'/admin/user-add-price/' + this.state.seletedUserId}>  <li className="breadcrumb-item"> Add Credit </li></Link>

                                                        <Link to={'/admin/user-entity-pass/' + this.state.seletedUserId}>  <li className="breadcrumb-item"> Entity By Pass   </li></Link>

                                                        <Link to={'/admin/change-currency/' + this.state.seletedUserId}>  <li className="breadcrumb-item "> Change Currency  </li></Link>
                                                        <Link
                                                                to={
                                                                    "/admin/bulk-sms-route/" +
                                                                    this.state.seletedUserId
                                                                }
                                                            >
                                                                {" "}
                                                                <li className="breadcrumb-item ">
                                                                    {" "}
                                                                    Bulk Sms Route Setting{" "}
                                                                </li>
                                                            </Link>
                                                            <Link to={`/admin/agent-setting/${this.state.seletedUserId}`}><li className="breadcrumb-item">Agent Setting</li></Link>
                                                        </>}
                                                    </ol>
                                                </nav>
                                            </div>
                                        </h5>
                                    </div>

                                    <div className="card-body">

                                        <div className="row">
                                            <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                                <div className="card table-responsive flex-fill">

                                                    {this.state.selectUserTransactionDetails ?

                                                        <table className="table  table-hover my-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Remark Name</th>
                                                                    <th className="">Amount</th>
                                                                    <th className="">Transaction</th>
                                                                    <th className="">Status</th>
                                                                    <th className="">Created Date / Time</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {
                                                                    this.state.selectUserTransactionDetails.map((item, i) =>
                                                                        <tr key={i}>

                                                                            <td>{item.description}</td>
                                                                            <td>{item.amount}</td>
                                                                            <td>{item.transaction_id}</td>
                                                                            <td>
                                                                                {
                                                                                    item.status === 1 ?
                                                                                        <span className="badge bg-success">  Success </span>
                                                                                        :

                                                                                        item.status === 0 ?
                                                                                            <>
                                                                                                <span className="badge bg-info"> Pending  </span>
                                                                                            </>
                                                                                            :
                                                                                            <span className="badge bg-danger"> Failed    </span>
                                                                                }


                                                                            </td>

                                                                            <td>{item.created}</td>
                                                                        </tr>

                                                                    )
                                                                }



                                                            </tbody>
                                                        </table>
                                                        :
                                                        <div className="text-center">
                                                            <div className="card-body">
                                                                <img alt="Empty Data" className="project-empty-img" src={EmptyImg} />
                                                                <p>No Any Transaction Details Found</p>

                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <AdminFooter />
                </div>
            </div>
        );
    }
}

export default UserTransationDetails;