import React from "react";
import { Link } from "react-router-dom";
import { PiUsersThreeDuotone} from "react-icons/pi";
import { LiaUserTieSolid } from "react-icons/lia";
import { PiUserGearDuotone } from "react-icons/pi";
import { useParams } from 'react-router-dom';
const ChatLeftMenu = () => {
  const { id } = useParams();

  return (
    <>
      <div className="py-3">
        <div className="nav flex-column liveChatList">
          <h3>Agent Management</h3>
          <ul className="sidebar-nav">
            <li>
              <Link
                to="/dashboard/user-management/agent"
                className={id === "agent" ? "active-agent-menu" : ""}
               
              >
                <span className="iconWH"></span>
                <LiaUserTieSolid size={20} /> Agents
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/user-management/manager"
                className={id === "manager" ? "active-agent-menu" : ""}
            
              >
                <span className="iconWH"></span>
                <PiUserGearDuotone size={18} /> Manager
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/user-management/team"
                className={id === "team" ? "active-agent-menu" : ""}
              
              >
                <span className="iconWH"></span>
                <PiUsersThreeDuotone size={20} /> Team
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ChatLeftMenu;
