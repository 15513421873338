import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../UI/Layout/DashboardLayout";
import UserLeftMenu from "./UserLeftMenu";
import { FiArrowLeft } from "react-icons/fi";
import { PiUserDuotone } from "react-icons/pi";
import { AiTwotoneMail } from "react-icons/ai";
import { AiTwotoneMobile } from "react-icons/ai";
import { Link, useHistory} from "react-router-dom";
import Axios from "axios";
import { HOST_URL_DB2, NODE_HOST_URL } from "../../api/Api";
import Alert from "../../../UI/Alert/Alert";
import Spining from "../../../UI/Spining/Spining";
import { useParams } from "react-router-dom";

const AgentEdit = () => {
  const { id } = useParams();
  const history = useHistory();
 
  const lStorage = JSON.parse(localStorage.getItem("login"));
  const user_id = lStorage.user.userId;
  const token = lStorage.token;
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState("agent");
  const [agentName, setAgentName] = useState("");
  const [agentLimit, setAgentLimit] = useState(null);
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [checkboxes, setCheckboxes] = useState({
    add: 0,
    update: 0,
    delete: 0,
  });
  const [error, setError] = useState({
    successMessage: false,
    bgColor: "alert-danger",
    errorMessage: "",
  });
  const goBack = () => {
    history.goBack();
  };
  useEffect(() => {
    if (!id) {
      return;
    }
    agentDetails(id);
  }, [id]);

  useEffect(() => {
    fetchAgentLimit();
  }, []);
  const fetchAgentLimit = async () => {
    const dataforAgentLimit = {
      user_id,
      token,
      method: "allow_agents",
    };
    try {
      const { data } = await Axios.post(
        `${NODE_HOST_URL}/whatsapp_user`,
        dataforAgentLimit
      );
      if (data.success === true) {
        setAgentLimit(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const agentDetails = async (agent_id) => {
    setLoading(true);
    const dataForDetails = {
      user_id,
      method: "details",
      token,
      agent_id,
    };
    try {
      const { data } = await Axios.post(
        `${NODE_HOST_URL}/whatsapp_agent`,
        dataForDetails
      );
      if (data.success === true) {
        setAgentName(data.data[0].name);
        setEmail(data.data[0].email);
        setMobile(data.data[0].mobile);
        setCheckboxes(JSON.parse(data.data[0].permission));
        setUserType(data.data[0].agent_type);
      }
    } catch (error) {
      setError({
        successMessage: true,
        bgColor: "alert-danger",
        errorMessage: error.message,
      });
      console.log(error.message);
    }
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(agentLimit?.allow_agents-agentLimit?.agent_count===0){
      setError({
        successMessage: true,
        bgColor: "alert-danger",
        errorMessage: "Limit exceed",
      });
      return
    }
    setLoading(true);
    const dataforAgent = {
      user_id,
      method: "create",
      token,
      name: agentName,
      email,
      mobile,
      agent_type: userType,
      permission: JSON.stringify(checkboxes),
    };

    try {
      const {data} = await Axios.post(`${HOST_URL_DB2}/agent.php`, dataforAgent);

      if (data.success === true) {
        setError({
          successMessage: true,
          bgColor: "alert-success",
          errorMessage: data.message,
        });
        window.location.href = '/dashboard/user-management/agent'
      } else {
        console.error("Failed to save data:", data.message);

        setError({
          successMessage: true,
          bgColor: "alert-danger",
          errorMessage: data.message,
        });
      }
    } catch (error) {
      console.error("Error occurred while saving data:", error);
      setError({
        successMessage: true,
        bgColor: "alert-danger",
        errorMessage: error.message,
      });
    }
    setLoading(false);
  };
  const handleUserType = (e) => {
    setUserType(e.target.value);
  };
  const handlePermission = (event) => {
    const { name, checked } = event.target;
    setCheckboxes((prevState) => ({
      ...prevState,
      [name]: checked ? 1 : 0,
    }));
  };
  const updateAgent = async (event) => {
    setLoading(true);
    event.preventDefault();
    const data = {
      user_id,
      method: "update",
      token,
      id: id,
      permission: JSON.stringify(checkboxes),
      name: agentName,
      agent_type: userType,
      mobile,
      email,
    };
    try {
      const response = await fetch(`${HOST_URL_DB2}/agent.php`, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();

      if (result.success) {
        setError({
          successMessage: true,
          bgColor: "alert-success",
          errorMessage: result.message,
        });
      } else {
        console.log("Failed to update data:", response.statusText);
      }
    } catch (error) {
      console.error("Error occurred while updating data:", error);
      setError({
        successMessage: true,
        bgColor: "alert-danger",
        errorMessage: error.message,
      });
    }
    setLoading(false);
  };

  
  return (
    <>
      {loading && <Spining />}
      <DashboardLayout>
        <main className="content">
          {error.successMessage && (
            <Alert type={error.bgColor}>{error.errorMessage}</Alert>
          )}
          <div className="container-fluid p-0">
            <div className="d-flex wFull">
              <div className="ChatLive_card col-md-2">
                <UserLeftMenu />
              </div>
              <div className="col-md-10">
                <div className="ChatLive_card d-flex flex-column wFull px-3 py-3">
                  <div className="TitleBox d-flex justify-content-between">
                    <div>
                      <h3> {id ? "Update Agent" : "Add Agent"}</h3>
                    </div>
                    <div> <h3 className={agentLimit?.allow_agents-agentLimit?.agent_count===0?"text-danger":"text-success"}>Available Limit : {agentLimit?.allow_agents-agentLimit?.agent_count}/{agentLimit?.allow_agents} </h3></div>
                    <div>
                      <Link onClick={goBack} to="#">
                        <FiArrowLeft /> Back
                      </Link>
                    </div>
                  </div>
                  <div className="row">
                    <form onSubmit={id ? updateAgent : handleSubmit}>
                      <div className="col-md-12">
                        <div className="col-md-12 mb-3">
                          <label className="col-form-label">
                            Select Type <span className="text-danger">*</span>
                          </label>
                          <div className="w-full">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input cursor-pointer"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio1"
                                value="agent"
                                checked={userType === "agent" ? true : false}
                                required
                                onChange={handleUserType}
                              />
                              <label
                                className="form-check-label cursor-pointer"
                                htmlFor="inlineRadio1"
                              >
                                Agent
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input cursor-pointer"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio2"
                                value="manager"
                                checked={userType === "manager" ? true : false}
                                required
                                onChange={handleUserType}
                              />
                              <label
                                className="form-check-label cursor-pointer"
                                htmlFor="inlineRadio2"
                              >
                                Manager
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input cursor-pointer"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio3"
                                checked={userType === "team" ? true : false}
                                value="team"
                                required
                                onChange={handleUserType}
                              />
                              <label
                                className="form-check-label cursor-pointer"
                                htmlFor="inlineRadio3"
                              >
                                Team
                              </label>
                            </div>
                          </div>
                        </div>
                        {(userType === "manager" || userType === "team") && (
                          <div className="col-md-12 mb-3">
                            <label className="col-form-label">Permission</label>
                            <div className="w-full">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input cursor-pointer"
                                  type="checkbox"
                                  id="checkboxadd"
                                  name="add"
                                  checked={checkboxes.add === 1}
                                  onChange={handlePermission}
                                />
                                <label
                                  className="form-check-label cursor-pointer"
                                  htmlFor="checkboxadd"
                                >
                                  Add
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input cursor-pointer"
                                  type="checkbox"
                                  style={{ cursor: "pointer" }}
                                  id="checkboxupdate"
                                  name="update"
                                  checked={checkboxes.update === 1}
                                  onChange={handlePermission}
                                />
                                <label
                                  className="form-check-label cursor-pointer"
                                  htmlFor="checkboxupdate"
                                  style={{ cursor: "pointer" }}
                                >
                                  Update
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input cursor-pointer"
                                  type="checkbox"
                                  id="checkboxdel"
                                  style={{ cursor: "pointer" }}
                                  name="delete"
                                  checked={checkboxes.delete === 1}
                                  onChange={handlePermission}
                                />
                                <label
                                  className="form-check-label cursor-pointer"
                                  htmlFor="checkboxdel"
                                  style={{ cursor: "pointer" }}
                                >
                                  Delete
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-6 mb-2">
                              <label className="col-form-label">
                                <PiUserDuotone />{" "}
                                {userType === "team"
                                  ? "Team Name"
                                  : "Full Name"}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="input-group mb-2">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Full Name"
                                  required
                                  value={agentName}
                                  onChange={(e) => setAgentName(e.target.value)}
                                />
                              </div>
                            </div>

                            <div className="col-md-6 mb-2">
                              <label className="col-form-label">
                                <AiTwotoneMail /> Email
                                <span className="text-danger">*</span>
                              </label>
                              <div className="input-group mb-2">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Email"
                                  required
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  readOnly={id ? true : false}
                                />
                              </div>
                            </div>
                            <div className="col-md-6 mb-2">
                              <label className="col-form-label">
                                <AiTwotoneMobile /> Mobile
                                <span className="text-danger">*</span>
                              </label>
                              <div className="input-group mb-2">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Mobile No"
                                  required
                                  value={mobile}
                                  onChange={(e) => setMobile(e.target.value)}
                                  readOnly={id ? true : false}
                                />
                              </div>
                            </div>

                            <div className="col-md-12 mb-4">
                              <button
                                type="submit"
                                className="btn btn-success btn-sm"
                              >
                                {id ? "Update" : "Submit"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </DashboardLayout>
    </>
  );
};

export default AgentEdit;
