import React, { useEffect, useState } from 'react';
import AdminHeader from '../../Header/AdminHeader'
import AdminLeftMenu from '../../Navbar/AdminLeftMenu'
import AdminFooter from '../../Footer/AdminFooter'
import { Link, useParams } from 'react-router-dom';
import { userloginDetails } from '../../Utils/AdminAuth';
import { HOST_URL } from '../../Dashboard/api/Api';
import api from "../../../axios/Axios";
import { ADMIN_USERS_LIST } from '../../../constants/ServiceConstants';
import Spining from '../../UI/Spining/Spining';
import Alert from '../../UI/Alert/Alert';

const ProfileDetails = () => {
    let { id, brandNumber } = useParams();
    const adminAuthDetails = userloginDetails();

    const [email, setEmail] = useState('');
    const [error, setError] = useState({
        errorStatus: false,
        bgColor: '',
        errorMessage: ''
    });
    const [isLoading, setIsLoading] = useState(true);
    const [whatsApp, setwhatsApp] = useState({
        address: "",
        brand_number: "",
        category: "",
        comp_name: "",
        description: "",
        email: "",
        image_url: "",
        waba_id: "", // for Whatsapp Token
        number_not_active: "",
        status: "",
        phone_id: "",
        wp_token: "",
        token: "",
        fb_business_manager_id: "",
    });
    const [isError, setisError] = useState(false);
    const [errorType, seterrorType] = useState("");
    const [ErrorResponse, setErrorResponse] = useState(null);
    const [userid, setUserId] = useState('')

    const handleFields = (e) => {
        setwhatsApp({ ...whatsApp, [e.target.name]: e.target.value });
    };



    useEffect(() => {
        getWhatsAppDetails();
    }, []);

    const UpdateDetails = async () => {
        // if (!whatsApp.daily_sending_limit) {
        //   setisError(true);
        //   seterrorType("alert alert-danger alert-dismissible");
        //   setErrorResponse("Daily sending limit cannot be empity");
        //   return;
        // }
        try {
            document.querySelector("body").scrollTo(0, 0);
            const data = new FormData();
            data.append("status", whatsApp.status);
            data.append("app_id", whatsApp.app_id);
            data.append("user_id", adminAuthDetails.user.userId);
            data.append("method", "update_whatsapp_profile11");
            data.append("token", adminAuthDetails.token);
            data.append("ret_user_id", id);
            data.append("brand_number", whatsApp.brand_number);
            data.append("id", userid);
            data.append("fb_businness_id", whatsApp.fb_business_manager_id);
            const response = await fetch(
                `${HOST_URL}/admin_user_list.php`,
                {
                    method: "POST",
                    body: data,
                }
            );

            const result = await response.json(); // Parse JSON here

            if (result.success === true) {
                // Update states or handle response
                setisError(true);
                seterrorType("alert alert-success alert-dismissible");
                setErrorResponse(result.message);

                setTimeout(function () {
                    window.location.reload();
                }, 2000);
            } else {
                setisError(true);
                seterrorType("alert alert-danger alert-dismissible");
                setErrorResponse(result.message);
            }
        } catch (err) {
            console.log(err);
            // Handle error - display an error message or take appropriate action
        }
    };

    const getWhatsAppDetails = async () => {
        try {
            const { data } = await api.post(ADMIN_USERS_LIST, {
                token: adminAuthDetails.token,
                user_id: adminAuthDetails.user.userId,
                ret_user_id: id,
                brand_number: brandNumber,
                method: "ret_whatsapp_profile",
            });
            if (data.success === true) {
                console.log(data.data)
                setwhatsApp({
                    address: data.data[0].address,
                    brand_number: data.data[0].brand_number,
                    category: data.data[0].category,
                    comp_name: data.data[0].comp_name,
                    description: data.data[0].description,
                    email: data.data[0].email,
                    image_url: data.data[0].image_url,
                    number_not_active: data.data[0].number_not_active,
                    status: data.data[0].status,
                    app_id: data.data[0].app_id,
                    fb_business_manager_id: data.data[0].fb_businness_id

                });
                setUserId(data.data[0].id)
                setIsLoading(false);
            } else {
                setErrorResponse(data.message);
                setIsLoading(false);
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div>
            <div className="wrapper">
                <AdminLeftMenu />
                <div className="main">
                    <AdminHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                {isLoading && <Spining />}
                                <div className="col-auto d-none d-sm-block">
                                    <h3><strong className='text-dark'>WhatsApp User List</strong></h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">
                                            <nav className="text-center" aria-label="breadcrumb">
                                                <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
                                                    <Link to={`/admin/whatsapp-account-profile-details/${id}`}><li style={{ fontSize: "13px" }} className="breadcrumb-item active">Profile Details</li></Link>
                                                    <Link to={`/admin/whatsapp-account-whatsapp-template-list/${id}`}><li style={{ fontSize: "13px" }} className="breadcrumb-item">Whatsapp Template List</li></Link>
                                                    <Link to={`/admin/whatsapp-account-update-whatsapp-token/${id}`}><li style={{ fontSize: "13px" }} className="breadcrumb-item">Update Whastapp Token</li></Link>
                                                    <Link to={`/admin/whatsapp-account-change-route/${id}`}><li style={{ fontSize: "13px" }} className="breadcrumb-item">Change Route</li></Link>
                                                    <Link to={`/admin/update-waba-id/${id}`}><li style={{ fontSize: "13px" }} className="breadcrumb-item">Update Waba Id</li></Link>
                                                </ol>
                                            </nav>
                                        </h5>
                                    </div>
                                    <div className="card-body">
                                        {isError && <Alert type={errorType}> {ErrorResponse} </Alert>}
                                        <div className="form-row ">
                                            <div className="form-group col-md-6">
                                                <label className="col-form-label">
                                                    Company Name:
                                                </label>
                                                <input
                                                    className="form-control"
                                                    placeholder="Company Pvt. Ltd."
                                                    type="text"
                                                    disabled
                                                    defaultValue={whatsApp.comp_name}
                                                    name="comp_name"
                                                    id="flexCheckDefault"
                                                    onChange={handleFields}
                                                />
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label className="col-form-label">
                                                    No. not active on WhatsApp
                                                </label>
                                                <select
                                                    value={whatsApp.number_not_active}
                                                    onChange={handleFields}
                                                    disabled
                                                    className="form-control"
                                                >
                                                    <option value=""> Select </option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </div>

                                            <div className="form-group col-md-12">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label className="col-form-label">
                                                            Display Picture:
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            disabled
                                                            name="emailunsub"
                                                            id="flexCheckDefault"
                                                        />
                                                        <small>
                                                            (Max size of 5MB, an image size of 640x640 is
                                                            recommended)
                                                        </small>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <img
                                                            className="img-fluid"
                                                            src={whatsApp.image_url}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group col-md-12">
                                                <label className="col-form-label">
                                                    Business Profile Address:
                                                </label>
                                                <textarea
                                                    placeholder="Enter Full Address (Maximum of 256 characters)"
                                                    className="form-control"
                                                    value={whatsApp.address}
                                                    disabled
                                                    name="address"
                                                    onChange={handleFields}
                                                ></textarea>
                                            </div>

                                            <div className="form-group col-md-12">
                                                <label className="col-form-label">
                                                    Business Description:
                                                </label>
                                                <textarea
                                                    placeholder="Enter Full Address (Maximum of 256 characters)"
                                                    className="form-control"
                                                    value={whatsApp.description}
                                                    disabled
                                                    name="description"
                                                    onChange={handleFields}
                                                ></textarea>
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label className="col-form-label">
                                                    Business Category:
                                                </label>
                                                <input
                                                    className="form-control"
                                                    placeholder="Grocery, Technology"
                                                    type="text"
                                                    disabled
                                                    id="flexCheckDefault"
                                                    value={whatsApp.category}
                                                    name="category"
                                                    onChange={handleFields}
                                                />
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label className="col-form-label">
                                                    Business Email:
                                                </label>
                                                <input
                                                    className="form-control"
                                                    placeholder="company@mail.com"
                                                    type="text"
                                                    id="flexCheckDefault"
                                                    disabled
                                                    value={whatsApp.email}
                                                    name="email"
                                                    onChange={handleFields}
                                                />
                                            </div>

                                            <div className="form-group col-md-12">
                                                <label className="col-form-label">
                                                    WhatsApp Profile Status:
                                                </label>
                                                <input
                                                    className="form-control"
                                                    placeholder="The max length for the string is 139 characters."
                                                    type="text"
                                                    disabled
                                                    name="profile_status"
                                                    onChange={handleFields}
                                                    id="flexCheckDefault"
                                                    value={whatsApp.profile_status}
                                                />
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label className="col-form-label">
                                                    Proposed WhatsApp Number:
                                                </label>
                                                <input
                                                    className="form-control"
                                                    placeholder="91XXXXXXXX"
                                                    type="text"
                                                    // disabled
                                                    value={whatsApp.brand_number}
                                                    name="brand_number"
                                                    id="flexCheckDefault"
                                                    onChange={handleFields}
                                                />
                                                <small>
                                                    (For netcore remove 91 from brand number and rest
                                                    all add 91 with brand number)
                                                </small>
                                            </div>

                                            <div className="form-group col-md-12">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label className="col-form-label">
                                                            App Id (Mendatory for Pinnacle and meta direct route)
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            placeholder="app_id"
                                                            type="text"
                                                            name="app_id"
                                                            onChange={handleFields}
                                                            id="flexCheckDefault"
                                                            value={whatsApp.app_id}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="col-form-label">
                                                            Facebook Business Manager Id
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            placeholder="facebook Business manager Id"
                                                            type="text"
                                                            name="fb_business_manager_id"
                                                            onChange={handleFields}
                                                            id="flexCheckDefault"
                                                            value={whatsApp.fb_business_manager_id}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group col-md-4">
                                                <label className="col-form-label">
                                                    WhatsApp Account Status:
                                                </label>
                                                <select
                                                    onChange={handleFields}
                                                    value={whatsApp.status}
                                                    className="form-control"
                                                    name="status"
                                                >
                                                    <option>Select Account Status </option>
                                                    <option value={0}>Pending </option>
                                                    <option value={1}>Approved </option>
                                                    <option value={2}>Processed </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="form-group col-md-6">
                                                <p className='mt-2' style={{fontSize:"12px"}}>Pinnacle APP ID:- 675419764769671 <br/> Meta Direct APP Id:- 4884679621657801</p>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <button
                                                    onClick={UpdateDetails}
                                                    className="btn btn-sm float-right btn-primary mt-3"
                                                >
                                                    Update Account Status
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <AdminFooter />
                </div>
            </div>
        </div>
    );
};

export default ProfileDetails;
