import React, { useEffect, useState } from "react";
import DashboardLayout from "../../UI/Layout/DashboardLayout";
import "./Agent.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import AlertBox from "../agent/AlertBox";
import Title from "../../UI/Text/Title";
import { HOST_URL } from "../api/Api";
import { PiUserListDuotone, PiChatTextDuotone } from "react-icons/pi";

const AgentsLists = () => {
  const emailRegex = /\S+@\S+\.\S+/;
  const [agentList, setAgentList] = useState({});
  const [updateForm, setUpdateForm] = useState(false);
  const [updateValue, setupdateValue] = useState({});
  const [alert, setAlert] = useState(null);

  const user = JSON.parse(localStorage.getItem("login"));
  const user_id = user.user.userId;
  const token = user.token;

  useEffect(() => {
    showAgent();
  }, []);

  const showAgent = async () => {
    const data = {
      user_id: user_id,
      method: "retrieve",
      token: token,
    };

    try {
      const response = await fetch(`${HOST_URL}/agent.php`, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();
      if (result.success) {
        setAgentList(result.data);
      } else {
        console.log("Failed to fetch data:", response.statusText);
      }
    } catch (error) {
      console.error("Error occurred while fetching data:", error);
    }
  };

  const DeleteAgent = async (id) => {
    const data = {
      user_id: user_id,
      method: "delete",
      token: token,
      id: id,
    };

    try {
      const response = await fetch(`${HOST_URL}/agent.php`, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();
      if (result.success) {
        showAgent();
      } else {
        console.log("Failed to delete data:", response.statusText);
      }
    } catch (error) {
      console.error("Error occurred while deleting data:", error);
    }
  };

  const toggleUpdate = async (id) => {
    const data = {
      user_id: user_id,
      method: "retrieveid",
      token: token,
      id: id,
    };

    try {
      const response = await fetch(`${HOST_URL}/agent.php`, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();
      if (result.success) {
        setupdateValue(result.data[0]);
        setUpdateForm(true);
      } else {
        console.log("Failed to retrieve data:", response.statusText);
      }
    } catch (error) {
      console.error("Error occurred while retrieving data:", error);
    }
  };

  const handleChange = (e) => {
    setupdateValue({ ...updateValue, [e.target.name]: e.target.value });
  };

  const showAlert = (type, message) => {
    setAlert({ type, message });
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  };

  const isValid = () => {
    if (!updateValue.name) {
      showAlert("error", "Please enter a name.");
      return false;
    }
    if (!updateValue.mobile || updateValue.mobile.length !== 10) {
      showAlert("error", "Please enter a valid 10-digit mobile number.");
      return false;
    }
    if (updateValue.password !== updateValue.cpassword) {
      showAlert("error", "Passwords do not match.");
      return false;
    }
    return true;
  };

  const updateData = async (event) => {
    event.preventDefault();
    if (isValid()) {
      const data = {
        user_id: user_id,
        method: "update",
        token: token,
        ...updateValue,
      };

      try {
        const response = await fetch(`${HOST_URL}/agent.php`, {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify(data),
        });
        const result = await response.json();
        if (result.success) {
          showAgent();
          showAlert("success", "Data updated successfully");
          setUpdateForm(false);
        } else {
          showAlert("error", "Failed to update data");
          console.log("Failed to update data:", response.statusText);
        }
      } catch (error) {
        console.error("Error occurred while updating data:", error);
      }
    }
  };

  return (
    <>
      <DashboardLayout>
        <main className="content">
          <div className="d-flex wFull">
            <div className="ChatFlexLeft mr-3">
              <div className="ChatLive_card px-3 py-3">
                <h3>Live Chat</h3>
                <ul class="nav flex-column liveChatList">
                  <li>
                    <a href="agent-list" className="active">
                      <span className="iconWH">
                        <PiUserListDuotone />
                      </span>{" "}
                      Chat Widget
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <span className="iconWH">
                        <PiChatTextDuotone />
                      </span>{" "}
                      Chat Setting
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex-grow-1">
              <div className="ChatLive_card d-flex flex-column wFull px-3 py-3">
                <div className="TitleBox">
                  <h3>Chat Widget</h3>
                </div>
                <div className="row">
                  <div className="col-md-10 mb-2">
                    <label className="col-form-label">Widget</label>
                    <input className="form-control" placeholder="Name" />
                  </div>
                  <div className="col-md-2 mb-2">
                    <div className="col-form-label">&nbsp;</div>
                    <button className="btn btn-success btn-sm" type="button">
                      + Add Widget
                    </button>
                  </div>
                  <div className="col-md-6 mb-2">
                    <label className="col-form-label">Widget Name</label>
                    <input className="form-control" placeholder="Widget Name" />
                  </div>
                  <div className="col-md-6 mb-2">
                    <label className="col-form-label">Widget No</label>
                    <input className="form-control" placeholder="Widget No" />
                  </div>
                  <div className="col-md-12 mb-2">
                    <label className="col-form-label">Widget Url</label>
                    <input className="form-control" placeholder="Widget Url" />
                  </div>
                </div>

                <div
                  className="table-responsive mt-4"
                  style={{ display: "none" }}
                >
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>Action</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(agentList).map((item, index) => (
                        <tr key={index}>
                          <td>
                            <div className="dropdown">
                              <button
                                className="btn btn-secondary btn-xs dropdown-toggle"
                                type="button"
                                id={`dropdownMenuButton${index}`}
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Actions
                              </button>
                              <div
                                className="dropdown-menu"
                                aria-labelledby={`dropdownMenuButton${index}`}
                              >
                                <a
                                  className="dropdown-item"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    toggleUpdate(agentList[item].id)
                                  }
                                >
                                  <FontAwesomeIcon icon={faTimes} /> Update
                                </a>
                                <a
                                  className="dropdown-item"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    DeleteAgent(agentList[item].id)
                                  }
                                >
                                  <FontAwesomeIcon icon={faTimes} /> Delete
                                </a>
                              </div>
                            </div>
                          </td>
                          <td>{agentList[item].name}</td>
                          <td>{agentList[item].email}</td>
                          <td>{agentList[item].mobile}</td>
                          <td>
                            {agentList[item].is_active === 1 ? (
                              <span className="badge badge-success">
                                Active
                              </span>
                            ) : (
                              <span className="badge badge-danger">
                                Inactive
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {updateForm && (
                  <div className="popup-agent">
                    <div className="assign-popup-content-agent">
                      <form>
                        <div class="form-group col-md-12">
                          <h5 class=" popupHeader">
                            Edit {updateValue?.email?.split("@")[0]}{" "}
                            <span class="popupPointer">
                              <FontAwesomeIcon
                                onClick={() => setUpdateForm(false)}
                                icon={faTimes}
                              />
                            </span>
                          </h5>
                        </div>
                        <div class="overflowblocks">
                          {alert && (
                            <AlertBox
                              type={alert.type}
                              message={alert.message}
                              onClose={() => setAlert(null)}
                            />
                          )}
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <label className="agent-label" for="email">
                                User Name
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                value={updateValue.email}
                                onChange={handleChange}
                                placeholder="Enter Email"
                                disabled
                              />
                              <p style={{ fontSize: "10px" }}>
                                Note: Email cannot be changed
                              </p>
                            </div>
                            <div className="form-group col-md-6">
                              <label className="agent-label" for="name">
                                Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="name"
                                value={updateValue.name}
                                onChange={handleChange}
                                placeholder="Enter Name"
                              />
                            </div>
                          </div>
                          <div
                            className="form-row"
                            style={{ marginTop: "-10px" }}
                          >
                            <div className="form-group col-md-6">
                              <label className="agent-label" for="mobile">
                                Mobile
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                name="mobile"
                                value={updateValue.mobile}
                                onChange={handleChange}
                                placeholder="Enter Mobile"
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label className="agent-label" for="name">
                                Status
                              </label>
                              <select
                                name="is_active"
                                value={updateValue.is_active}
                                onChange={handleChange}
                                className="form-control"
                              >
                                {/* <option value=''>Select Status</option> */}
                                <option value="1">Active</option>
                                <option value="0">InActive</option>
                              </select>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <label className="agent-label" for="password">
                                Password
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                name="password"
                                value={updateValue.password}
                                onChange={handleChange}
                                placeholder="Enter Password"
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label className="agent-label" for="cpassword">
                                Confirm Password
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                name="cpassword"
                                value={updateValue.cpassword}
                                onChange={handleChange}
                                placeholder="Enter Confirm Password"
                              />
                            </div>
                          </div>
                        </div>
                        <button
                          className="btn btn-primary"
                          onClick={(e) => updateData(e)}
                        >
                          Update
                        </button>
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>
      </DashboardLayout>
    </>
  );
};

export default AgentsLists;
