import React, { useState } from "react";
import DashboardLayout from "../../../UI/Layout/DashboardLayout";
import ChatLeftMenu from "./ChatLeftMenu";
import { AiOutlineUser, AiTwotoneDelete } from "react-icons/ai";
import { FiArrowLeft } from "react-icons/fi";

const TriggerEdit = () => {
  const [selectedBox, setSelectedBox] = useState(null);
  const handleSelect = (boxName) => {
    setSelectedBox(boxName);
  };
  const closeBox = () => {
    setSelectedBox(null);
  };

  return (
    <>
      <DashboardLayout>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="d-flex wFull">
              <div className="ChatLive_card col-md-2">
                <ChatLeftMenu />
              </div>
              <div className="col-md-10">
                <div className="ChatLive_card d-flex flex-column wFull px-3 py-3">
                  <div className="TitleBox">
                    <a href="/dashboard/trigger-edit">
                      <FiArrowLeft /> Back
                    </a>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="col-md-12 mb-2">
                        <label>Trigger Type</label>
                        <div class="input-group drop-down-icons mb-3">
                          <select class="form-control">
                            <option>Basic Site Notification</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-12 mb-2">
                        <label>Trigger Name</label>
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Trigger Name"
                          />
                        </div>
                      </div>

                      <div className="col-md-12 mb-2">
                        <label>Delay</label>
                        <div class="input-group drop-down-icons mb-3">
                          <select class="form-control">
                            <option>No Delay</option>
                            <option>10 seconds</option>
                            <option>20 seconds</option>
                            <option>30 seconds</option>
                            <option>45 seconds</option>
                            <option>1 Minute</option>
                            <option>2 Minute</option>
                            <option>3 Minute</option>
                            <option>4 Minute</option>
                            <option>10 Minute</option>
                            <option>15 Minute</option>
                            <option>20 Minute</option>
                            <option>30 Minute</option>
                            <option>1 Hour</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-12 mb-2">
                        <label>Agent's Name</label>
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Customer Support"
                          />
                        </div>
                      </div>

                      <div className="col-md-12 mb-2">
                        <label>Trigger Message</label>
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Customer Support"
                          />
                        </div>
                      </div>

                      <div className="col-md-12 mb-4">
                        <label>Trigger Message</label>
                        <div className="w-full">
                          <div class="form-check form-check-inline mb-2">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              value="option1"
                            />
                            <label class="form-check-label" for="inlineRadio1">
                              Use a Predifined Shortcuts
                            </label>
                          </div>
                        </div>
                        <div class="input-group drop-down-icons mb-2">
                          <select class="form-control">
                            <option>Select Shortcut</option>
                          </select>
                        </div>
                        <div className="w-full text-center my-2">OR</div>
                        <div className="w-full">
                          <div class="form-check form-check-inline mb-2">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              value="option1"
                            />
                            <label class="form-check-label" for="inlineRadio1">
                              Set your message
                            </label>
                          </div>
                        </div>

                        <div class="input-group mb-2">
                          <textarea class="form-control">
                            Hi I am Online and Ready to Answer your Queries :)
                          </textarea>
                        </div>
                      </div>

                      <div class="col-md-12 mb-3">
                        <label className="text-sm">Suggested Message</label>

                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Enter Message"
                          />
                          <div class="input-group-append">
                            <span class="input-group-text">
                              <AiTwotoneDelete size={20} />
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12 mb-3">
                        <label className="text-sm">Status</label>

                        <div class="input-group mb-2">
                          <label class="switch">
                            <input type="checkbox" />
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>

                      <div class="col-md-12 mb-3">
                        <label className="text-sm">Trigger Description</label>

                        <div class="input-group mb-3">
                          <textarea
                            class="form-control"
                            placeholder="Enter Trigger Description"
                          ></textarea>
                        </div>
                      </div>

                      <div className="col-md-12 mb-4">
                        <button
                          type="button"
                          class="btn btn-sm btn-outline-dark"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          class="btn btn-outline-danger btn-sm"
                        >
                          Delete
                        </button>
                        <button className="btn btn-success btn-sm">Save</button>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="ChatPreviewFrame wFull d-flex align-items-start flex-column">
                        <div className="d-flex wFull alert-dark px-3 py-3">
                          <span className="iconCircluate bg-white mr-2">
                            <AiOutlineUser />
                          </span>
                          <h6 className="d-flex mb-0">Team</h6>
                        </div>
                        <div class="chat-window d-flex flex-column">
                          <div class="chat-bubble sender">
                            Hi I am Online and Ready to Answer your Queries 🙂
                          </div>
                          {/* <div class="chat-bubble receiver">
                            I have a question about Bootstrap.
                          </div> */}
                        </div>

                        <div className="d-flex px-3 py-2 justify-content-center borderBox wFull text-center">
                          This is the Preview
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </DashboardLayout>
    </>
  );
};

export default TriggerEdit;
