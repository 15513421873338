import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import EmptyImg from "../Dashboard/assets/img/signupDone.gif";
import logo from "../Dashboard/assets/img/logo.png";
import { Link } from "react-router-dom";
import Banner2 from "../Dashboard/assets/img/appointment-reminder.png";
import Banner3 from "../Dashboard/assets/img/emailicons4.png";
import Banner4 from "../Dashboard/assets/img/emailicons3.png";
import Banner5 from "../Dashboard/assets/img/whstaApp-conversations.png";

const SignupSuccess = () => {
  console.log("success signup");
  return (
    <div style={{ overflow: "hidden" }}>
      <section style={{ background: "#fff" }} className="loginbg">
        <div className=" d-flex flex-column">
          <div className="row justify-content-center min-vh-100">
            <div className="col-md-6 col-lg-6 col-xl-6">
              <div className="">
                <div className="">
                  <div className="loginsuccessBox">
                    <div className="text-center">
                      <div className="card-body">
                        <img
                          alt="Empty Data"
                          style={{ width: "100px" }}
                          src={EmptyImg}
                        />
                        <p>
                          Your account has been created successfully, <br />{" "}
                          Please find login credentials on your email.
                        </p>
                        <Link className="btn btn-primary btn-sm" to="/login">
                          Login To Account
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{ background: "#5f5a7f" }}
              className="col-md-6 d-none d-md-block arrow-left "
            >
              <div className="d-flex align-items-center min-vh-100  ">
                <div className="row w-50 mb-5 mt-5 text-center d-flex  justify-content-center ">
                  <div className="col-lg-12">
                    <h4 className="text-white">
                      A single platform for all Conversations
                    </h4>
                    <div className="border_bar"></div>
                    <div className="mt-2"></div>
                  </div>
                  <div className="col-md-8">
                    <div className="row justify-content-center mt-5 mb-5">
                      <div className="col-md-4">
                        {" "}
                        <p className="text-white">Developer Friendly APIs</p>
                      </div>
                      <div className="col-md-4">
                        {" "}
                        <p className="text-white">Secure & Scalable</p>
                      </div>
                      <div className="col-md-4">
                        {" "}
                        <p className="text-white">Impactful Campaigns</p>
                      </div>
                    </div>
                    <div className="d-flex login-page-info  m-3 justify-content-between">
                      <div className="">
                        <img src={Banner2} />
                        <p className="text-white">SMS </p>
                      </div>
                      <div className="">
                        <img src={Banner4} />
                        <p className="text-white">Voice </p>
                      </div>
                      <div className="">
                        <img src={Banner3} />
                        <p className="text-white">Email </p>
                      </div>
                      <div className="">
                        <img src={Banner5} />
                        <p className="text-white">Whatsapp</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SignupSuccess;
