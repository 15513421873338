import Axios from "axios";
import { checkUserUrl } from "../../Utils/Utils";
const lStorage = JSON.parse(localStorage.getItem("login"));

if (lStorage != null) {
  var userId = lStorage.user.userId;
  var userToken = lStorage.token;
  var url = lStorage.url;
}


let oroginURL = checkUserUrl(url);

export const HOST_URL = `${
  "https://console.authkey.io/api"
}`;
export const HOST_URL_DB2 = `${
  "https://db2.authkey.io/api"
}`;
export const NODE_HOST_URL = `${
  "https://napi.authkey.io/api"
}`;
export const REQUEST_URL = `${
  oroginURL == true ? "https://api.authkey.io/request" : "/restapi/request.php"
}`;
export const SOCKET_URL = "https://napi.authkey.io"

export const whatsappSummery = async (dateFrom, dateto) => {
  return Axios.get(
    `${HOST_URL}/whatsapp_report.php?user_id=${userId}&method=summary&token=${userToken}&date_from=${dateFrom}&date_to=${dateto}`
  );
};
export const getWhatsaAppTemplateList = async () => {
  return Axios.get(
    `${HOST_URL}/whatsapp_template.php?user_id=${userId}&method=retrieve&token=${userToken}`
  );
};

export const campaignContactList = async (listId) => {
  return Axios.get(
    `${HOST_URL}/contact-list.php?user_id=${userId}&method=retrieve_data&token=${userToken}&list_id=${listId}`
  );
};

export const contactListAPI = (method) => {
  return Axios.get(
    `${HOST_URL}/contact-list.php?user_id=${userId}&method=${method}&token=${userToken}`
  );
};

export const downloadContactList = (id) => {
  window.location.href = `${HOST_URL}/contact-list.php?user_id=${userId}&method=download&token=${userToken}&list_id=${id}`;
};

export const WhstappAccountList = async () => {
  return await Axios.get(
    `${HOST_URL}/wp_profile.php?user_id=${userId}&method=retrieve&token=${userToken}`
  );
};

export const WhstappGalleryList = async () => {
  return Axios.get(
    `${HOST_URL}/uploadFileWhatsapp.php?user_id=${userId}&method=retrieve&token=${userToken}`
  );
};

export const getBlogPost = async () => {
  return Axios.get(
    `${HOST_URL}/blog.php?user_id=10&method=retrieve&token=68f3dcf1bf51e3e85150867e8df35586`
  );
};

export const getSMSTemplateList = async (method = "retrieve", id = null) => {
  return Axios.get(
    `${HOST_URL}/sms_template.php?user_id=${userId}&method=${method}&id=${id}&token=${userToken}`
  );
};

export const getUserPriceList = async (method = "retrieve", keyword = "") => {
  return Axios.get(
    `${HOST_URL}/user_pricelist.php?user_id=${userId}&method=${method}&token=${userToken}&keyword=${keyword}`
  );
};

export const getCampaignSMSTemplateList = async (
  method = "retrieve",
  id = null
) => {
  return Axios.get(
    `${HOST_URL}/sms_template.php?user_id=${userId}&method=${method}&id=${id}&token=${userToken}`
  );
};

export const getDLTsmsTemplateList = async (method = "retrieve") => {
  console.log(
    `${HOST_URL}/dlt_template.php?user_id=${userId}&method=${method}&token=${userToken}`
  );
  return Axios.get(
    `${HOST_URL}/dlt_template.php?user_id=${userId}&method=${method}&token=${userToken}`
  );
};

export const getAllCountryList = async () => {
  return Axios.get(`${HOST_URL}/country_master.php`);
};

export const createWhatsAppCampaign = (data) => {
  return Axios.post(`${HOST_URL}/bulk_campaign_sms.php`, data);
};

export const createSMSCampaign = (data) => {
  return Axios.post(`${HOST_URL_DB2}/bulk_campaign_sms.php`, data);
};

export const conversation = async (data) => {
  return await Axios.post(`${HOST_URL}/netcore_conversation.php`, data);
};

export const getFaq = ({ method = "retrieve", query = null }) => {
  return Axios.get(
    `${HOST_URL}/faq.php?user_id=10&method=${method}&token=68f3dcf1bf51e3e85150867e8df35586&name=${query}`
  );
};

// Agent API

export const whatsAppAgent = async (data) => {
  return Axios.post(`${HOST_URL}/agent.php`, data);
};

export const deleteOPTOUTNumber = async (mobile) => {
  let data = {
    user_id: userId,
    token: userToken,
    method: "delete",
    mobile: mobile,
  };
  return Axios.post(`${HOST_URL}/optout_list.php`, data);
};

export const wp_get_billable_notbillable = (listID, templateID) => {
  return Axios.get(
    `${HOST_URL}/wp_get_billable_notbillable.php?user_id=${userId}&token=${userToken}&method=retrieve&list_id=${listID}&template_id=${templateID}`
  );
};

export const UploadImageToServer = (data) => {
  return Axios.post(`${HOST_URL}/blog-gallery.php`, data);
};

export const contactUs = (data) => {
  return Axios.post(`${HOST_URL}/contact-us.php`, data);
};

export const changePassword = (data) => {
  return Axios.post(`${HOST_URL}/change_password.php`, data);
};

export const userProfile = (method) => {
  return Axios.get(
    `${HOST_URL}/profile.php?user_id=${userId}&token=${userToken}&method=retrieve`
  );
};

export const userAPIReQuest = (method) => {
  return Axios.get(
    `${HOST_URL}/user_api_request.php?user_id=${userId}&token=${userToken}&method=summary`
  );
};

export const translateAPI = (word, itc) => {
  return Axios.get(
    `${HOST_URL}/translate/getTranslated.php?word=${word}&itc=${itc}`
  );
};
