import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import DashboardHeader from "../../Header/DashboardHeader";
import DashboardFooter from "../../Footer/DashboardFooter";
import DashboardLeftMenu from "../../Dashboard/DashboardLeftMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dialog from "react-dialog";
import {
  faCalendarCheck,
  faInfoCircle,
  faMobile,
  faAddressBook,
  faBan,
  faDownload,
  faSearch,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import EmptyImg from "../../Dashboard/assets/img/create-project.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/monikai.css";
import { HOST_URL } from "../api/Api";
class WhatsappDetailReports extends Component {
  constructor(props) {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    super(props);
    this.state = {
      user_id: userId,
      token: userToken,
      whatsAppDetailsReportList: "",
      responceMessage: "",
      status: "",
      senderid: "",
      mobile: "",
      email: "",
      channel: this.props.match.params.channel,
      dateFrom: this.props.match.params.dateFrom,
      dateTo: this.props.match.params.dateTo,
      SelecteddateFrom: "",
      SelecteddateTo: "",
      apiResponse: [],
      apiDLResponse: [],
      isDialogOpen: false,
      isDLRResponseDialogOpen: false,
      successMessage: false,
      errorMessage: "",
      bgColor: "",
    };
    // this.checkResponce = this.checkResponce.bind(this)
  }

  componentDidMount() {
    this.detailsReport();
  }

  // ${HOST_URL}/whatsapp_report.php?user_id=26&method=details&token=72e8fc25e46476d7c48bad2c09d56347&date_from=2023-1-04&date_to=2023-1-04

  detailsReport() {
    fetch(
      `${HOST_URL}/whatsapp_report.php?user_id=${this.state.user_id}&method=details&campaign_id=8&token=${this.state.token}&date_from=${this.state.dateFrom}&date_to=${this.state.dateTo}`
    ).then((response) => {
      response.json().then((result) => {
        console.log("result", result);
        if (result.success === true) {
          this.setState({
            whatsAppDetailsReportList: result.message.report,
          });
        } else {
          this.setState({ responceMessage: result.message });
        }
      });
    });
  }

  filterDetailsWithChannel() {
    this.setState({ whatsAppDetailsReportList: "" });
    fetch(
      `${HOST_URL}/whatsapp_report.php?user_id=${this.state.user_id}&method=details&token=${this.state.token}&date_from=${this.state.dateFrom}&date_to=${this.state.dateTo}&mobile=${this.state.mobile}&senderid=${this.state.senderid}&status=${this.state.status}`
    ).then((response) => {
      response.json().then((result) => {
        // console.log(result)
        if (result.success === true) {
          this.setState({ whatsAppDetailsReportList: result.message.report });
        } else {
          this.setState({
            successMessage: true,
            errorMessage: result.message,
            bgColor: "alert alert-danger alert-dismissible",
          });
        }
      });
    });
  }

  dataDownload() {
    this.setState({ successMessage: false });
    var date1 = new Date(this.state.dateFrom);
    var date2 = new Date(this.state.dateTo);
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    console.log("Difference_In_Days", Math.trunc(Difference_In_Days));

    if (Math.trunc(Difference_In_Days) <= 15) {
      window.location.href = `${HOST_URL}/user_report.php?user_id=${this.state.user_id}&method=download&channel=wp&token=${this.state.token}&date_from=${this.state.dateFrom}&date_to=${this.state.dateTo}`;
    } else {
      document.querySelector("body").scrollTo(0, 0);
      this.setState({
        successMessage: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: `Please go to Download Center to Download 15 day's after data.`,
      });
    }
  }

  dateFrom = (date) => {
    let dated =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      ("0" + date.getDate()).slice(-2);
    this.setState({
      dateFrom: dated,
      SelecteddateFrom: date,
    });
  };

  dateTo = (date) => {
    let dated =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      ("0" + date.getDate()).slice(-2);
    this.setState({
      dateTo: dated,
      SelecteddateTo: date,
    });
  };

  setStatus() {
    if (this.state.channel === "sms") {
      return (
        <>
          <option value=""> All </option>
          <option value="delivered"> Delivered </option>
          <option value="failed"> Failed </option>
          <option value="submitted"> Submitted </option>
        </>
      );
    } else {
      return (
        <>
          <option value=""> No Channel Selected </option>
        </>
      );
    }
  }

  checkResponce = (e) => {
    fetch(
      `${HOST_URL}/user_report.php?user_id=${this.state.user_id}&method=request_status&token=${this.state.token}&logid=${e}`
    ).then((response) => {
      response.json().then((result) => {
        console.log("result", result);
        if (result.success === true) {
          this.setState(
            {
              apiResponse: result.message,
            },
            () => {
              // Now that apiResponse is set, open the dialog
              this.openDialog("request_status");
            }
          );
        } else {
          this.setState({
            successMessage: true,
            errorMessage: result.message,
            bgColor: "alert alert-danger alert-dismissible",
          });
        }
      });
    });
  };

  checkDlrResponse = (dlrResponse, karix_status) => {
    // Assuming dlrResponse contains the necessary details
    const dlrResponseDetails = dlrResponse;
    
    let karixStatus;
    try {
      karixStatus = JSON.parse(karix_status);
    } catch (error) {
      console.error('Error parsing karix_status:', error);
      karixStatus = null; // Handle the case where parsing fails
    }
  
    // Determine the message status
    const messageStatus = karixStatus && karixStatus.messages && karixStatus.messages.length > 0 
      ? karixStatus.messages[0].message_status 
      : karix_status;
  
    // Update the state to open the DLR Response Dialog
    this.setState({
      apiDLResponse: [{
        dlr_reponse: dlrResponseDetails || messageStatus
      }],
      isDLRResponseDialogOpen: true,
    });
  };

  openDialog = () => this.setState({ isDialogOpen: true });

  handleClose = () => this.setState({ isDialogOpen: false });

  openDialogDLR = () => this.setState({ isDLRResponseDialogOpen: true });

  handleCloseDLR = () => this.setState({ isDLRResponseDialogOpen: false });

  render() {
    console.log("state", this.state);

    return (
      <div className="wrapper">
        <DashboardLeftMenu />
        <div className="main">
          <DashboardHeader />
          <main className="content">
            <div className="container-fluid p-0">
              <div className=" mb-2 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                  <h3>
                    <strong>WhatsApp Details </strong> Reports{" "}
                  </h3>
                </div>
              </div>
              <div className="">
                <div className="card">
                  <div className="">
                    <h5 className="card-title  mt-5">
                      <div className="col-auto ml-auto text-right mt-n1">
                        <nav className="text-center" aria-label="breadcrumb">
                          <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
                            <Link to="/dashboard/whatsapp-report">
                              <li className="breadcrumb-item">
                                {" "}
                                Today's Summary{" "}
                              </li>{" "}
                            </Link>
                            <Link
                              to={
                                "/dashboard/whatsapp-detail-reports/" +
                                this.state.dateFrom +
                                "/" +
                                this.state.dateTo
                              }
                            >
                              {" "}
                              <li className="breadcrumb-item active">
                                {" "}
                                Detailed Reports{" "}
                              </li>{" "}
                            </Link>
                          </ol>
                        </nav>
                      </div>
                    </h5>
                  </div>

                  <div className="card-body">
                    {this.state.successMessage ? (
                      <div className={this.state.bgColor} role="alert">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                        <div className="">{this.state.errorMessage}</div>
                      </div>
                    ) : null}

                    {this.state.isDialogOpen && (
                      <Dialog
                        modal={true}
                        title="API Requets / Response Status"
                        isDraggable={true}
                        onClose={this.handleClose}
                        maxWidth={"md"}
                      >
                        <table className="table table-striped table-sm  project  my-0">
                          <thead>
                            <tr className="text-left">
                              <th>Email/Mobile</th>
                              <th>API Request</th>
                              <th>API Status / Response</th>
                              <th>Received</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.apiResponse.map((itam, i) => (
                              <tr
                                className={
                                  itam.status === "success"
                                    ? "bg-success-table"
                                    : "bg-danger-table"
                                }
                                key={i}
                              >
                                <td className="text-left">
                                  {itam.email && `{`}
                                  {itam.email}
                                  {itam.email && `}`}
                                  <br />
                                  {itam.mobile && `{`}
                                  {itam.mobile}
                                  {itam.mobile && `}`}
                                </td>

                                <td className="text-left">
                                  <JSONPretty
                                    id="json-pretty"
                                    data={itam.workflows}
                                  ></JSONPretty>
                                </td>
                                <td className="text-left">
                                  <JSONPretty
                                    id="json-pretty"
                                    data={itam.message}
                                  ></JSONPretty>
                                </td>
                                <td className="text-left">
                                  {itam.submitted_on}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Dialog>
                    )}

                    {this.state.isDLRResponseDialogOpen && (
                      <Dialog
                        modal={true}
                        title="DLR Repost Details"
                        isDraggable={true}
                        onClose={this.handleCloseDLR}
                        maxWidth={"md"}
                        style={{ width: 700 }}
                      >
                        {Array.isArray(this.state.apiDLResponse) ? (
                          this.state.apiDLResponse.map((item, i) => (
                            <div key={i}>
                              <JSONPretty
                                id={`json-pretty-${i}`}
                                data={item.dlr_reponse}
                              ></JSONPretty>
                            </div>
                          ))
                        ) : (
                          <p>No DLR response data available.</p>
                        )}
                      </Dialog>
                    )}

                    <div className="filter-option">
                      <h6 className="card-subtitle text-muted">Filter </h6>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-text">
                              <FontAwesomeIcon icon={faCalendarCheck} />
                            </div>

                            <DatePicker
                              className="form-control btn-block"
                              dateFormat="yyyy/MM/dd"
                              value={this.state.dateFrom}
                              onChange={this.dateFrom}
                              selected={this.state.SelecteddateFrom}
                              placeholderText="From Date"
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-text">
                              <FontAwesomeIcon icon={faCalendarCheck} />
                            </div>

                            <DatePicker
                              className="form-control btn-block"
                              dateFormat="yyyy/MM/dd"
                              value={this.state.dateTo}
                              onChange={this.dateTo}
                              selected={this.state.SelecteddateTo}
                              placeholderText="Date To"
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-text">
                              <FontAwesomeIcon icon={faMobile} />
                            </div>
                            {this.state.channel === "email" ? (
                              <input
                                onChange={(event) =>
                                  this.setState({ email: event.target.value })
                                }
                                type="text"
                                placeholder="Enter Email"
                                className="form-control"
                              />
                            ) : (
                              <input
                                onChange={(event) =>
                                  this.setState({ mobile: event.target.value })
                                }
                                type="text"
                                placeholder="Enter Mobile NO"
                                className="form-control"
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-text">
                              <FontAwesomeIcon icon={faAddressBook} />
                            </div>
                            <input
                              disabled={this.state.channel !== "sms"}
                              onChange={(event) =>
                                this.setState({ senderid: event.target.value })
                              }
                              type="text"
                              placeholder="Enter Sender ID"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-text">
                              <FontAwesomeIcon icon={faBan} />
                            </div>
                            <select
                              onChange={(event) =>
                                this.setState({ status: event.target.value })
                              }
                              className="form-control"
                            >
                              <option value=""> Select Status </option>
                              {this.setStatus()}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-5"></div>
                        <div className="col-md-4">
                          <button
                            type="button"
                            onClick={() => this.dataDownload()}
                            className="btn-success btn-sm btn-block ml-auto"
                          >
                            <FontAwesomeIcon icon={faDownload} /> Download
                            Reports{" "}
                          </button>
                          <small>
                            To Download Large File Data Go to{" "}
                            <Link to="/dashboard/download-centre">
                              Download Center{" "}
                            </Link>
                          </small>
                        </div>
                        <div className="col-md-3">
                          <button
                            type="button"
                            onClick={() => this.filterDetailsWithChannel()}
                            className="btn-primary btn-block btn-sm ml-auto"
                          >
                            {" "}
                            <FontAwesomeIcon icon={faSearch} /> Filter Search{" "}
                          </button>
                        </div>
                      </div>
                    </div>

                    <hr />

                    <div className="row">
                      <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                        <div
                          style={{ overflow: "scroll", height: 400 + "px" }}
                          className=" flex-fill"
                        >
                          <div className="">
                            {this.state.whatsAppDetailsReportList ? (
                              <table className="table my-0">
                                <thead>
                                  <tr>
                                    <th>Status</th>
                                    <th>Mobile</th>
                                    <th>Cost</th>
                                    <th>Country</th>
                                    <th>Message</th>

                                    <th>Submitted </th>
                                    <th>Delivered </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.whatsAppDetailsReportList.map(
                                    (item, i) => (
                                      <tr>
                                        <td>
                                          {item.status === "delivered" ? (
                                            <>
                                              {" "}
                                              <span className="badge btn-success">
                                                {" "}
                                                {item.status}{" "}
                                              </span>{" "}
                                              <FontAwesomeIcon
                                                onClick={() =>
                                                  this.checkResponce(item.logid)
                                                }
                                                className="ml-2"
                                                role="button"
                                                color="green"
                                                icon={faExclamationCircle}
                                              />{" "}
                                            </>
                                          ) : item.status === "read" ? (
                                            <>
                                              {" "}
                                              <span className="badge bg-info">
                                                {" "}
                                                {item.status}{" "}
                                              </span>{" "}
                                              <FontAwesomeIcon
                                                onClick={() =>
                                                  this.checkResponce(item.logid)
                                                }
                                                className="ml-2"
                                                role="button"
                                                color="red"
                                                icon={faExclamationCircle}
                                              />{" "}
                                            </>
                                          ) : item.status === "failed" ? (
                                            <>
                                              {" "}
                                              <span
                                                data-tip
                                                data-for={"kannel_status" + i}
                                                style={{ cursor: "pointer" }}
                                                className="badge bg-danger"
                                              >
                                                {item.status}{" "}
                                                <FontAwesomeIcon
                                                  icon={faInfoCircle}
                                                  color="#77ff8e"
                                                />
                                              </span>{" "}
                                              <ReactTooltip
                                                html={true}
                                                id={"kannel_status" + i}
                                                place="bottom"
                                                effect="solid"
                                              >
                                                {item.kannel_status}
                                              </ReactTooltip>
                                              <FontAwesomeIcon
                                                onClick={() =>
                                                  this.checkResponce(item.logid)
                                                }
                                                className="ml-2"
                                                role="button"
                                                color="red"
                                                icon={faExclamationCircle}
                                              />
                                            </>
                                          ) : (
                                            <span className="badge bg-danger">
                                              {item.status}
                                            </span>
                                          )}

                                          <FontAwesomeIcon
                                            onClick={() =>
                                              this.checkDlrResponse(
                                                item.dlr_reponse, item.karix_status
                                              )
                                            }
                                            className="ml-2"
                                            role="button"
                                            color="orange"
                                            icon={faExclamationCircle}
                                          />
                                        </td>

                                        <td>{item.mobile}</td>
                                        <td>{item.sms_cost}</td>
                                        <td>{item.country_code}</td>
                                        <td>
                                          <span
                                            className="badge bg-info"
                                            data-tip
                                            data-event="click focus"
                                            data-for={"registerTip" + i}
                                            style={{
                                              cursor: "pointer",
                                              fontSize: 15,
                                            }}
                                          >
                                            {" "}
                                            <small
                                              style={{ fontSize: 10 + "px" }}
                                            >
                                              Message Show / Hide{" "}
                                            </small>{" "}
                                            <FontAwesomeIcon
                                              icon={faInfoCircle}
                                              color="yellow"
                                              size={5}
                                            />
                                          </span>
                                          <ReactTooltip
                                            globalEventOff="click"
                                            className="tooltip"
                                            html={true}
                                            clickable={true}
                                            onClick={() => {
                                              ReactTooltip.show(this.fooRef);
                                            }}
                                            id={"registerTip" + i}
                                            place="bottom"
                                            effect="solid"
                                          >
                                            {item.message}
                                          </ReactTooltip>
                                        </td>

                                        <td>{item.submitted_on}</td>
                                        <td>{item.delivered_on}</td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            ) : (
                              <div className="text-center">
                                <div className="card-body">
                                  <img
                                    alt="Empty Data"
                                    className="project-empty-img"
                                    src={EmptyImg}
                                  />
                                  <small>
                                    {this.state.responceMessage} in{" "}
                                    <strong> {this.state.channel} </strong>{" "}
                                    Between {this.state.dateFrom} to{" "}
                                    {this.state.dateTo}{" "}
                                  </small>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <DashboardFooter />
        </div>
      </div>
    );
  }
}

export default WhatsappDetailReports;
