import React from "react";
import DashboardLayout from "../../../UI/Layout/DashboardLayout";
import ChatLeftMenu from "./ChatLeftMenu";
import { FiPlus } from "react-icons/fi";
import { BsPencilSquare } from "react-icons/bs";

const ChatSetting = () => {
  return (
    <>
      <DashboardLayout>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="d-flex wFull">
              <div className="ChatLive_card col-md-2">
                <ChatLeftMenu />
              </div>
              <div className="col-md-10">
                <div className="ChatLive_card d-flex flex-column wFull px-3 py-3">
                  <div className="TitleBox clearfix">
                    <h3>
                      Shortcut{" "}
                      <a
                        href="/dashboard/shortcut"
                        class="btn btn-outline-success btn-sm float-right mb-3"
                      >
                        <FiPlus /> Add Shortcut
                      </a>
                    </h3>
                  </div>
                  <div className="row">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>
                            <input type="checkbox" />
                          </th>
                          <th>Access</th>
                          <th>Shortcut</th>
                          <th>Message</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <input type="checkbox" />
                          </td>
                          <td>Pulic</td>
                          <td>Lead Capture Form</td>
                          <td>
                            Sorry to keep you waiting, unfortunately all of our
                            agents are currently busy or away, please leave a
                          </td>
                          <td>
                            <a href="/dashboard/shortcut">
                              <div class="badge bg-primary mr-2 py-2">
                                <BsPencilSquare size={18} />
                              </div>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </DashboardLayout>
    </>
  );
};

export default ChatSetting;
