import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Title({ isButton, children, btnName, actionURL, Icons }) {
  return (
    <div className="row mb-1">
      <div className="col-auto d-none d-sm-block">
        <h3>
          <strong> {children} </strong>{" "}
        </h3>
      </div>
      {isButton && (
        <div className="col-auto ml-auto text-right mt-n1">
          <Link className="btn btn-primary btn-xs" to={actionURL}>
            {" "}
            <FontAwesomeIcon icon={Icons} color="" /> {btnName}{" "}
          </Link>
        </div>
      )}
    </div>
  );
}

export default Title;
