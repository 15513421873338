import React from "react";
import { Link } from "react-router-dom";
import OptOutNumber from "./compopents/cell/OptOutNumber";
import CopyMediaURL from "./compopents/cell/CopyMediaURL";
import Delete from "./compopents/cell/Delete";
import ApproveTemplate from "./compopents/cell/ApproveTemplate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUpload,
  faInfoCircle,
  faDownload,
  faEye,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import JSONPretty from "react-json-pretty";
import Optin from "./compopents/cell/OptIn";
import GetMobileNo from "./compopents/cell/GetMobileNo";
import { downloadContactList, HOST_URL, HOST_URL_DB2 } from "../../Dashboard/api/Api";
import dayjs from "dayjs";
import { userloginDetails } from "../../Utils/AdminAuth";

const replaceUrl = (url) => {
  var baseURL = document.location;
  return url.replace("https://console.authkey.io/", `${baseURL.origin}/`);
};

const ColumnConst = (props) => {
  return <></>;
};

// const WHATSAPP_ACCOUNT_LIST = [
//   {
//     name: "ID",
//     selector: (row) => row.id,
//     sortable: true,
//     width: "100px",
//   },
//   {
//     name: "Company Name",
//     selector: (row) => row.comp_name,
//     sortable: true,
//   },
//   {
//     name: "Account Status",
//     cell: (e) => (
//       <>
//         {" "}
//         {e.account_status === 1 ? (
//           <span className="badge bg-primary mr-2"> Green </span>
//         ) : e.account_status === 2 ? (
//           <span className="badge bg-info mr-2"> Yellow </span>
//         ) : e.account_status === 3 ? (
//           <span className="badge bg-danger mr-2"> Red </span>
//         ) : (
//           <span className="badge badge-info mr-2"> N/A </span>
//         )}{" "}
//       </>
//     ),
//   },
//   {
//     name: "FB Manager ID ",
//     selector: (row) => row.fb_businness_id,
//     sortable: true,
//   },
//   {
//     name: "WhatsApp Number",
//     cell: (e) => (
//       <>
//         {" "}
//         <strong> {e.brand_number} </strong>{" "}
//       </>
//     ),
//   },
//   {
//     name: "Daily Sending Limit",
//     selector: (row) => row.daily_sending_limit,
//     sortable: true,
//   },
//   {
//     name: "Daily Used",
//     selector: (row) => row.daily_used,
//     sortable: true,
//   },
//   {
//     name: "Status",
//     cell: (e) => (
//       <>
//         {" "}
//         {e.status === 0 ? (
//           <span className="badge bg-warning mr-2"> Pending </span>
//         ) : (
//           <span className="badge bg-primary mr-2"> Approved </span>
//         )}{" "}
//       </>
//     ),
//     sortable: true,
//   },
//   {
//     name: "Action",
//     cell: (e) => (
//       <>
//         {" "}
//         <Link to={`whatsapp/edit/${e.id}`}>
//           {" "}
//           <span className="badge bg-success mr-2"> Edit / Update </span>{" "}
//         </Link>{" "}
//       </>
//     ),
//     width: "200px",
//   },
// ];

const MEDIA_GALLERY = [
  {
    name: "id",
    selector: (row) => row.id,
    sortable: true,
    width: "80px",
  },
  {
    name: "Media Type",
    cell: (row) => <strong className="text-primary"> {row.doc_type}</strong>,
    selector: (row) => row.doc_type,
    sortable: true,
    width: "150px",
  },
  {
    name: "Media Name",
    selector: (row) => row.doc_name,
    sortable: true,
    width: "300px",
  },
  {
    name: "Media URL",
    cell: (row) => (
      <a href={`${replaceUrl(row.doc_url)}`}> {replaceUrl(row.doc_url)} </a>
    ),
    sortable: true,
  },
  {
    name: "Action",
    cell: (e) => <CopyMediaURL id={e.id} url={`${replaceUrl(e.doc_url)}`} />,
    width: "200px",
  },
];

const OPTIN_OPTOUT_WHATSAPP_LIST = [
  {
    name: "ID",
    selector: (row) => row.id,
    sortable: true,
  },
  {
    name: "OPTIN Mobile Number",
    selector: (row) => row.mobile,
    sortable: true,
  },
  {
    name: "Created Date / Time",
    selector: (row) => row.created,
    sortable: true,
  },

  {
    name: "Status",
    selector: (row) => <span className="badge bg-danger mr-2"> OPTOUT </span>,
  },

  {
    name: "Action",
    cell: (e) => (
      <>
        {" "}
        <OptOutNumber mobile={e.mobile} />{" "}
      </>
    ),
    width: "200px",
  },
];

const lStorage = JSON.parse(localStorage.getItem("login"));
const user_id = lStorage?.user?.userId;
const token = lStorage?.token;

const FreshReply = async (id,value) =>{
   let data = {
    token: token,
    user_id: user_id,
    method: "add_freshreply",
    id: id,
    value: value === 0 ? 1 : 0
   }
   console.log(data);
   try {
    let response = await fetch(`${HOST_URL}/whatsapp_template.php`,{
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    let result = await response.json();
    if(result.success){
      console.log("Successfull")
      window.location.reload();
    }
   } catch (error) {
    console.log(error);
   }
}

const WHATSAPP_TEMPLATE_LIST_CONST = [
  {
    name: "WID",
    selector: (row) => row.id,
    sortable: true,
    width: "80px",
  },
  {
    name: "Template Name",
    selector: (row) => (
      <button
        type="button"
        class="bg-transparent border-0"
        data-toggle="tooltip"
        data-placement="top"
        title={row.temp_name}
      >
        {row.temp_name}
      </button>
    ),
    sortable: true,
  },
  {
    name: "Category",
    selector: (row) => row.temp_category,
    sortable: true,
  },
  {
    name: "Template Type",
    cell: (row) => <span className="text-primary">{row.temp_type}</span>,
    sortable: true,
  },
  {
    name: "Dynamic Content",
    selector: (row) => (row.dynamic_content === 0 ? "No" : "Yes"),
    sortable: true,
    width: "200px",
  },
  {
    name: "Status",
    cell: (row) =>
      row.temp_status === 0 ? (
        <span className="badge bg-success">Pending</span>
      ) : row.temp_status === 1 ? (
        <span className="badge bg-primary">Approved</span>
      ) : row.temp_status === 2 ? (
        <span className="badge bg-info">Draft </span>
      ) : row.temp_status === 5 ? (
          <span className="badge bg-warning">In Review </span>
        ) : row.error_desc_creation === null ? (
          <button
            type="button"
            class="badge bg-danger border-0"
            data-toggle="tooltip"
            data-placement="top"
            title={row.facebook_response}
          >
            Rejected
          </button>
        ) : (
          <button
            type="button"
            class="badge bg-danger border-0"
            data-toggle="tooltip"
            data-placement="top"
            title={row.error_desc_creation}
          >
            Rejected
          </button>
        ),
    sortable: true,
  },
  {
    name: "Last update",
    selector: (row) => (
      <button
        type="button"
        class="bg-transparent border-0"
        data-toggle="tooltip"
        data-placement="top"
        title={row.created}
      >
        {row.created}
      </button>
    ),
    sortable: true,
  },
  {
    name: "Action",
    cell: (e) => (
      <>
        {e.temp_status === 1 ? (
          <Link to={`/dashboard/preview-whatsapp-template/${e.id}`}>
            <span className="badge bg-primary mr-2"> View </span>{" "}
          </Link>
        ) : e.temp_status === 2 ? (
          <>
            <Link to={`/dashboard/preview-whatsapp-template/${e.id}`}>
              <span className="badge bg-primary mr-2"> View </span>{" "}
            </Link>
            <Link to={`/dashboard/preview-whatsapp-template/${e.id}`}>
              <span className="badge bg-success mr-2"> Edit </span>{" "}
            </Link>
          </>
        ) : e.temp_status === 0 ? (
          <>
            <Link to={`/dashboard/preview-whatsapp-template/${e.id}`}>
              <span className="badge bg-primary mr-2"> View </span>{" "}
            </Link>
            <Link to={`/dashboard/preview-whatsapp-template/${e.id}`}>
              <span className="badge bg-success mr-2"> Edit </span>{" "}
            </Link>
          </>
        ) : (
          <>
            <Link to={`/dashboard/preview-whatsapp-template/${e.id}`}>
              <span className="badge bg-primary mr-2"> View </span>{" "}
            </Link>
            <Delete id={e.id} name={e.temp_name} />
          </>
        )}
      </>
    ),
    width: "200px",
  },
  {
    name: "Fresh Reply",
    selector: (row) => (
      row.fresh_reply === 0 ? <span className="badge bg-success" onClick={()=>FreshReply(row.id,row.fresh_reply)}>Add Fresh Reply</span> :
      <span className="badge bg-danger" onClick={()=>FreshReply(row.id,row.fresh_reply)}>Remove</span>
    ),
    sortable: true,
  },
];

const CAMPAIGN_CONTACT_LIST = [
  {
    name: "ID",
    selector: (row) => row.id,
    sortable: true,
    width: "100px",
  },
  {
    name: "Contact List Name / Description",
    cell: (e) => (
      <p className="text-left">
        {" "}
        {e.name} <br /> <small> {e.description} </small>{" "}
      </p>
    ),
    sortable: true,
    width: "400px",
  },
  {
    name: "Total Count",
    cell: (e) => (
      <span className="badge bg-success mr-2">{e.number_count}</span>
    ),
    sortable: true,
    width: "200px",
  },
  {
    name: "Status",
    cell: (e) => (
      <>
        {" "}
        {e.status === 0 ? (
          <span className="badge bg-warning mr-2"> Inactive </span>
        ) : (
          <span className="badge bg-primary mr-2"> Active </span>
        )}{" "}
      </>
    ),
    sortable: true,
  },
  {
    name: "Action",
    cell: (e) => (
      <>
        {" "}
        <Link to={`upload-contact-list/${e.id}`}>
          {" "}
          <span className="badge bg-success mr-2">
            {" "}
            <FontAwesomeIcon icon={faUpload} /> Add / Upload Contact List{" "}
          </span>{" "}
        </Link>{" "}
        <Link to={`view-contact-list/${e.id}`}>
          {" "}
          <span className="badge bg-primary mr-2">
            {" "}
            View Contact List{" "}
          </span>{" "}
        </Link>{" "}
        <span
          onClick={() => downloadContactList(e.id)}
          className="badge bg-success mr-2"
        >
          {" "}
          <FontAwesomeIcon icon={faDownload} /> Download{" "}
        </span>{" "}
      </>
    ),
    width : "400px",
  },
];

const CAMPAIGN_FILTER_CONTACT_LIST = [
  {
    name: "ID",
    selector: (row) => row.id,
    sortable: true,
    width: "100px",
  },
  {
    name: "List Name",
    selector: (row) => row.list_name,
    sortable: true,
  },
  {
    name: "mobile",
    selector: (row) => row.mobile,
    sortable: true,
    width: "200px",
  },
  {
    name: "Status",
    cell: (e) => (
      <>
        {" "}
        {e.status === 0 ? (
          <span className="badge bg-warning mr-2"> Inactive </span>
        ) : (
          <span className="badge bg-primary mr-2"> Active </span>
        )}{" "}
      </>
    ),
    sortable: true,
  },
  {
    name: "Created Date",
    selector: (row) => row.created,
    sortable: true,
  },
];


const processContent = (text) => {
  return text.split(',').map(part => `<div>${part.trim()}</div>`).join('');
};

const CAMPAIGN_REPORT_SMS_TABLE = [
  {
    name: "MSISDN",
    selector: (row) => <span style={{fontSize:"12px"}}>{row.mobile}</span>,
    sortable: true,
    width: "115px",
  },
  {
    name: <span className="ml-9">Content</span>,
    selector: (row) => (
      <div className="break-after-comma" style={{fontSize:"10px"}} dangerouslySetInnerHTML={{ __html: processContent(row.content) }} />
    ),
    sortable: true,
    width: "365px",
  },
  {
    name: "Submitted on",
    selector: (row) => <span style={{fontSize:"12px"}}>{row.sent_time}</span>,
    sortable: true,
    width: "150px",
  },
  {
    name: "Delivered on",
    selector: (row) => <span style={{fontSize:"12px"}}>{row.deliver_time}</span>,
    sortable: true,
    width: "150px",
  },
  {
    name: "Status",
    selector: (row) => <span style={{fontSize:"12px"}}>{row.status}</span>,
    sortable: true,
    width: "90px",
  },
  {
    name: "Count",
    selector: (row) => row.msg_count,
    sortable: true,
  },
  {
    name: "Country",
    selector: (row) => <span style={{fontSize:"12px"}}>{row.countryname}</span>,
    sortable: true,
    width: "100px",
  },
  {
    name: "Error",
    selector: (row) => <span style={{fontSize:"12px"}}>{row.api_status}</span>,
    sortable: true,
    width: "100px",
  },
];


const CAMPAIGN_REPORT_WHATSAPP_TABLE = [
  {
    name: 'Mobile Number',
    selector: row => row.mobile,
    sortable: true,
    width: '200px',
},

{
    name: 'Status',
    cell: (e) => <>  {<strong> {e.status === 'failed' ? <span className='text-danger'>{e.status}</span> : e.status === 'delivered' ? <span className='text-success'>{e.status}</span> : <span className='text-primary'>{e.status}</span>}  </strong>} </>,
    sortable: true,
},
{
    name: 'DLR Reponse',
    cell: (e) => <>  {<JSONPretty id="json-pretty" data={e.dlr_reponse}></JSONPretty>}
    </>,
},
{
    name: 'Parameters',
    cell: (e) => <>  {<JSONPretty id="json-pretty" data={e.parameter}></JSONPretty>}
    </>,
},
];

const CAMPAIGN_REPORT_CTA_TABLE = [
  {
    name: 'Phone Number',
    selector: row => row.phone_number,
    sortable: true,
  },
  {
    name: 'Url Click Count',
    selector: row => <span style={{marginLeft:"30px"}}>{row.url_clickcount}</span>,
    sortable: true,
  },
  {
    name: 'IP',
    selector: row => <span>{row.ip}</span>,
    sortable: true,
  },
  {
    name: 'City',
    selector: row => <span>{row.url_city}</span>,
    sortable: true,
  },
  {
    name: 'Created Time',
    selector: row => <span>{row.created}</span>,
    sortable: true,
  },
];

const lUserStorage = userloginDetails();
const userId = lUserStorage?.user?.userId;
const userToken = lUserStorage?.token;
const adminType = lUserStorage?.user?.isAdmin||null

const ADMIN_CAMPAIGN_DATA = [
  {
    name: "CID",
    selector: (row) => (
      <>
        {" "}
        {row.id}
        <div>
          <a
            href={`${HOST_URL}/bulk_campaign.php?user_id=${row.user_id}&method=download&campaign_id=${row.id}&channel=${row.channel}&token=${row.user_token}`}
            className="text-success"
          >
            {/* <Link className="text-success"> */}{" "}
            <FontAwesomeIcon icon={faDownload} /> {/* </Link>{" "} */}
          </a>
        </div>
      </>
    ),
    sortable: true,
    width: "80px",
  },
  {
    name: "Template ID",
    selector: (row) => row.template_id,
    sortable: true,
    width: "125px",
  },
  {
    name: "User Name",
    selector: (row) => row.user_name,
    sortable: true,
  },
  {
    name: "Total Data",
    selector: (row) => row.contact_count,
    sortable: true,
    width: "114px",
  },
  {
    name: "Data",
    cell: (row) => (
      <div>
        <div className="d-flex justify-content-start">
          Deliverd: {row.delivered}
        </div>
        <div className="d-flex justify-content-start">Failed: {row.failed}</div>
        <div className="d-flex justify-content-start">Read: {row.read}</div>
        <div className="d-flex justify-content-start">Sent: {row.sent}</div>
      </div>
    ),
    sortable: true,
    width: "150px",
  },
  {
    name: "Date & Time",
    selector: (row) => row.camp_start_datetime,
    sortable: true,
  },
  {
    name: "Cost",
    cell: (row) => <div>{parseFloat(row.campaign_cost).toFixed(2)}</div>,
    sortable: true,
    width: "100px",
  },
  // {
  //   name: "Status",
  //   cell: (row) =>
  //     row.status === 1 ? (
  //       <span className="badge bg-info">Submitted</span>
  //     ) : row.status === 2 ? (
  //       <span className="badge bg-info">Schedule</span>
  //     ) : row.status === 3 ? (
  //       <span className="badge bg-success">Processing</span>
  //     ) : row.status === 5 ? (
  //       <span className="badge bg-warning">Schedule</span>
  //     ) : (
  //       <span className="badge bg-primary ">Completed</span>
  //     ),
  //   sortable: true,
  // },
];

const ADMIN_SMS_CAMPAIGN_DATA = [
  {
    name: "Campaign Details",
    selector: (row) => (
      <>
        {" "}
       <div className="d-flex justify-content-start">CampaignID : {row.id}</div>
       <div className="d-flex justify-content-start">SenderID : {row.sender_id}</div>
       <div className="d-flex justify-content-start">Camp_name : {row.campaign_name}</div>
       <div className="d-flex justify-content-start">TID : {row.template_id}</div>       
        <div>
          <a
            // href={`${HOST_URL_DB2}/bulk_campaign.php?user_id=${row.user_id}&method=download&campaign_id=${row.id}&channel=sms&token=${userToken}`}
            href = {`${HOST_URL_DB2}/bulk_campaign_sms.php?user_id=${userId}&ret_user_id=${row.user_id}&method=download&token=${userToken}&channel=sms&campaign_id=${row.id}&archive_status=${row.archive_status}&camp_start_datetime=${row.camp_start_datetime}&type=${adminType}&username=${row.user_name}`}
            className="text-success"
          >
            {/* <Link className="text-success"> */}{" "}
            <FontAwesomeIcon icon={faDownload} /> {/* </Link>{" "} */}
          </a>
        </div>
      </>
    ),
    sortable: true,
    width: "200px",
  },
  {
    name: "User Name",
    selector: (row) => row.user_name,
    sortable: true,
  },
  {
    name: "Total Data",
    selector: (row) => row.contact_count,
    sortable: true,
    width: "114px",
  },
  {
    name: "Data",
    cell: (row) => (
      <div>
        <div className="d-flex justify-content-start">
          Deliverd: {row.delivered}
        </div>
        <div className="d-flex justify-content-start">Failed: {row.failed}</div>
        <div className="d-flex justify-content-start">Optout: {row.optout}</div>
        <div className="d-flex justify-content-start">Dnd: {row.dnd}</div>
      </div>
    ),
    sortable: true,
    width: "150px",
  },
  {
    name: "Date & Time",
    selector: (row) => row.camp_start_datetime,
    sortable: true,
  },
  {
    name: "Cost",
    cell: (row) => <div>{parseFloat(row.campaign_cost).toFixed(2)}</div>,
    sortable: true,
    width: "100px",
  },
];

const ADMIN_BILLING_SUMMARY = [
  // {
  //   name: "User ID",
  //   selector: (row) => row.user_id,
  //   sortable: true,
  //   width:"90px"
  // },
  {
    name: "Date",
    selector: (row) => dayjs(row.submission_date).format("DD/MM/YYYY h:mm A"),
    sortable: true,
  },
  {
    name: "User Name",
    selector: (row) => row.email,
    sortable: true,
    width: "250px",
  },
  {
    name: "Total",
    selector: (row) => row.total_count,
    sortable: true,
    width: "80px",
  },
  {
    name: "Delivered",
    selector: (row) => row.delivered_count,
    sortable: true,
    width: "110px",
  },
  {
    name: "Failed",
    selector: (row) => row.failed_count,
    sortable: true,
    width: "90px",
  },
  {
    name: "Other",
    selector: (row) => row.other_count,
    sortable: true,
    width: "85px",
  },
  {
    name: "Country Code ",
    selector: (row) => row.country_code,
    sortable: true,
    width: "135px",
  },
];

const ADMIN_WHATSAPP_SETUP = [
  // {
  //   name: "User ID",
  //   selector: (row) => row.user_id,
  //   sortable: true,
  //   width:"90px"
  // },
  {
    name: "User Name",
    selector: (row) => row.user_name,
    sortable: true,
   
  },
  {
    name: "Token/ Remark",
    cell: (row) => (
      <p
        className="p-2"
        style={{
          
          height: "80px",
          overflowY: "auto",
          border: "1px solid black",
          fontSize: "10px",
          scrollbarWidth: "thin",
        }}
      >
        {row.access_token}
      </p>
    ),
    sortable: true,
  },

  {
    name: "Date",
    selector: (row) => dayjs(row.modified).format("DD/MM/YYYY h:mm A"),
    sortable: true,
  },
  {
    name: "Action",
    cell: (row) => (
      <Link
        to={`/admin/whatsapp-setup/details/${row.user_id}`}
      >
        <span className="badge bg-success">
          <FontAwesomeIcon icon={faEye} /> View Details{" "}
        </span>
      </Link>
    ),
    sortable: true,
  },
];

const ADMIN_PINNACLE_SIGNUP = [
  {
    name: "User Name",
    selector: (row) => row.user_name,
    sortable: true,
   
  },
  {
    name: "Waba ID",
    selector: (row) => row.waba_id,
    sortable: true,
   
  },
  {
    name: "Phone Number ID",
    selector: (row) => row.phone_num_id,
    sortable: true,
   
  },
  {
    name: "Remark",
    selector: (row) => row.remark,
    sortable: true,
     width: "150px"
  },
  {
    name: "Status",
    selector: (row) => row.status === 1 ? <span style={{color:"green"}}>Registered</span> : <span style={{color:"red"}}>Not Registered</span>,
    sortable: true,
   
  },
  {
    name: "Date",
    selector: (row) => dayjs(row.modified).format("DD/MM/YYYY h:mm A"),
    sortable: true,
  },
  {
    name: "Action",
    cell: (row) => (
      <Link
        to={`/admin/pinnacle-embeded-signup/register/${row.id}/${row.waba_id}/${row.phone_num_id}`}
      >
        <span className="badge bg-success">
           Register{" "}
        </span>
      </Link>
    ),
    sortable: true,
  },
];

const handleCopy = (row) => {
  if (row.access_token_permanent) {
    navigator.clipboard.writeText(row.access_token_permanent)
      .then(() => {
        console.log('Text copied to clipboard');
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  }
};

const ADMIN_PINNACLE_TOKEN = [
  {
    name: "User Name",
    selector: (row) => row.user_name,
    sortable: true,
   
  },
  {
    name: "Permanent Token",
    cell: (row) => (
      <>
      {row.access_token_permanent ? 
      <>
      <p
      className="p-2"
      style={{
        
        height: "80px",
        overflowY: "auto",
        border: "1px solid black",
        fontSize: "10px",
        scrollbarWidth: "thin",
      }}
    >
       <FontAwesomeIcon onClick={()=>handleCopy(row)} icon={faCopy} style={{ cursor: 'pointer', float:"right"}}/>
      <span style={{padding:"20px 20px 20px 20px"}}>{row.access_token_permanent}</span>
    </p> 
    </>
      : null}
      </>
    ),
    sortable: true,
  },
  {
    name: "Temporary Token",
    cell: (row) => (
      <>
      {row.access_token_temp ? 
      <p
      className="p-2"
      style={{
        
        height: "80px",
        overflowY: "auto",
        border: "1px solid black",
        fontSize: "10px",
        scrollbarWidth: "thin",
      }}
    >
      {row.access_token_temp}
    </p> : null}      
      </>
    ),
    sortable: true,
  },

  {
    name: "Date",
    selector: (row) => dayjs(row.modified).format("DD/MM/YYYY h:mm A"),
    sortable: true,
  },
  // {
  //   name: "Action",
  //   cell: (row) => (
  //     <Link
  //       to={`/admin/whatsapp-setup/details/${row.user_id}`}
  //     >
  //       <span className="badge bg-success">
  //         <FontAwesomeIcon icon={faEye} /> View Details{" "}
  //       </span>
  //     </Link>
  //   ),
  //   sortable: true,
  // },
];


const CAMPAIGN_CONTACT_DATA = [
  {
    name: "ID",
    selector: (row) => row.id,
    sortable: true,
  },
  {
    name: "List Name",
    selector: (row) => row.list_name,
    sortable: true,
  },
  {
    name: "Mobile",
    selector: (row) => row.mobile,
    sortable: true,
  },
  {
    name: "Status",
    cell: (e) => (
      <>
        {" "}
        {e.status === 0 ? (
          <span className="badge bg-warning mr-2"> Inactive </span>
        ) : (
          <span className="badge bg-primary mr-2"> Active </span>
        )}{" "}
      </>
    ),
    sortable: true,
  },
  {
    name: "Other Column",
    cell: (e) => (
      <>
        <span
          data-tip
          data-for={"kannel_status" + e.id}
          style={{ cursor: "pointer" }}
          className="badge bg-danger"
        >
          <FontAwesomeIcon icon={faInfoCircle} color="#77ff8e" /> Column Name
        </span>
        <ReactTooltip
          id={"kannel_status" + e.id}
          globalEventOff="click"
          className="tooltip"
          clickable={true}
          onClick={() => {
            ReactTooltip.show(this.fooRef);
          }}
          effect="solid"
        >
          <span className="text-info"> col_A </span> : {e.col_A}
          <span className="text-info"> col_B </span> : {e.col_B}
          <span className="text-info"> col_C </span> : {e.col_C}
          <span className="text-info"> col_D </span> : {e.col_D}
          <span className="text-info"> col_E </span> : {e.col_E}
          <span className="text-info"> col_F </span> : {e.col_F}
          <span className="text-info"> col_G </span> : {e.col_G}
          <span className="text-info"> col_H </span> : {e.col_H}
          <span className="text-info"> col_I </span> : {e.col_I}
          <span className="text-info"> col_J </span> : {e.col_J}
        </ReactTooltip>
      </>
    ),
    sortable: true,
  },
  {
    name: "Date Time",
    selector: (row) => row.created,
    sortable: true,
  },
];

const WHATSAPP_CAMPAIGN_LIST_CONST = [
  {
    name: "ID",
    selector: (row) => row.id,
    sortable: true,
    width: "80px",
  },
  {
    name: "Name",
    selector: (row) => row.campaign_name,
    sortable: true,
  },
  {
    name: "Template ID",
    selector: (row) => row.template_id,
    sortable: true,
  },
  {
    name: "Data",
    cell: (row) => (
      <div>
        <div>Total: {row.contact_count}</div>
        <div>Deliverd: {row.delivered}</div>
        <div>Failed: {row.failed}</div>
        <div>Read: {row.read}</div>
        <div>Sent: {row.sent}</div>
        <div>
          <a
            href={`${HOST_URL}/bulk_campaign.php?user_id=${row.user_id}&method=download&campaign_id=${row.id}&channel=${row.channel}&token=${row.user_token}`}
            className="text-success"
          >
            {/* <Link className="text-success"> */}{" "}
            <FontAwesomeIcon icon={faDownload} /> {/* </Link>{" "} */}
          </a>
        </div>
      </div>
    ),
    sortable: true,
  },
  {
    name: "Start On",
    selector: (row) => row.camp_start_datetime,
    sortable: true,
  },
  {
    name: "Status",
    cell: (row) =>
      row.status === 1 ? (
        <span className="badge bg-info">Submitted</span>
      ) : row.status === 2 ? (
        <span className="badge bg-info">Schedule</span>
      ) : row.status === 3 ? (
        <span className="badge bg-success">Processing</span>
      ) : row.status === 5 ? (
        <span className="badge bg-warning">Schedule</span>
      ) : (
        <span className="badge bg-primary ">Completed</span>
      ),
    sortable: true,
  },
  {
    name: "Action",
    cell: (row) => (
      <Link
        to={`/dashboard/campaign/detailed-report/${row.channel}/${row.id}/${row.campaign_name}`}
      >
        <span className="badge bg-success">
          <FontAwesomeIcon icon={faEye} /> Report{" "}
        </span>
      </Link>
    ),
    sortable: true,
  },
];

// Admin Column Cost

const ADMIN_WHATSAPP_ACCOUNT_LIST = [
  {
    name: "ID",
    selector: (row) => row.id,
    sortable: true,
    width: "100px",
  },
  {
    name: "Action",
    cell: (e) => (
      <>
        <Link to={`/admin/whatsapp-account-profile-details/${e.user_id}`}>
          {" "}
          <span className="badge bg-success mr-2"> Update Details </span>{" "}
        </Link>
      </>
    ),
  },
  {
    name: "Email / Userid",
    selector: (row) => row.email,
    sortable: true,
  },
  {
    name: "Whatsapp Account Name",
    selector: (row) => row.comp_name,
    sortable: true,
  },
  {
    name: "WhatsApp Number",
    selector: (row) => row.brand_number,
    sortable: true,
  },
  // {
  //     name: 'Waba Id',
  //     selector: row => row.waba_id,
  //     sortable: true,
  // },
  {
    name: "Daily Sending limit",
    selector: (row) => row.daily_sending_limit,
    sortable: true,
  },
  {
    name: "Route Name",
    selector: (row) => row.route_name,
    sortable: true,
  },
  {
    name: "Status",
    cell: (e) => (
      <>
        {" "}
        {e.status === 0 ? (
          <span className="badge bg-warning mr-2"> Pending </span>
        ) : e.status === 1 ? (
          <span className="badge bg-primary mr-2"> Approved </span>
        ) : (
          <span className="badge bg-secondary mr-2"> Processed </span>
        )}{" "}
      </>
    ),
    sortable: true,
  },
];

// Embeded Signup list

const EMBEDED_SIGNUP_LIST = [
  {
    name: "Waba ID",
    selector: (row) => row.id,
    sortable: true,
  },
  {
    name: "Whatsapp Account Name",
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: "Message Template Namespace",
    selector: (row) => row.message_template_namespace,
    sortable: true,
  },
  {
    name: "Timezone Id",
    selector: (row) => row.timezone_id,
    sortable: true,
    width: "140px",
  },
  {
    name: "Action",
    cell: (e) => (
      <>
        <GetMobileNo id={e.id} />
      </>
    ),
    width: "200px",
  },
];

// Admin Column Whatsapp Api

const ADMIN_WHATSAPP_API_LIST = [
  {
    name: "Username",
    selector: (row) => row.username,
    sortable: false,
    width: "260px",
  },
  {
    name: "Cost",
    selector: (row) => parseInt(row.cost) || 0,

    sortable: true,
  },
  {
    name: "Delivered",
    selector: (row) => parseInt(row.delivered) || 0,

    sortable: true,
  },
  {
    name: "Failed",
    selector: (row) => parseInt(row.failed) || 0,

    sortable: true,
  },
  {
    name: "Not sent",
    selector: (row) => parseInt(row.notsent) || 0,
    // cell: (e) => <>  {e.notsent === "" ? e.notsent : "0"} </>,
    sortable: true,
  },
  {
    name: "Optout",
    selector: (row) => parseInt(row.optout) || 0,
    sortable: true,
  },
  {
    name: "Read",
    selector: (row) => parseInt(row.read) || 0,
    sortable: true,
  },
  {
    name: "Sent",
    selector: (row) => parseInt(row.sent) || 0,
    sortable: true,
  },
  {
    name: "Submitted",
    selector: (row) => parseInt(row.Submitted) || 0,
    sortable: true,
  },
];

const WHATSAPP_TEMPLATE_LIST_ADMIN = [
  {
    name: "WID",
    selector: (row) => row.id,
    sortable: true,
    width: "80px",
  },
  {
    name: "Template Name",
    selector: (row) => row.temp_name,
    sortable: true,
  },
  {
    name: "Category",
    selector: (row) => row.temp_category,
    sortable: true,
  },
  {
    name: "Template Type",
    cell: (row) => <span className="text-primary">{row.temp_type}</span>,
    sortable: true,
  },
  {
    name: "Dynamic",
    selector: (row) => (row.dynamic_content === 0 ? "No" : "Yes"),
    sortable: true,
    width: "100px",
  },

  {
    name: "Status",
    cell: (row) =>
      row.temp_status === 0 ? (
        <span className="badge bg-success">Pending</span>
      ) : row.temp_status === 1 ? (
        <span className="badge bg-primary">Approved</span>
      ) : row.temp_status === 2 ? (
        <span className="badge bg-info">Draft </span>
      ) : row.temp_status === 3 ? (
        <span className="badge bg-danger"> Rejected </span>
      ) : (
        <span className="badge bg-success"> Processing </span>
      ),
    sortable: true,
  },

  {
    name: "Last update",
    selector: (row) => row.created,
    sortable: true,
  },
  {
    name: "Action",
    cell: (e) => (
      <ApproveTemplate
        templateid={e.id}
        dynamicContent={e.dynamic_content}
        status={e.temp_status}
        user_id={e.user_id}
      />
    ),
    width: "200px",
  },
];

const ADMIN_CONTACT_US = [
  {
    name: "Name",
    selector: (row) => row.name,
    sortable: true,
    width: "200px",
  },
  {
    name: "Email",
    selector: (row) => row.email,
    sortable: true,
  },
  {
    name: "mobile",
    selector: (row) => row.contact,
    sortable: true,
  },
  {
    name: "Message",
    cell: (e) => <> {<span title={e.message}> {e.message} </span>} </>,
  },

  {
    name: "Other",
    cell: (e) => (
      <>
        {" "}
        {
          <span className="text-left">
            {" "}
            Category : <b>{e.category} </b> <br /> Company : <b>{e.company}</b>{" "}
            <br /> Website : <b>{e.website}</b> <br /> Designation :{" "}
            <b>{e.desg}</b>{" "}
          </span>
        }{" "}
      </>
    ),
  },

  {
    name: "Created Date",
    selector: (row) => row.created,
    sortable: true,
  },
];

const AGENT_LIST = [
  {
    name: "WID",
    selector: (row) => row.id,
    sortable: true,
    width: "80px",
  },
  {
    name: "Name",
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: "Email",
    selector: (row) => row.email,
    sortable: true,
  },
  {
    name: "Mobile",
    selector: (row) => row.mobile,
    sortable: true,
  },
  {
    name: "Status",
    cell: (row) =>
      row.is_active === 0 ? (
        <span className="badge bg-success">Inactive</span>
      ) : (
        <span className="badge bg-primary">Active</span>
      ),
    sortable: true,
  },

  {
    name: "Last update",
    selector: (row) => row.created,
    sortable: true,
  },
  {
    name: "Action",
    cell: (e) => (
      <>
        {" "}
        {e.temp_status === 1 ? (
          ""
        ) : (
          <Link to={`/dashboard/agent-edit-update/${e.id}`}>
            {" "}
            <span className="badge bg-success mr-2"> Edit / Update </span>{" "}
          </Link>
        )}
        <span className="badge bg-danger mr-2"> Delete </span>
      </>
    ),
    width: "200px",
  },
];

const OPTOUTLIST = [
  {
    name: "OPT OUT Number",
    selector: (row) => row.mobile,
    sortable: true,
    width: "200px",
  },
  {
    name: "Created",
    selector: (e) => e.created,
    sortable: true,
  },
  {
    name: "Action",
    cell: (e) => (
      <>
        {" "}
        <Optin mobile={e.mobile} />{" "}
      </>
    ),
  },
];

export {
  MEDIA_GALLERY,
  // WHATSAPP_ACCOUNT_LIST,
  OPTOUTLIST,
  OPTIN_OPTOUT_WHATSAPP_LIST,
  WHATSAPP_TEMPLATE_LIST_CONST,
  CAMPAIGN_CONTACT_LIST,
  CAMPAIGN_REPORT_SMS_TABLE,
  CAMPAIGN_REPORT_WHATSAPP_TABLE,
  CAMPAIGN_REPORT_CTA_TABLE,
  ADMIN_WHATSAPP_ACCOUNT_LIST,
  WHATSAPP_TEMPLATE_LIST_ADMIN,
  CAMPAIGN_CONTACT_DATA,
  CAMPAIGN_FILTER_CONTACT_LIST,
  ADMIN_CONTACT_US,
  AGENT_LIST,
  ADMIN_WHATSAPP_API_LIST,
  EMBEDED_SIGNUP_LIST,
  WHATSAPP_CAMPAIGN_LIST_CONST,
  ADMIN_CAMPAIGN_DATA,
  ADMIN_SMS_CAMPAIGN_DATA,
  ADMIN_BILLING_SUMMARY,
  ADMIN_WHATSAPP_SETUP,
  ADMIN_PINNACLE_SIGNUP,
  ADMIN_PINNACLE_TOKEN,
};

export default ColumnConst;
