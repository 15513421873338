import React from "react";
import { Link } from "react-router-dom";
import DashboardHeader from "../../Header/DashboardHeader";
import DashboardFooter from "../../Footer/DashboardFooter";
import DashboardLeftMenu from "../DashboardLeftMenu";
import whatsapp from "../../Dashboard/assets/img/whstaApp-conversations.png";
import sms from "../../Dashboard/assets/img/sms-icon.png";
import voice from "../../Dashboard/assets/img/voice-icon.png";
import email from "../../Dashboard/assets/img/email-icon.png";
const DashboardAPi = () => {
  return (
    <div className="wrapper">
      <DashboardLeftMenu />
      <div className="main">
        <DashboardHeader />
        <main className="content">
          <div className="container-fluid p-0">
            <div className=" row mb-2 mb-xl-3">
              <div className="col-auto d-none d-sm-block">
                <h3>
                  <strong>API Docs</strong>
                </h3>
              </div>
            </div>
            <div className="">
              <div className="card">
                <div className="card-body">
                  <div className="text-center">
                    <h5 className="">
                      Welcome to Authkey! Get familiar with our product and
                      explore its features:
                    </h5>

                    <p className="lead text-muted lh-190">
                      Authkey APIs are fast, AI-Driven and supports message
                      deliveries through multiple channels in the same request.
                      You can choose multiple channel deliveries in parallel or
                      fallback as per your requirement. You also have options to
                      create templates with dynamic contents and also club
                      multiple templates into events to be triggered via a
                      single API request.
                    </p>

                    <h6 className="my-4 text-bold">
                      Channels supported by Authkey:
                    </h6>

                    <div className="row mb-5">
                      <div className="col-sm-3">
                        <div className="card rounded-lg mb-sm-0 channelsms cardApiH">
                          <div className="p-3 text-center">
                            <img
                              src={sms}
                              alt="api"
                              className="img-fluid apicons"
                            />
                            <h5 className="mb-2 mt-2">SMS</h5>
                            <p className="text-sm mt-2 mb-0">
                              Transactional SMS can be triggered either by
                              directly passing the sms message in the API
                              request or you can also create a template with
                              dynamic values and use the template identifier in
                              the API request.
                            </p>
                            <Link
                              className="btn btn-sm btn-primary mt-3"
                              to="/dashboard/sms-api-docs"
                            >
                              Read More
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-3">
                        <div className="card rounded-lg mb-sm-0 cardApiH">
                          <div className="p-3 text-center">
                            <img
                              src={voice}
                              alt="api voice"
                              className="img-fluid apicons"
                            />
                            <h5 className="mb-2 mt-2"> Voice </h5>
                            <p className=" mt-2 mb-0 text-sm">
                              Voice call can be placed from Authkey by passing
                              the voice message in text in the API request. The
                              platform will auto-convert the text into audio and
                              place the call. Use it in the API to create voice
                              template.
                            </p>
                            <Link
                              className="btn btn-sm btn-primary mt-3"
                              to="/dashboard/voice-api-docs"
                            >
                              Read More
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-3">
                        <div className="card rounded-lg mb-sm-0 cardApiH">
                          <div className="p-2 py-3 text-center">
                            <img
                              src={email}
                              alt="email"
                              className="img-fluid apicons"
                            />
                            <h5 className="mb-2 mt-2">Email</h5>
                            <p className=" mt-2 mb-0 text-sm">
                              Create Email templates on Authkey and use the
                              created template id in the API request to send
                              email. Authkey uses AWS SES to send emails, so you
                              need to have an account in AWS to use this
                              feature.
                            </p>
                            <Link
                              className="btn btn-sm btn-primary mt-3"
                              to="/dashboard/email-api-docs"
                            >
                              Read More
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-3">
                        <div className="card rounded-lg mb-sm-0 cardApiH">
                          <div className="p-2 py-3 text-center">
                            <img
                              src={whatsapp}
                              alt="whatsapp"
                              className="img-fluid apicons"
                            />
                            <h5 className="mt-2 mb-2">WhatsApp</h5>
                            <p className=" mt-2 mb-0 text-sm">
                              Whatsapp API is a communication connection to
                              enable a marketing lineup to customers or
                              prospects. Whatsapp business web was launched in
                              Aug '18 for marketers to scale their engagements.
                            </p>
                            <Link
                              className="btn btn-sm btn-primary mt-3"
                              to="/dashboard/whatsapp-api-docs"
                            >
                              Read More
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <DashboardFooter />
      </div>
    </div>
  );
};

export default DashboardAPi;
