import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../UI/Layout/DashboardLayout";
// import { IoIosStar } from "react-icons/io";
// import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Axios from "axios";
import { NODE_HOST_URL } from "../../api/Api";
import Spining from "../../../UI/Spining/Spining";
import Alert from "../../../UI/Alert/Alert";
import { useParams, useLocation } from "react-router-dom";
const AgentReport = () => {
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lStorage = JSON.parse(localStorage.getItem("login"));
  const user_id = lStorage.user.userId;
  const token = lStorage.token;
  const brand_number = localStorage.getItem("wp-cmpy-brand_number");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [agentData, setAgentData] = useState([]);
  const [sum, setSum] = useState({
    total_num:0,
    total_reapeat_num:0,
    total_reply_num:0,
  })
  const [error, setError] = useState({
    successMessage: false,
    bgColor: "alert-danger",
    errorMessage: "",
  });
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
   
    if (start && end) {
      setCalendarOpen(false);
    }
  };

  const handleInputClick = () => {
    setCalendarOpen(true);
  };
  const formatDate = (date) => {
    const options = { timeZone: 'Asia/Kolkata', year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = new Date(date).toLocaleString('en-IN', options);
    const [day, month, year] = formattedDate.split('/');
    return `${year}-${month}-${day}`;
  };
  const getPlaceholderText = () => {
   
    if (startDate && endDate) {
      return `${formatDate(startDate)} / ${formatDate(endDate)}`;
    } else if (startDate) {
      return `${formatDate(startDate)} / End date`;
    }
    return "Select a date range";
  };
  useEffect(() => {
    const todayDate = new Date();
    const yesterdayDate = new Date(todayDate);
    yesterdayDate.setDate(todayDate.getDate() - 1);
    const formattedDate = formatDate(yesterdayDate)
    agentReport(formattedDate, formattedDate);
  }, []);
  const agentReport = async (startDate, endDate) => {
    setLoading(true);
    const dataForAgentReport = {
      user_id,
      token,
      method: "agentwise_report",
      brand_number,
      user_type: "admin",
      agent_id: id,
      from_date: formatDate(startDate),
      to_date: formatDate(endDate),
    };

    try {
      const { data } = await Axios.post(
        `${NODE_HOST_URL}/whatsapp_report`,
        dataForAgentReport
      );
      if (data.success === true) {
        setAgentData(data.data);
        let total_num=0,total_reapeat_num=0, total_reply_num=0
        data.data.forEach(element => {
          total_num=total_num+element.totalnumber;
          total_reapeat_num= total_reapeat_num+element.totalrepeatnumber;
          total_reply_num = total_reply_num+element.replynumcount;
        });
        setSum(
          {
            total_num,
            total_reapeat_num,
            total_reply_num,
          }
        )
        
      }
    } catch (error) {
      setError({
        successMessage: true,
        bgColor: "alert-danger",
        errorMessage: error.message,
      });
    }
    setLoading(false);
  };

  return (
    <>
      <DashboardLayout>
        {loading && <Spining />}
        <main className="content">
          {error.successMessage && (
            <Alert type={error.bgColor}>{error.errorMessage}</Alert>
          )}
          <div className="container-fluid p-0">
            <h4 className="px-3">Agent Chat Report</h4>
            <div className="d-flex wFull">
              <div className="col-md-12">
                <div className="ChatLive_card d-flex flex-column wFull px-3 py-3">
                  <div className="TitleBox">
                    <div className="row">
                      <div className="col-md-2">
                        <h6>Agent Id : {id}</h6>
                      </div>
                      <div className="col-md-5">
                        <h6>Agent Name : {queryParams.get("name")}</h6>
                      </div>
                      <div className="col-md-5">
                        <div className="d-flex">
                          <DatePicker
                            onChange={handleDateChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            open={calendarOpen}
                            onClickOutside={() => setCalendarOpen(false)}
                            onInputClick={handleInputClick}
                            className="form-control"
                            dateFormat="YYYY-MM-DD"
                            placeholderText={getPlaceholderText()}
                            isClearable
                          />
                          <button
                            className="btn btn-success ml-2"
                            onClick={() => agentReport(startDate, endDate)}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                    <hr></hr>
                    <div className="row">
                      <div className="col-md-3"><h6>Total Chat: {sum.total_num}</h6></div>
                      <div className="col-md-3"><h6>Total New Chat: {sum.total_num-sum.total_reapeat_num}</h6></div>
                      <div className="col-md-3"><h6>Total Reapeat Chat: {sum.total_reapeat_num}</h6></div>
                      <div className="col-md-3"><h6>Total Missed Chat: {sum.total_num-sum.total_reply_num}</h6></div>
                    </div>
                  </div>
                  <div className="row">
                  <div className="tableAutoScroll">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Total Chat</th>
                          <th>New Chat</th>
                          <th>Repeat Chat</th>
                          <th>Missed chat</th>
                        </tr>
                      </thead>
                      <tbody>
                        {agentData.map((data, i) => (
                          <tr key={i}>
                            <td>{data.date}</td>
                            <td>{data.totalnumber}</td>
                            <td>{data.totalnumber - data.totalrepeatnumber}</td>
                            <td>{data.totalrepeatnumber}</td>
                            <td>{data.totalnumber - data.replynumcount}</td>
                            {/* <td>
                           {data.waitingtime}
                          </td> */}
                            {/* <td>
                            <Link to="/">10 Min</Link>
                          </td>
                          <td>
                            <Link to="/">20 Min</Link>
                          </td>
                          <td>
                            <span className="pinkColor">
                              <IoIosStar />
                              <IoIosStar />
                              <IoIosStar />
                              <IoIosStar />
                            </span>
                          </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </DashboardLayout>
    </>
  );
};

export default AgentReport;
