import React from "react";
import WhatsAppApiComponent from "../../site/WhatsAppApiComponent";
import DashboardLayout from "../../UI/Layout/DashboardLayout";
import Title from "../../UI/Text/Title";

const WhatsAppAPIDashboard = () => {
  return (
    <DashboardLayout>
      <main className="content">
        <div className="container-fluid p-0">
          <Title isButton={false}>
            <b>WhatsApp Docs</b>
          </Title>

          <div className="card">
            <div className="card-body">
              <h3 className="mt-1 main_innerheading">
                WhatsApp API Documentation
              </h3>
              <WhatsAppApiComponent />
            </div>
          </div>
        </div>
      </main>
    </DashboardLayout>
  );
};
export default WhatsAppAPIDashboard;
