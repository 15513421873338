import React from "react";
import "../../../../style/components/whatsAppPreview.css";
import { bg_whatsapp, whatsapp_brand } from "../../../../images/WhatsAppImages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faGlobe } from "@fortawesome/free-solid-svg-icons";

function NewWhatsAppPreview(props) {
  let previewData = props.previewData;
  const companyname = localStorage.getItem("wp-cmpy-name");
  var truncatedcompanyname;
  if (companyname) {
    if (companyname.length <= 15) {
      truncatedcompanyname = companyname
    } else {
      truncatedcompanyname = companyname.substring(0, 15) + '...';
    }
  }
  const companyimg = localStorage.getItem("wp-cmpy-img");

  const displayWhatsAppPreview = (TemplateType, headerText) => {
    for (let key in headerText) {
      if (key === "text") {
        return <strong> {previewData.headerText.receiveHeadePrev} </strong>;
      }
      if (key === "image") {
        return <img className="img-fluid" src={previewData.headerText.image} />;
      }
      if (key === "video") {
        return (
          <video width="100%" controls>
            <source src={previewData.headerText.video} type="video/mp4" />
          </video>
        );
      }
      if (key === "document") {
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={previewData.headerText.document}
          >
            <div className="doc-type">Document Media</div>
          </a>
        );
      }
    }
  };

  const quickReply = () => {
    return previewData.quickButton.map((item) => {
      let key = Object.keys(item);
      const index = key.indexOf("actionType");
      if (index > -1) {
        let data = key.splice(index, 1);
      }

      return key.map((keyitem) => (
        <div className="button-text">
          <p className="mb-1">{item[keyitem]}</p>
        </div>
      ));
    });
  };

  // console.log(previewData)


  return (
    <div className="whatsapp">
      {previewData && (
        <>
          <ul>
            <li>
              {" "}
              <strong> Template name: </strong> {previewData.tampleName}{" "}
            </li>
            <li>
              <strong> Language: </strong> {previewData.language}{" "}
            </li>
            <li>
              {" "}
              <strong> Category: </strong>
              {previewData.accountType}{" "}
            </li>
            <li>
              {" "}
              <strong> Type: </strong> {previewData.templateType}{" "}
            </li>
          </ul>
          <div
            style={{ backgroundImage: `url(${bg_whatsapp})` }}
            className="whatsapp-review whatsapp-bg"
          >
            <div>
              <div className="text-section">
                <div className="whats-app-header">
                  <div className="">
                    {" "}
                    <img src={companyimg ? companyimg : whatsapp_brand} />{" "}
                  </div>
                  <div className="">
                    {" "}
                    <p>{truncatedcompanyname} </p>{" "}
                  </div>
                </div>

                <div className="body-message">
                  {displayWhatsAppPreview(
                    previewData.headerText.TemplateType,
                    previewData.headerText
                  )}
                  {/* <img src={previewData.headerText.tempImage} className="img-fluid"/> */}
                  <p>
                    {" "}
                    {previewData.preBodyMessage
                      ? previewData.preBodyMessage
                      : previewData.bodyMessage}
                  </p>

                  <small>{previewData.footerText}</small>
                </div>

                {previewData.Callbutton[0] && (
                  <>
                    {previewData?.Callbutton?.map((item, index) => (
                      <div className="button-text">
                        <a href={`tel:+${item.countryCode}${item.phone}`}>
                          {" "}
                          <p key={index}>
                            {" "}
                            <FontAwesomeIcon icon={faPhone} /> {item.text}{" "}
                          </p>{" "}
                        </a>
                      </div>
                    ))}
                  </>
                )}

                {previewData.Urlbutton[0] && (
                  <>
                    {previewData.Urlbutton.map((item, index) => (
                      <div className="button-text">
                        <a href={`${item.url}`}>
                          {" "}
                          <p key={index}>
                            {" "}
                            <FontAwesomeIcon icon={faGlobe} /> {item.urltext}{" "}
                          </p>{" "}
                        </a>
                      </div>
                    ))}
                  </>
                )}

                {previewData.Urlbutton2[0] && (
                  <>
                    {previewData.Urlbutton2.map((item, index) => (
                      <div className="button-text">
                        <a href={`${item.url}`}>
                          {" "}
                          <p key={index}>
                            {" "}
                            <FontAwesomeIcon icon={faGlobe} /> {item.urltext2}{" "}
                          </p>{" "}
                        </a>
                      </div>
                    ))}
                  </>
                )}

                {previewData.button1 &&
                  <div className="button-text">
                    <p className="mb-1">{previewData.button1.button1}</p>
                  </div>}

                {previewData.button2 &&
                  <div className="button-text">
                    <p className="mb-1">{previewData.button2.button2}</p>
                  </div>}

                {previewData.button3 &&
                  <div className="button-text">
                    <p className="mb-1">{previewData.button3.button3}</p>
                  </div>}

                {/* {previewData.quickButton[0] && <>{quickReply()}</>} */}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default NewWhatsAppPreview;
