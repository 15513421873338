import React, { useState } from "react";
import DashboardLayout from "../../../UI/Layout/DashboardLayout";
import ChatLeftMenu from "./ChatLeftMenu";
import { FiPlus } from "react-icons/fi";

const Webhooks = () => {
  const [selectedBox, setSelectedBox] = useState(null);
  const handleSelect = (boxName) => {
    setSelectedBox(boxName);
  };
  const closeBox = () => {
    setSelectedBox(null);
  };

  return (
    <>
      <DashboardLayout>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="d-flex wFull">
              <div className="ChatLive_card col-md-2">
                <ChatLeftMenu />
              </div>
              <div className="col-md-10">
                <div className="ChatLive_card d-flex flex-column wFull px-3 py-3">
                  <div className="TitleBox">
                    <h5>
                      Webhooks{" "}
                      <a
                        href="/dashboard/shortcut"
                        class="btn btn-outline-success btn-sm float-right mb-3"
                      >
                        <FiPlus /> Create Webhook
                      </a>
                    </h5>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="px-4 py-3 borderBox  mb-2">
                        <div className="row">
                          <div className="col-md-6">
                            <label>Name</label>
                            <div class="input-group mb-2">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Name"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label>Your Endpoint URL</label>
                            <div class="input-group mb-2">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Endpoint URL"
                              />
                            </div>
                          </div>
                          <div className="col-md-12 mt-3">
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                value="option1"
                              />
                              <label
                                class="form-check-label"
                                for="inlineCheckbox1"
                              >
                                Chat Start
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox2"
                                value="option2"
                              />
                              <label
                                class="form-check-label"
                                for="inlineCheckbox2"
                              >
                                Chat End
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox3"
                                value="option3"
                              />
                              <label
                                class="form-check-label"
                                for="inlineCheckbox3"
                              >
                                New Ticket{" "}
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox3"
                                value="option3"
                              />
                              <label
                                class="form-check-label"
                                for="inlineCheckbox3"
                              >
                                New Ticket Transcript
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12 mb-4 mt-4">
                        <button className="btn btn-success btn-sm">Save</button>
                        <button
                          type="button"
                          class="btn btn-sm btn-outline-dark"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </DashboardLayout>
    </>
  );
};

export default Webhooks;
