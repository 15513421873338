import React, { useEffect, useState } from 'react';
import AdminHeader from "../Header/AdminHeader";
import AdminFooter from "../Footer/AdminFooter";
import AdminLeftMenu from "../Navbar/AdminLeftMenu";
import { userloginDetails } from '../Utils/AdminAuth';
import { ADMIN_USERS_LIST } from '../../constants/ServiceConstants';
import api from "../../axios/Axios";

const ReviewTemplate = () => {
    const adminAuthDetails = userloginDetails();
    const [whatsTemplateList, setWhatsTemplateList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [ErrorResponse, setErrorResponse] = useState(null);
    const [checkStatusPopup, setCheckStatusPopup] = useState(false)
    const [status, setStatus] = useState('')
    const [rowId, setRowId] = useState('')
    const [retUserId,setRetUserId] = useState('')

    useEffect(() => {
        getTemplateList();
    }, []);

    const getTemplateList = async () => {
        try {
            const { data } = await api.post(ADMIN_USERS_LIST, {
                token: adminAuthDetails.token,
                user_id: adminAuthDetails.user.userId,
                method: "retrieve_review",
                type: adminAuthDetails.user.isAdmin
            });
            if (data.success) {
                setWhatsTemplateList(data.data);
            } else {
                setErrorResponse(data.message);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    const checkStatus = async (facebook, userid, id) => {
        try {
            const { data } = await api.post(ADMIN_USERS_LIST, {
                token: adminAuthDetails.token,
                user_id: adminAuthDetails.user.userId,
                method: "check_meta_status",
                type: adminAuthDetails.user.isAdmin,
                ret_user_id: userid,
                fb_temp_id: facebook
            });
            if (data.success) {
                console.log(data)
                setStatus(data.message);
                setCheckStatusPopup(true)
                setRowId(id)
                setRetUserId(userid)
            } else {
                setErrorResponse(data.message);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    const Resend = async () => {
        try {
            const { data } = await api.post(ADMIN_USERS_LIST, {
                token: adminAuthDetails.token,
                user_id: adminAuthDetails.user.userId,
                method: "resend_status",
                type: adminAuthDetails.user.isAdmin,
                ret_user_id: retUserId,
                id: rowId
            });
            if (data.success) {
                console.log(data)
                setTimeout(()=>{
                    window.location.reload()
                },2000)              
            } else {
                setErrorResponse(data.message);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    console.log(whatsTemplateList)

    return (
        <div className="wrapper">
            <AdminLeftMenu />
            <div className="main">
                <AdminHeader />
                <main className="content">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-12">
                                <div className="card flex-fill">
                                    <div className="card-header d-flex align-items-center border-bottom">
                                        <h3 className="card-title mb-0 font-weight-bold">Review Template</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-striped table-bordered">
                                                <thead className="thead-dark">
                                                    <tr>
                                                        <th style={{ color: "white" }}>WID</th>
                                                        <th style={{ color: "white" }}>Username</th>
                                                        {/* <th style={{color:"white"}}>Action</th> */}
                                                        <th style={{ color: "white" }}>Check Status</th>
                                                        <th style={{ color: "white" }}>Status</th>
                                                        <th style={{ color: "white" }}>Template Number</th>
                                                        <th style={{ color: "white" }}>Category</th>
                                                        <th style={{ color: "white" }}>Template Type</th>
                                                        <th style={{ color: "white" }}>Dynamic</th>
                                                        <th style={{ color: "white" }}>Last Update</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {isLoading ? (
                                                        <tr>
                                                            <td colSpan="9" className="text-center">Loading...</td>
                                                        </tr>
                                                    ) : (
                                                        whatsTemplateList.map((item) => (
                                                            <tr key={item.id}>
                                                                <td>{item.id}</td>
                                                                <td>{item.username}</td>
                                                                {/* <td>
                                                                    <span onClick={''} className="badge bg-danger cursor-pointer">
                                                                        Delete
                                                                    </span>
                                                                </td> */}
                                                                <td>
                                                                    {item.temp_status === 1 || item.temp_status === 3 || item.temp_status === 4 ? null : (
                                                                        <span className="badge bg-info" onClick={() => checkStatus(item.facebook_tempid, item.user_id, item.id)}>Check Status</span>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {item.temp_status === 0 && <span className="badge bg-success">Pending</span>}
                                                                    {item.temp_status === 1 && <span className="badge bg-primary">Approved</span>}
                                                                    {item.temp_status === 2 && <span className="badge bg-info">Draft</span>}
                                                                    {item.temp_status === 3 && <span className="badge bg-danger">Rejected</span>}
                                                                    {item.temp_status === 4 && <span className="badge bg-danger">Deleted</span>}
                                                                    {item.temp_status === 5 && <span className="badge bg-warning">In Review</span>}
                                                                    {item.temp_status === 6 && <span className="badge bg-success">Processing</span>}
                                                                </td>
                                                                <td>{item.temp_name}</td>
                                                                <td>{item.temp_category}</td>
                                                                <td><span className="text-primary">{item.temp_type}</span></td>
                                                                <td>{item.dynamic_content === 0 ? "No" : "Yes"}</td>
                                                                <td>{item.created}</td>
                                                            </tr>
                                                        ))
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {checkStatusPopup && (
                            <div
                                className="modal fade show"
                                tabIndex="-1"
                                style={{ display: 'block' }}
                                aria-labelledby="popupLabel"
                                aria-hidden="true"
                            >
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        {/* <div className="modal-header">
                                            <h5 className="modal-title" id="popupLabel">Status</h5>
                                        </div> */}
                                        <div className="modal-body text-center">
                                            <h1>{status}</h1>
                                            <button
                                                type="button"
                                                onClick={Resend} // Ensure this is a function reference
                                                className="btn btn-danger"
                                            >
                                                Resend
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={() => setCheckStatusPopup(false)}
                                            >
                                                Close
                                            </button>
                                        </div>
                                        {/* <div className="modal-footer">
                                            <button
                                                type="button"
                                                onClick={"DeleteData"} // Ensure this is a function reference
                                                className="btn btn-danger"
                                            >
                                                Resend
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={() => setCheckStatusPopup(false)}
                                            >
                                                Close
                                            </button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                </main>
                <AdminFooter />
            </div>
        </div>
    );
};

export default ReviewTemplate;
