import React, { useState } from "react";
import "../../../../style/components/whatsAppPreview.css";
import { bg_whatsapp, whatsapp_brand } from "../../../../images/WhatsAppImages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faGlobe } from "@fortawesome/free-solid-svg-icons";
import 'bootstrap/dist/css/bootstrap.min.css';

const WhatsappCampaignListPreview = (props) => {
    // State for managing modal visibility
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openTemplatePopup = props.openTemplatePopup

    // Toggle modal visibility
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    // Getting company info from localStorage
    const companyname = localStorage.getItem("wp-cmpy-name");
    let truncatedcompanyname;
    if (companyname) {
        truncatedcompanyname = companyname.length <= 15 ? companyname : companyname.substring(0, 15) + '...';
    }

    const companyimg = localStorage.getItem("wp-cmpy-img");

    console.log(props)

    // Render WhatsApp Preview content inside the modal
    return (
        <div>
            {/* Button to open modal */}
            {/* <button type="button" className="btn btn-primary" onClick={toggleModal}>
        Show WhatsApp Preview
      </button> */}

            {/* Bootstrap Modal */}
            <div
                className={`modal fade ${openTemplatePopup ? 'show' : ''}`}
                tabIndex="-1"
                aria-labelledby="whatsappPreviewModal"
                aria-hidden={!openTemplatePopup}
                style={{ display: openTemplatePopup ? 'block' : 'none' }}
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        {/* <div className="modal-header">
        <h5 className="modal-title" id="whatsappPreviewModal">
          WhatsApp Campaign Preview
        </h5>
        <button
          type="button"
          className="close"
          onClick={props.openTemplatePopup}
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div> */}
                        <div className="modal-body">
                            {props && props.templateDetails && (
                                <>
                                    {props.templateDetails.map((template, index) => (
                                        <div key={index}>
                                            <div className="row">
                                                <div className="col-7">
                                                    <div
                                                        style={{ backgroundImage: `url(${bg_whatsapp})`, height: "80vh" }}
                                                        className="whatsapp-review whatsapp-bg"
                                                    >
                                                        <div className="text-section">
                                                            <div className="whats-app-header whatsappImg">
                                                                <div>
                                                                    <img
                                                                        src={whatsapp_brand}
                                                                        alt="Company Logo"
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <p>{truncatedcompanyname}</p>
                                                                </div>
                                                            </div>

                                                            <div className="body-message bodyMessage">
                                                                <p>
                                                                    {/* {template.temp_type}, {template.temp_header} */}
                                                                </p>
                                                                <p>{template.temp_body}</p>
                                                                <small>{template.temp_footer}</small>

                                                                {template.temp_button && template.temp_button.map((item, btnIndex) => (
                                                                <div key={btnIndex} className="button-text">
                                                                    {/* Check for Phone Number Button */}
                                                                    {item.countryCode && item.phone && (
                                                                        <a href={`tel:+${item.countryCode}${item.phone}`}>
                                                                            <p>
                                                                                <FontAwesomeIcon icon={faPhone} /> {item.text}
                                                                            </p>
                                                                        </a>
                                                                    )}
                                                                    {/* Check for URL Button */}
                                                                    {item.url && item.urltext && (
                                                                        <a href={item.url}>
                                                                            <p>
                                                                                <FontAwesomeIcon icon={faGlobe} /> {item.urltext}
                                                                            </p>
                                                                        </a>
                                                                    )}
                                                                    {/* Check for Second URL Button */}
                                                                    {item.url && item.urltext2 && (
                                                                        <a href={item.url2}>
                                                                            <p>
                                                                                <FontAwesomeIcon icon={faGlobe} /> {item.urltext2}
                                                                            </p>
                                                                        </a>
                                                                    )}

                                                                    {item?.button1 &&
                                                                        <div>
                                                                            <p className="mb-1">{item?.button1}</p>
                                                                        </div>}

                                                                    {item?.button2 &&
                                                                        <div>
                                                                            <p className="mb-1">{item?.button2}</p>
                                                                        </div>}

                                                                    {item?.button3 &&
                                                                        <div>
                                                                            <p className="mb-1">{item?.button3}</p>
                                                                        </div>}

                                                                        {/* {previewData.quickButton[0] && <>{quickReply()}</>} */}
                                                                </div>
                                                            ))}
                                                            </div>

                                                            {/* Check for buttons and render them */}
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-5 mt-10">
                                                    <ul>
                                                        <li style={{ fontSize: "13px" }}>
                                                            <strong>Template name:</strong> {template.temp_name}
                                                        </li>
                                                        <li style={{ fontSize: "13px" }}>
                                                            <strong>Language:</strong> {template.temp_language}
                                                        </li>
                                                        <li style={{ fontSize: "13px" }}>
                                                            <strong>Category:</strong> {template.temp_category}
                                                        </li>
                                                        <li style={{ fontSize: "13px" }}>
                                                            <strong>Type:</strong> {template.temp_type}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>



                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>


            {/* Modal Backdrop (optional but common for Bootstrap modals) */}
            {isModalOpen && <div className="modal-backdrop fade show" />}
        </div>
    );
};

export default WhatsappCampaignListPreview;
